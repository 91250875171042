import React from "react";
import Wheel from "./UI/wheel";
import RightButtonPanel from "./UI/rightButtonPanel";
import artBg from "../../../../../assets/games/americanRoulette/roulette_bg.png";
import "../../../../css/all_roulette_tamplate.css";
import "./UI/american_roulette.css";
import Title from "./UI/title";
import Table from "./UI/Table";
import BetChips from './UI/betChips';
import coin from '../../../../../assets/gold_coin.svg';
import user from '../../../../../assets/login/user.svg';

import clearSound from "../../../../../assets/sounds/clear.mp3";
import betSound from "../../../../../assets/sounds/bet.mp3";
import takeSound from "../../../../../assets/sounds/take.mp3";
import winSound from "../../../../../assets/sounds/win.mp3";
import duploseSound from "../../../../../assets/sounds/duplose.mp3";
import exitSound from "../../../../../assets/sounds/exit.mp3";
import wheelSound from "../../../../../assets/sounds/wheelStart.mp3";

import { connect } from "react-redux";

class AmericanRoulette extends React.Component {
    constructor(props) {
        super(props);
        this.artWheelRef = React.createRef();
        this.RightButtonPanel = React.createRef();
        this.wheelWidth = window.innerHeight * 1;
        this.wheelHeight = window.innerHeight * 0.45;
        this.state = {
            previousBetArray: [],
            finalPreviousBetArray: [],
            balance: this.props.balance,
            gameId: this.props.gameId,
            playerId: this.props.playerId,
            zindex: '',
            //betButtonText: "PREV",
            betButtonText: this.props.language.Prev,
            betButtonValue:0,
            //zoomButtonText: "Wheel Zoom: ON",
            zoomButtonText: this.props.language.Zoom_ON,
            zoomButtonValue:0,
            showBetButton: false,
            wheelZoom: 1,
            stop: "NA",
            betString: [],
            Total_Bet_Amount: 0,
            betAmount: 1,
            totalBet: 0,
            betstr: "",
            bets: "",
            infoText: this.props.language.Play_Njoy,
            nmArray:[],
            targetResult: props.targetResult,
            selectedChipVal:1,
            wheelZoomClick: true,
            disDoubleBtn: 1,
            disBetBtn: 0,
            disClearBtn: 1,
            disZoomBtn: 0,
            glowTakeBtn: 0,
            btnActive: false,
            winAmount:0,
            amrLoader:true,
        };
        this.newLimits = [];
        this.resultNumber = 0;
        this.isReady = false;
        this.removeGlow = false;
        this.isloadImg = false;
        this.updatePlayerBalnce = false;
        this.loadBg(); 
 
    }
    componentDidMount() {  
        const { childRef } = this.props;
        childRef(this);
        this.betSound = new Audio(betSound);

        this.clearSound = new Audio(clearSound);
        this.exitSound = new Audio(exitSound);
        this.betSound = new Audio(betSound);
        this.takeSound = new Audio(takeSound);
        this.winSound = new Audio(winSound);
        this.duploseSound = new Audio(duploseSound);
        this.resetBetList(); 

        if(this.props.ar_chipValues){
            let newChips = this.props.ar_chipValues.split(",");
            console.log(newChips[0]);  
            let initChipVal = newChips[0];
            let lblTxt;
            if(initChipVal >= 1000 && initChipVal <= 999999){
                lblTxt = "K"
            }else if(initChipVal >= 1000000 && initChipVal <= 999999999){
                lblTxt = "M"                        
            }else if(initChipVal > 999999999){            
                lblTxt = "B"
            } 
            
            this.state.selectedChipVal = initChipVal;
            console.log(this.state.selectedChipVal)
            // this.state.selectedChipLbl = initChipVal+""+lblTxt;
          }


          if(this.props.ar_innerLimits){
            this.newLimits = this.props.ar_innerLimits.split("|");
            console.log(this.newLimits);
            for(let t=0; t<this.newLimits.length; t++){
              this.newLimits[t] = this.newLimits[t].split("-");
              console.log(" *******   "+this.newLimits[t]);
            }
          }

          
        document.getElementById('rouletteTableCover').style.display = 'none';
        document.getElementById('bet_take').classList.remove('active');
        this.setState({betButtonText: this.props.language.Prev,betButtonValue:0});
        
        if(this.props.balance > 0){
            this.setState({disBetBtn: 0, disZoomBtn: 0 })
        }else{
            // document.getElementById('rouletteTableCover').style.display = 'block';
            this.setState({disDoubleBtn: 1, disBetBtn: 1, disClearBtn: 1, disZoomBtn: 1 })
        }
        if(this.props.gameState.history && this.props.gameState.history.length === 0){
            this.setState({disBetBtn: 1});
        }
        if(this.props.gameState.handId){
            this.handId = this.props.gameState.handId;
        }

        document.addEventListener("keyup", this.ar_handleKeyboardEvent);  
        
    }
    componentWillUnmount() {
        document.removeEventListener("keyup", this.ar_handleKeyboardEvent);
    }

    changeAmtlabl(chngAmt) {
        let newAmt = Number(Number(chngAmt).toFixed(2));
        if (newAmt >= 1000 && newAmt <= 999999) {
            newAmt = Number((newAmt / 1000).toFixed(2)) + "K";
        } else if (newAmt >= 1000000 && newAmt <= 999999999) {
            newAmt = Number((newAmt / 1000).toFixed(2)) + "M";
        } else if (newAmt > 999999999) {
            newAmt = Number((newAmt / 1000).toFixed(2)) + "B";
        }
        return newAmt;
    }
    
    ar_handleKeyboardEvent = (evt) => {
        console.log(evt.code);
        switch(evt.code){
          case "Enter":
          case "NumpadEnter":
            if (this.state.betButtonValue == 0 && this.state.disBetBtn == 0) {
              this.rightButtonPanelHandler("PREV");
            }else if (this.state.betButtonValue == 1 && this.state.disBetBtn == 0) {
              this.rightButtonPanelHandler("SPIN");
            }
            break;
          case "Space":
              console.log(this.state.betButtonText )
            if(this.state.betButtonValue == 2 && this.state.disBetBtn == 0){
              this.rightButtonPanelHandler("TAKE");
            }
            break;
          case "Escape":
            //this.rightButtonPanelHandler("exit");
            break;
          case "KeyC":      
            if(this.state.betButtonValue== 1 && this.state.disClearBtn == 0){
              this.rightButtonPanelHandler("clear");
            }
            break;      
        }
    
      }
 
    updateGameState(){        
        // alert('ding');
        this.RightButtonPanel.updateHistory(); //current
    }
    resetBetList(){
        this.state.betString = [];
        for (var l = 0; l < 171; l++) {
            this.state.betString.push({
                id: l,
                amount: 0
            });
        }
    }
    updateMessage(){
        console.log("--------- "+this.props.infoText);
        this.setState({infoText: this.props.infoText});
        
        console.log("error_code: "+this.props.error_code);
        if(this.props.error_code === "game.bet.invalid"){
            this.setState({
               
                betButtonText: this.props.language.Prev,
                betButtonValue:0,
                btnActive : false,
                //zoomButtonText: "Wheel Zoom: ON"
                zoomButtonText: this.props.language.Zoom_ON,
                zoomButtonValue:0,
                wheelZoom: 1,
                zindex: '0',
                X_top: "14.5vh",
                disBetBtn : 0,
                disZoomBtn : 0,
            });
            this.updateWinsArray(0);
            document.getElementById('rouletteTableCover').style.display = 'none';
            setTimeout(() => {
                this.setState({infoText: this.props.language.Play_Njoy});
            }, 1000);
        }
    }
    gameCloseHandler(game) {

        // this.exitSound = new Audio(exitSound);
        this.exitSound.play();
      
        this.artWheelRef.current.stopWheelSound();
        this.clearSound.pause();
        this.betSound.pause();
        this.takeSound.pause();
        this.winSound.pause();
        this.duploseSound.pause();
        this.props.action(game);


    }
    wheelHandler(position) {
        // this.rightButtonPanelHandler("ZoomOFF");
        // this.declareWin();. 
        
        if(position){
            this.setState({stop: position});
        }
        this.props.gamstateHandle();
        console.log("Wheel Handle: Game State: "+this.props.gameState);
        console.log( this.props.gameState);
        console.log("Wheel Handle: Game State: Take Amount: "+this.props.gameState.takeAmount);
        console.log("Wheel Handle: WinAmount: "+this.state.winAmount);
        if(this.props.gameState.takeAmount === 0){
            if(this.state.winAmount>0){
                this.rightButtonPanelHandler('TAKE');   
                console.log("Take Amount: "+this.props.gameState.takeAmount);
            }else{                
                var valId3 = document.getElementById('bet_take');
                valId3.classList.remove('active');
            }
        }else{
            //this.setState({disBetBtn: 0})
        }
        if(this.props.gameState.handId){
            this.handId = this.props.gameState.handId;
        }
        if(this.updatePlayerBalnce == false){
            this.updatePlayerBalnce = true;
            //this.getPlayerInfo();
        }
    }

    showPrevBtn(){
        this.setState({
            showBetButton: true,
          
            betButtonText: this.props.language.Prev,
            betButtonValue:0,
            btnActive : false
        });
        let valId4 = document.getElementById('bet_take');
        valId4.classList.remove('active');
    }
    declareWin(value) {  
        if(this.state.winAmount==0){
            this.updateWinsArray(0);            
        }
         
        this.setState({
            showBetButton: false,
            infoText:this.props.language.Play_Njoy,
            balance: this.props.balance,            
            //totalBet: 0,
        });
        // this.rightButtonPanelHandler('clear');
        document.getElementById('rouletteTableCover').style.display = 'none';
         
        this.isReady = false;
        console.log( this.props.gameState.history );
        console.log("Declare win")
    }
    rightButtonPanelHandler(action, value) {
        switch (action) {
            case "SPIN": 
            this.state.totalBet = this.state.betString.reduce((prev, cur) => Number((prev + cur.amount).toFixed(2)), 0);
            if(this.state.totalBet != 0 && this.state.totalBet < this.props.minBetAmt){
                console.log("Minimum bet amount not matched. ")
                // this.setState({infoText: this.props.language.Min_Bet+" "+this.props.minBetAmt+" "+this.props.language.Max_Bet+" "+this.props.maxBetAmt});
                this.setState({infoText: this.props.language.MinBetAmt+" "+this.props.minBetLbl+" "+this.props.language.MaxBetAmt+" "+this.props.maxBetLbl});
      
              }else{ 
                if(this.state.Total_Bet_Amount > 0){
                    this.updatePlayerBalnce = false;
                    // this.setState({
                    //     disDoubleBtn: 1,
                    //     disBetBtn: 1,
                    //     disClearBtn: 1,
                    //     disZoomBtn: 1,
                    //     btnActive:false
                    // })

                    this.wheelSound = new Audio(wheelSound);
                    this.winSound = new Audio(winSound);
                    this.duploseSound = new Audio(duploseSound);


                    console.log("SPIN: ");
                    var divContain = document.getElementById('roulette');
                    divContain.style.transform = "scale(1)";
                    divContain.style.zIndex = "99";
                    // if(this.state.zoomButtonValue === 0) {
                    //     this.setState({wheelZoom: 1.5, zindex: '1000', X_top: "21.8vh", x_img: 1});
                    // }
                  
                    document.getElementById('rouletteTableCover').style.zIndex = "1000";
                    document.getElementById("zoomBtn").disabled = true;
                    //console.log("Double Btn: "+document.getElementById("zoomBtn").disabled)
                    console.log("BetSting: ");
                    console.log(this.state.betString);
                    //console.log("this.state.betString.length: "+this.state.betString.length);

                    this.setState({ betstr: [], bets: [] });

                    if(this.state.betString.length >1){
                        this.state.totalBet = this.state.betString.reduce((prev, cur) => Number((prev + cur.amount).toFixed(2)), 0);
                    }else{
                        this.state.totalBet =0;
                    }
                    console.log("Total Bet: "+this.state.totalBet);
                    console.log("betAmount: "+this.state.betAmount);

                    if (this.state.betAmount !== 0 && this.state.totalBet !== 0) {
                        this.isReady = false;
                        let betValid = [];
                        // this.state.betstr = "";
                        this.state.bets = "";
                        console.log("balance: "+this.props.balance)
                        console.log("totalBet: "+this.state.totalBet)
                        if (this.props.balance >= this.state.totalBet) {
                            let innerMin_Limit = 1;
                            console.log("betString length  "+this.state.betString.length);
                            for (let i = 0; i < this.state.betString.length; i++) {

                                // let innerMin_Limit = 1;
                                if(i<=37){                  
                                innerMin_Limit = this.newLimits[0][0];                  
                                
                                }else if(i >= 38 && i <= 99){
                                innerMin_Limit = this.newLimits[1][0];                      
                                }else if(i >= 100 && i <= 114){
                                innerMin_Limit = this.newLimits[2][0];                      
                                }else if(i >= 115 && i <= 137){
                                innerMin_Limit = this.newLimits[3][0];            
                                }else if(i>=138 && i <= 148){
                                innerMin_Limit = this.newLimits[5][0];
                                }else if(i>=154 && i <= 159){
                                innerMin_Limit = this.newLimits[6][0];
                                }else if(i >= 165 && i <= 170){
                                innerMin_Limit = this.newLimits[7][0];

                                }

                                if(this.state.betString[i].amount > 0){
                                console.log(this.state.betString[i].amount)
                                console.log(innerMin_Limit)
                                if(this.state.betString[i].amount >= innerMin_Limit){
                                    console.log("OK")
                                    betValid[i] = true;
                                }else{
                                    betValid[i] = false;
                                    console.log("dont place the bet.")
                                }
                                }else{
                                betValid[i] = true;
                                }

                                if (this.state.betString[i].id === 148
                                    || this.state.betString[i].id === 159
                                ) {
                                    this.state.bets += Number(this.state.betString[i].amount.toFixed(2)) + ',';
                                } else if (this.state.betString[i].id === 149
                                    || this.state.betString[i].id === 150
                                    || this.state.betString[i].id === 151
                                    || this.state.betString[i].id === 152
                                    || this.state.betString[i].id === 153
                                    || this.state.betString[i].id === 160
                                    || this.state.betString[i].id === 161
                                    || this.state.betString[i].id === 162
                                    || this.state.betString[i].id === 163
                                    || this.state.betString[i].id === 164) {
                                    this.state.bets += ' ' + ',';

                                } else if (this.state.betString[i].id === 38
                                    || this.state.betString[i].id === 100
                                    || this.state.betString[i].id === 115
                                    || this.state.betString[i].id === 137
                                    || this.state.betString[i].id === 138) {
                                    this.state.bets = this.state.bets.slice(0, -1);
                                    this.state.bets += ',' + Number(this.state.betString[i].amount.toFixed(2)) + '|';
                                } else {
                                    this.state.bets += Number(this.state.betString[i].amount.toFixed(2)) + '|';
                                }
                            }
                            this.state.betstr = this.state.bets.slice(0, -1);
                            console.log("this.state.betstr: ");
                            console.log(this.state.betstr);

                            console.info("betValid: "+betValid);
                            let sendBet = true;;
                            for(let u=0; u<betValid.length;u++){
                              if(betValid[u] ==  false){
                                sendBet = false;
                                // console.log("Dont send bet reques");
                                break;
                              }
                            }
              
                            console.log(this.state.nmArray);
                            console.log(this.state.betString);
                            console.info(this.state.bets);
                            this.state.betstr = this.state.bets.slice(0, -1);
                            if(sendBet){
                                if(this.state.zoomButtonValue === 0) {                      
                                    this.setState({wheelZoom: 1.5, zindex: '1000', X_top: "21.8vh", x_img: 1,});
                                }
                                this.placebet();
                                 this.setState({
                                disDoubleBtn: 1,
                                disBetBtn: 1,
                                disClearBtn: 1,
                                disZoomBtn: 1,
                                btnActive:false
                    })
                              console.log(  console.log(this.state.betstr))
                              document.getElementById('rouletteTableCover').style.display = 'block';
                            }else{
                                const minInrLmt = this.changeAmtlabl(innerMin_Limit) 
                              console.log("Dont Send Bet Request.")
                              this.setState({ 
                                infoText: this.props.language.InerLmt +" "+ minInrLmt, 
                                disClearBtn: 0,});                                           
                            }


                            return this.state.betstr;
                        } else {
                            //alert('amount reduces to your balance');
                        }
                    console.log("Info text on Bet Amount: "+this.state.infoText)
                    }else {
                        if(this.props.infoText !== undefined){
                        this.setState({infoText: this.props.infoText,});
                        }
                    }
                }

              }
                break;
            case "TAKE":
                //this.takeSound.play();
                console.log('take amount action');
                var valId6 = document.getElementById('bet_take');
                // valId6.classList.remove('active');

                for(var i=0; i<this.state.betString.length; i++){
                    var valId7 = document.getElementById('id_'+i);
                    if(valId7){
                        // valId7.classList.remove('active');
                    }
                    document.getElementById("id_"+i).innerHTML = ``;
                }
                this.takeAmountResult();
                break;
            case "PREV":
                this.betSound.play();
                this.removeImgGlow();
                this.showPrevBet();

                break;
            case "ZoomON": 
                this.betSound.play();
                this.setState({ zoomButtonText: this.props.language.Zoom_OFF,zoomButtonValue:1});
               //this.setState({ wheelZoom: 1.5, zoomButtonText: "Wheel Zoom: OFF", zindex: 1000 });
                break;
            case "ZoomOFF":
                this.betSound.play();
                this.setState({zoomButtonText: this.props.language.Zoom_ON ,zoomButtonValue:0});
               // this.setState({ wheelZoom: 1, zoomButtonText: "Wheel Zoom: ON" });
                break;
            case "double":
                this.betSound.play();
                this.updateWinsArray(2);
                break;
            case "clear":
                if(this.props.gameState.betRequest){
                    // alert("clear button is called")
                    console.log(this.props.gameState.betRequest)
                    
                    this.setState({ betButtonText: this.props.language.Prev,betButtonValue:0, infoText:  this.props.language.Play_Njoy , btnActive : false, disZoomBtn: 0 ,disBetBtn: 0,});
                }else{
                    this.setState({disBetBtn: 1});
                }
                
                document.getElementById('bet_take').classList.remove('active');
                this.clearSound.play();
                //this.updateWinsArray(0);
                this.clearBetList();
                break;
            case "exit":
                this.gameCloseHandler("target");
                const body = {
                    sessionId: sessionStorage.getItem('sessionId'),
                    CN: "EXIT_GAME",
                    object: {
                        gameId: this.props.gameId,
                        playerId: Number(this.props.playerId),
                    }
                }
                this.props.network.sendof(body);
                this.body ={};
                break;
            case "disableBtns":
                this.removeImgGlow();
                this.setState({
                    disDoubleBtn: 1,
                    disBetBtn: 1,
                    disClearBtn: 1,
                    disZoomBtn: 1,
                })
                if(this.state.totalBet === 0){
                   this.setState({infoText: this.props.language.Play_Njoy})
                }
                break;
            default:
                break;
        }
    }

    exitGame(){
        this.gameCloseHandler("target");
        const body = {
            sessionId: sessionStorage.getItem('sessionId'),
            CN: "EXIT_GAME",
            object: {
                gameId: this.props.gameId,
                playerId: Number(this.props.playerId),
            }
        }
        this.props.network.sendof(body);
        this.body ={};
    }

    removeImgGlow(){
        console.log("removeImgGlow");
//        for(var i=0; i<this.state.nmArray.length; i++){
        for(var i=0; i<this.state.betString.length; i++){
            var valId8 = document.getElementById('id_'+i);
           // console.log("Remove Glow Number: "+valId8);
            if(valId8){
                valId8.classList.remove('active');
            }
        }
        this.removeGlow = false;
    }

    PreviousBetMethod() {
        console.log("Previous Bet Method");
        this.updateWinsArray(0);
        var spacesremove = this.props.gameState['betRequest']['betString'].replace(/ /g, "0");
        var preBet = spacesremove.replace(/,/g, '|');
        this.state.previousBetArray.push({
            amount: preBet.split("|",)
        });
        this.setState({ previousBetArray: this.state.previousBetArray });
        if (this.state.previousBetArray) {
            this.toCreatePreviousBetArray();
        }
        console.log(this.state.previousBetArray[0].amount[0]);
        if(this.props.gameState.handId){
            this.handId = this.props.gameState.handId;
        }
    }
    toCreatePreviousBetArray() {
        for (let i = 0; i < this.state.previousBetArray[0].amount.length; i++) {
            this.state.finalPreviousBetArray.push({
                id: i,
                amount: Number(this.state.previousBetArray[0].amount[i])
            });
        }
        this.setState({ finalPreviousBetArray: this.state.finalPreviousBetArray });
        console.log(this.state.finalPreviousBetArray);
        if (this.state.finalPreviousBetArray) {
            this.toGenerteBetString();
        }
    }
    toGenerteBetString() {
        for (let i = 0; i < this.state.betString.length; i++) {
            if (this.state.betString[i].id == this.state.finalPreviousBetArray[i].id) {
                console.log('ids' + i);
                let value = this.state.betString[i].amount += this.state.finalPreviousBetArray[i].amount;
                if (this.state.finalPreviousBetArray[i].amount != 0) {
                    document.getElementById("id_" + i).innerHTML = `<p class="goldCoin"> <img src=${coin} /> <span> ${Number(value.toFixed(2))} </span> </p>`
                }
            }
        }
        this.setState({ betString: this.state.betString, betButtonText:this.props.language.Spin,betButtonValue:1, previousBetArray: [], finalPreviousBetArray: [],btnActive:true });
        console.log(this.state.betString);
        console.log(this.props.language.Spin)
    }
    takeAmountResult() {
        console.log("Take Amount Function");
        console.log("Remove Img Glow: "+this.resultNumber);
        this.setState({
            disDoubleBtn: 1,
            // disBetBtn: 0,
            disClearBtn: 1,
            disZoomBtn: 0,
            glowTakeBtn: 0,
            btnActive : false,
            betButtonText: this.props.language.Prev,
            betButtonValue:0,
            totalBet: 0,
            winAmount:0
        })
        console.log("WinAmount: "+this.state.winAmount);
        var valId9 = document.getElementById('bet_take');
        valId9.classList.remove('active');
        //document.getElementById('rouletteTableCover').style.display = 'none';
        this.setState({infoText:this.props.language.Play_Njoy , showBetButton: false});
        this.updateWinsArray(0);

        const body = {
            sessionId: sessionStorage.getItem('sessionId'),
            CN: "TAKE_AMOUNT",
            object: {
                gameId: this.props.gameId,
                playerId: Number(this.props.playerId),
            }
        }
        // this.props.network.sendof(body);
        this.body={};

        if(this.props.isPlayerBanned){
            this.gameCloseHandler("target");        
            this.props.player_banned();
        }

        console.log("gameUnderMaintanance "+this.props.gameUnderMaintanance);
        if(this.props.gameUnderMaintanance){
            this.gameCloseHandler("target");
            this.props.game_maintanance("Game under maintenance, reach to admin.");
        }

        console.log("deviceBlocked "+this.props.isdeviceBlocked);
        if (this.props.isdeviceBlocked){
            this.gameCloseHandler("target");
            this.props.device_blocked();
        }
    }
    clearBetList(){
        console.log("clearBetList");
        for(var i=0; i<this.state.betString.length; i++){
            document.getElementById("id_"+i).innerHTML = ``;            
            this.state.betString[i].amount = 0;
        }
        this.setState({Total_Bet_Amount: 0});
        // this.setState({disDoubleBtn: 1, disClearBtn: 1,betButtonText: this.props.language.Prev,betButtonValue:0,disBetBtn: 0,});
        this.setState({disDoubleBtn: 1, disClearBtn: 1});

    }
    updateWinsArray(num) {
        console.info("Update Bet:::::::::::::::::::::: "+ num);

         let totBet = 0;
       
        if(num == 2){ 
            console.log(this.state.Total_Bet_Amount);
            console.log(this.props.balance);
            console.log(this.state.Total_Bet_Amount * 2);
            if(this.state.Total_Bet_Amount * 2 > this.props.maxBetAmt){
                this.setState({infoText: this.props.language.MinBetAmt+" "+this.props.minBetLbl+" "+this.props.language.MaxBetAmt+" "+this.props.maxBetLbl});
            }else{
                if(this.state.Total_Bet_Amount * 2 <= this.props.balance){
                  
                    let innerMaxLimit = 1;
                    for(let k=0; k<this.state.betString.length; k++){

                        if(k<=37){                  
                            innerMaxLimit = this.newLimits[0][1];                  
                            
                          }else if(k >= 38 && k <= 99){
                            innerMaxLimit = this.newLimits[1][1];                      
                          }else if(k >= 100 && k <= 114){
                            innerMaxLimit = this.newLimits[2][1];                      
                          }else if(k >= 115 && k <= 137){
                            innerMaxLimit = this.newLimits[3][1];            
                          }else if(k>=138 && k <= 148){
                            innerMaxLimit = this.newLimits[5][1];
                          }else if(k>=154 && k <= 159){
                            innerMaxLimit = this.newLimits[6][1];
                          }else if(k >= 165 && k <= 170){
                            innerMaxLimit = this.newLimits[7][1];
                
                          }



                          if(this.state.betString[k].amount * num < this.props.balance){
                              
                          if(this.state.betString[k].amount > 0 && this.state.betString[k].amount <= innerMaxLimit && totBet <= this.props.balance  ){          
                              if(this.state.betString[k].amount * num <= innerMaxLimit){  
                              
                              
                                  // if(this.state.Total_Bet_Amount + this.state.betString[k].amount <= this.props.balance){
                                  //   this.state.betString[k].amount = this.state.betString[k].amount * num;
                                  //   document.getElementById("id_" + k).innerHTML = `<p class="goldCoin"> <img src=${coin} /> <span> ${this.state.betString[k].amount} </span> </p>`;
                                  // }
          
          
                                  if(this.state.Total_Bet_Amount + this.state.betString[k].amount <= this.props.balance){   
                                  
                                      this.state.Total_Bet_Amount = this.state.Total_Bet_Amount + this.state.betString[k].amount;             
                                      this.state.betString[k].amount = this.state.betString[k].amount * num;

                                      let doubleBetValue = Number((this.state.betString[k].amount).toFixed(2));
                                      if(doubleBetValue >= 1000 && doubleBetValue <=999999){
                                        doubleBetValue = Number((doubleBetValue / 1000).toFixed(2))+"K";
                                        console.log(doubleBetValue);
                                      }else if(doubleBetValue >= 1000000 && doubleBetValue <=999999999){
                                        doubleBetValue = Number((doubleBetValue / 1000).toFixed(2))+"M";
                                        console.log(doubleBetValue);
                                      }else if(doubleBetValue >999999999){
                                        doubleBetValue = Number((doubleBetValue / 1000).toFixed(2))+"B";
                                        console.log(doubleBetValue);
                                      } 
                                      
                                      document.getElementById("id_" + k).innerHTML = `<p class="goldCoin"> <img src=${coin} /> <span> ${Number(doubleBetValue)} </span> </p>`;
                                      

                                    //   document.getElementById("id_" + k).innerHTML = `<p class="goldCoin"> <img src=${coin} /> <span> ${this.state.betString[k].amount} </span> </p>`;
                                  }else{
                                      this.isReady = false;
                          this.setState({         
                          disDoubleBtn: 1,     
                          disClearBtn: 0,
                          disZoomBtn: 0,
                          infoText:this.props.language.LowBalance ,
                          });
          
                                  }
          
                              }else{
                                console.log(innerMaxLimit);
                                let maxBetMount = this.changeAmtlabl(innerMaxLimit)
                                setTimeout(() => {
                                  this.setState({infoText: this.props.language.MaxInrBetAmt+" "+maxBetMount});
                                },500)
                                
                              }
                              totBet += this.state.betString[k].amount;
                              this.setState({
                                  Total_Bet_Amount: totBet,
                              });
                          }
                          }else{
              
                              this.isReady = false;
                              this.setState({         
                              disDoubleBtn: 1,     
                              disClearBtn: 0,
                              disZoomBtn: 0,
                              infoText:this.props.language.LowBalance ,
                              });
              
              
                          }
      
                      }
                  //console.log("Total Bet: "+totBet);
      
                  if(totBet <= this.props.balance){
                      this.setState({
                      // Total_Bet_Amount: totBet,
                      });
                          
                      this.setState({
                      betString: this.state.betString,
                      disDoubleBtn: 0,     
                      disClearBtn: 0,
                      disZoomBtn: 0,
                      infoText: this.props.language.Play_Njoy,
                      });
      
                  }else {
                      this.isReady = false;
                      this.setState({         
                      disDoubleBtn: 1,     
                      disClearBtn: 0,
                      disZoomBtn: 0,
                      infoText:this.props.language.LowBalance ,
                      });
                  
                  }
              }else{
                  this.isReady = false;
                      this.setState({                         
                      infoText:this.props.language.LowBalance ,
                      });
                  
              }       

            }
            
        }else{
          for(let a=0; a<this.state.betString.length ; a++){
            if(this.state.betString[a].amount > 0 ){
              this.state.betString[a].amount = 0;
              document.getElementById("id_" + a).innerHTML = "";
            }
            totBet += this.state.betString[a].amount;
          }
    
          this.setState({
            betString: this.state.betString,
            disDoubleBtn: 1,     
            disClearBtn: 1,
            // disZoomBtn: 0,
            // disBetBtn: 0,
            // betButtonText: "PREV", 
            infoText: this.props.language.Play_Njoy,
            //Total_Bet_Amount: totBet,
          });
         console.log("Enable Prev Button")
        }
 
    }

    showPrevBet() {
        if(this.props.gameState.betRequest.betAmount <= this.props.balance){ 
            if(this.props.gameState.betRequest.betAmount > this.props.maxBetAmt){
                this.setState({ infoText:this.props.language.PreBet_MaxLmt });
            } else{

                if(this.state.Total_Bet_Amount == 0){
                    let prevBetString=[];
                    console.log("Prev Bet String Length: ");
                    console.log(this.props.gameState);
                    console.log(this.props.gameState.betRequest.betString);
                    console.log(this.props.gameState.betRequest.betString.length);
                    // let myArr = this.props.gameState.betRequest.betString.split(",");
                    console.log(this.props.gameState.betRequest.betString);
                    var newStr = this.props.gameState.betRequest.betString.replace(/,/g, "|");
                    console.log("newStr : "+newStr)
                    //var newStr0 = newStr.slice(0, -1);
                    // console.log("newStr0 : "+newStr0)
                    var newStr1 = newStr.replace(/ /g, "0");
                    console.log("newStr1 : "+newStr1);
                    var newStr2 = newStr1.split('|');
                    var totalBetAmt = 0;
                    console.log("newStr2 : "+newStr2);
                    console.log("newStr2 length: "+newStr2.length);
                    for(var i=0; i<newStr2.length; i++){
                        console.log("---- : "+Number(newStr2[i]));
                        // if(Number(newStr2[i]) != NaN){
                        //     totalBetAmt = totalBetAmt+Number(newStr2[i]); 
                        // }
                        if (!isNaN(Number(newStr2[i]))) {
                            totalBetAmt = totalBetAmt+Number(newStr2[i]);
                        }                    
                        prevBetString.push({
                            id: i,
                            amount: Number(newStr2[i])
                        });
                        
                    }
                    console.log("Previous Total Bet Amount: "+totalBetAmt)                
                    this.state.betString = prevBetString.slice();
                }
                console.log("Previous Bet Amount: "+totalBetAmt);
                console.log("Player Balance: "+this.props.balance);
                
                this.setState({
                    Total_Bet_Amount : totalBetAmt
                })
                console.log("Prev Total Bet: "+this.state.Total_Bet_Amount);
                for(var k=0; k<this.state.betString.length; k++){
                    // var value  = this.state.betString[k].amount;
    
                    let preBetValue = Number((this.state.betString[k].amount).toFixed(2));
                    if (preBetValue > 0) {
                    //console.log("Bet Value: 488: " + value);
                    if(preBetValue >= 1000 && preBetValue <=999999){
                    preBetValue = Number((preBetValue / 1000).toFixed(2))+"K";
                    console.log(preBetValue);
                    }else if(preBetValue >= 1000000 && preBetValue <=999999999){
                    preBetValue = Number((preBetValue / 1000).toFixed(2))+"M";
                    console.log(preBetValue);
                    }else if(preBetValue >999999999){
                    preBetValue = Number((preBetValue / 1000).toFixed(2))+"B";
                    console.log(preBetValue);
                }
                document.getElementById("id_"+k).innerHTML = `<p class="goldCoin"> <img src=${coin} /> <span> ${preBetValue} </span> </p>`
                }
                    
                    // if(value > 0){
                    //     document.getElementById("id_"+k).innerHTML = `<p class="goldCoin"> <img src=${coin} /> <span> ${value} </span> </p>`
                    // }
                }
                if(this.state.betButtonValue === 0){
                    var valId10 = document.getElementById('bet_take');
                    valId10.classList.remove('active');
                    this.setState({
                        showBetButton: true,
                        betButtonText: this.props.language.Spin,
                        betButtonValue:1,
                        btnActive:true
                    });        
                    this.isReady = true;
                }
                this.setState({
                    betString: this.state.betString,
                    disClearBtn: 0,
                    disDoubleBtn: 0,
                    disZoomBtn: 0,
                }) 

            }
                     
        }else{
            this.setState({infoText: this.props.language.Bet_Amt_MaxBal})
        }        
    }
    betHandler(amount) {
        console.log(amount);
        this.setState({ betAmount: amount });
    }
    betPanelHandler(no) {
        this.setState({ betButtonText:  this.props.language.Spin,betButtonValue:1,btnActive:true });
        console.log(this.state.betAmount);
        if (this.state.betAmount !== 0) {
            console.log(no);
            const x = no;
            switch (true) {
                case (x < 171):
                    for (let i = 0; i < this.state.betString.length; i++) {
                        if (this.state.betString[i].id === no) {
                            let value = this.state.betString[i].amount += this.state.betAmount;
                            document.getElementById("id_" + no).innerHTML = `<p class="goldCoin"> <img src=${coin} /> <span> ${Number(value.toFixed(2))} </span> </p>`
                        }
                    }
                    this.setState({ betString: this.state.betString });
                    console.log(this.state.betString);
                    break;
                default:
                   // alert("none");
                    break;
            }
        }
    }
    placebet() {
        console.log("American Roulette: Send Bet Request")
        const body = {
            sessionId: sessionStorage.getItem('sessionId'),
            CN: "ROULETTE_BET",
            object: {
                betString: this.state.betstr,
                playerId: Number(this.props.playerId),
                gameId: this.props.gameId
            }
        }
        this.props.network.sendof(body);
        this.body={};
        let betAmountLabel;
        if(this.state.Total_Bet_Amount){
            betAmountLabel = this.changeAmtlabl(this.state.Total_Bet_Amount)
        }
        // this.setState({infoText:this.props.language.BetAccepted+this.state.Total_Bet_Amount});
        this.setState({infoText:this.props.language.BetAccepted+" "+betAmountLabel});


       // this.getPlayerInfo();
    }
    
    getPlayerInfo() {
        const body2 = {
            CN: "GET_PLAYER_INFO",
            object: {
            gameId: Number(this.state.gameId),
            playerId: Number(this.props.playerId),
            },
            sessionId: sessionStorage.getItem('sessionId'),
        }
        this.props.network.sendof(body2);
        this.body2 ={};
    }

    americanRoulleteGetwheelresp() { 
        console.log(this.props.targetResult.resultNum + " AmericanRoullete Result winAmount: "+this.props.targetResult.winAmount);
        console.log(this.props.gameState );

        if (this.props.targetResult) {
            this.resultNumber = this.props.targetResult.resultNum;
            if(this.state.zoomButtonValue == 0){
                this.setState({wheelZoom: 1.5, zindex: '1000', X_top: "21.8vh", x_img:1 });
            } 
            this.artWheelRef.current.spinTheWheel(this.resultNumber);
        }
        this.wheelSound.play();
    }
    glowImage(){
        this.setState({wheelZoom: 1, zindex: '0', X_top: "14.5vh", });
        
        if(this.props.targetResult.winAmount > 0){
            document.getElementById('winPop').style.display = 'block'; 
            this.getPlayerInfo();
            setTimeout(()=>{
                document.getElementById('winPop').style.display = 'none';  
                for(var i=0; i<this.state.betString.length; i++){
                    document.getElementById("id_"+i).innerHTML = ``;            
                    this.state.betString[i].amount = 0;
                }    

                // this.state.Total_Bet_Amount = 0;
                // this.setState({Total_Bet_Amount: 0});  
            },2000)
            this.winSound.play();
        }else{
            this.duploseSound.play();
            for(var i=0; i<this.state.betString.length; i++){
                document.getElementById("id_"+i).innerHTML = ``;            
                this.state.betString[i].amount = 0;
            }    
            this.getPlayerInfo();
            setTimeout(()=>{
                // this.state.Total_Bet_Amount = 0;
                // this.setState({Total_Bet_Amount: 0});  
            },1000)
        }
        this.removeImgGlow();

        console.log("Glow Win Image: "+ this.resultNumber);
        this.setState({winAmount: this.props.targetResult.winAmount});
        
        if(this.resultNumber == 37){
            if(document.getElementById('id_0') != null){
                document.getElementById('id_0').classList.add('active');
            }
        }else{
            if(document.getElementById('id_'+Number(this.resultNumber+1)) != null){
                document.getElementById('id_'+Number(this.resultNumber+1)).classList.add('active');
            }
        }

        this.declareWin(this.props.targetResult.winAmount);
    }  
    AmericanRouletteTimerGetTime() {
        console.log(this.props.timesend);
        if (this.props.timesend) {
            console.log("American Current Time: "+this.props.timesend)
            console.log(this.props.timesend);
            this.AmericanTimerInSeconds.current.sendTimer(this.props.timesend);
        }
    }
    chipVal(val){
        this.takeSound = new Audio(takeSound);
        this.takeSound.play();
        console.log("American Non-Timer Val  "+val);
        this.state.selectedChipVal = val;
        console.log( "check ::" + this.state.selectedChipVal );
         this.setState({
            selectedChipVal: val,
            //infoText: `Your Bet Amount Is: ${val} & You Are Ready To Go`
        });
    }
    benohandle(id) {
        this.betSound1 = new Audio(betSound)
        this.betSound1.play();
        this.removeImgGlow();
        console.info("benohandle: ");
        console.info("Selected Chip Value: "+this.state.selectedChipVal);
        console.info("Selected ID Value: "+ id);
        if(this.state.selectedChipVal == 0){
            this.state.betString[id].amount = 0;
            document.getElementById("id_"+id).innerHTML = ``
        }else{
            // this.setState({
            //     disClearBtn: 0,
            //     disBetBtn: 0,
            //     disDoubleBtn: 0,
            // });
        }
        console.log("this.state.betString: ")
        console.info(this.state.betString);
        console.log("Length: "+this.state.betString.length)
        for(var i = 0; i<this.state.betString.length; i++){
            if(this.state.betString[i].amount === "NaN" || this.state.betString[i].amount === NaN) {
               this.state.betString[i].amount = 0;
            }
            // console.log("--------- "+this.state.betString[i].amount);
        }

        const totalbet = this.state.betString.reduce((total, m) => Number((m.amount + total).toFixed(2)), Number(this.state.selectedChipVal))
       // const totalbet = this.state.nmArray.reduce((total, m) => m.amount + total, Number(this.state.selectedChipVal))
        console.log("New Bet Amount "+totalbet);
        console.log("this.props.balance: "+this.props.balance);
        if(totalbet > this.props.maxBetAmt){
            // this.setState({infoText: this.props.language.Min_Bet+" "+this.props.minBetAmt+" "+this.props.language.Max_Bet+" "+this.props.maxBetAmt});
            this.setState({infoText: this.props.language.MinBetAmt+" "+this.props.minBetLbl+" "+this.props.language.MaxBetAmt+" "+this.props.maxBetLbl});
            // this.setState({infoText: "Min Bet "+this.props.minBetAmt+" and Max Bet "+this.props.maxBetAmt});
          }else if(totalbet <= this.props.balance ){

            if (this.state.betString[id] != null && this.state.betString[id].amount != null) {

                let innerLimit = 1;
                try{
                  if(id<=37){                  
                    innerLimit = this.newLimits[0][1];                  
                    
                  }else if(id >= 38 && id <= 99){
                    innerLimit = this.newLimits[1][1];                      
                  }else if(id >= 100 && id <= 114){
                    innerLimit = this.newLimits[2][1];                      
                  }else if(id >= 115 && id <= 137){
                    innerLimit = this.newLimits[3][1];            
                  }else if(id>=138 && id <= 148){
                    innerLimit = this.newLimits[5][1];
                  }else if(id>=154 && id <= 159){
                    innerLimit = this.newLimits[6][1];
                  }else if(id >= 165 && id <= 170){
                    innerLimit = this.newLimits[7][1];
            
                  }
          
                }catch (err)
                {
                  console.log("error while trying to fetch inner limits: ArayOutOfBoundsException "+ err.message);
                }
                console.log("FST Inner Limit:  "+innerLimit)


                if((this.state.betString[id].amount+Number(this.state.selectedChipVal)) <= innerLimit){
                    this.isReady = true;
                    this.setState({
                        Total_Bet_Amount : totalbet,
    
                    })
                    console.log("Total Bet Value: "+this.state.Total_Bet_Amount);
                    for(var j=0; j<this.state.betString.length; j++){
                        if(this.state.betString[j].id == id){
                            if(this.state.selectedChipVal == 0){
                                var value  = this.state.betString[j].amount *= Number(this.state.selectedChipVal);
                                document.getElementById("id_"+j).innerHTML = ``
                            }else{
                                // var value  = this.state.betString[j].amount += Number(this.state.selectedChipVal);
                                let chipBetValue =  this.state.betString[j].amount = Number((this.state.betString[j].amount + Number(this.state.selectedChipVal)).toFixed(2));
                                console.log("Bet Value: 699: " + chipBetValue);
                                console.log("Bet Value: 699: " + typeof(chipBetValue));
                                if(Number(chipBetValue) >= 1000 && Number(chipBetValue) <=999999){
                                  chipBetValue = Number((chipBetValue / 1000).toFixed(2))+"K";
                                  console.log(chipBetValue);
                                }else if(chipBetValue >= 1000000 && chipBetValue <=999999999){
                                  chipBetValue = Number((chipBetValue / 1000).toFixed(2))+"M";
                                  console.log(chipBetValue);
                                }else if(chipBetValue >999999999){
                                  chipBetValue = Number((chipBetValue / 1000).toFixed(2))+"B";
                                  console.log(chipBetValue);
                                }

                                this.setState({
                                    disClearBtn: 0,
                                    disBetBtn: 0,
                                    disDoubleBtn: 0,
                                });

                                document.getElementById("id_"+j).innerHTML = `<p class="goldCoin"> <img src=${coin} /> <span> ${chipBetValue} </span> </p>`
                            }
                        }
    
                        if( this.removeGlow === true){
                            //document.getElementById('id_'+i).classList.remove('active');
                            var valId11 = document.getElementById('id_'+j);
                            if(valId11){
                                valId11.classList.remove('active');
                            }
                        }
                    }
    
                    var TotalBetVaue = this.state.betString.reduce((prev, cur) => Number((prev + cur.amount).toFixed(2)), 0);
                    console.log("Total Bet Amount: "+TotalBetVaue );
                    if(TotalBetVaue == 0){
                        this.setState({disDoubleBtn: 1, disClearBtn: 1, betButtonText: this.props.language.Prev,betButtonValue:0, disZoomBtn:0,btnActive:false});
                        if(this.props.gameState.betRequest){
                            this.setState({betButtonText: this.props.language.Prev,betButtonValue:0,btnActive:false});
                        }else{
                            this.setState({disBetBtn: 1});
                        }
                    }else{
                        this.setState({disDoubleBtn: 0, disClearBtn: 0, showBetButton: true});
                        this.removeGlow = false;
                        if(this.state.betButtonValue == 0){
                            this.setState({
                                showBetButton: true,
                                betButtonText: this.props.language.Spin,
                                betButtonValue:1,
                                btnActive:true
                            });
                            var valId12 = document.getElementById('bet_take');
                            valId12.classList.remove('active');
                        }
                    }
                }else{

                    let maxBetMount = this.changeAmtlabl(innerLimit)
                    setTimeout(() => {
                      this.setState({infoText: this.props.language.MaxInrBetAmt+""+maxBetMount}) 
                    },150)
                    
                     console.log(this.state.infoText)
                }
            }

             if(this.state.selectedChipVal == 0 && this.state.Total_Bet_Amount ==0){
                this.setState({infoText:this.props.language.MinBetAmt + " " + this.props.minBetLbl})
                setTimeout(()=>{
                    this.setState({ infoText:  this.props.language.Play_Njoy });
                  },1500)
            }else{
                this.setState({
                    //  infoText:  this.props.language.Play_Njoy 
                    infoText: this.props.language.MinBetAmt+" "+this.props.minBetLbl+" "+this.props.language.MaxBetAmt+" "+this.props.maxBetLbl,
                    });
            }
        }else{
           this.setState({infoText: this.props.language.LowBalance})
            console.log("amount reduces to your balance")
            //alert('amount reduces to your balance');
        }
       
        this.setState({
            betString: this.state.betString
        })
    }
    loadBg(){
        this.LoadImg = new window.Image()
        this.LoadImg.src = artBg; 
        this.LoadImg.addEventListener('load', this.americanRouletteLoded)
      }

    americanRouletteLoded = () => {
        if(!this.isloadImg){
            this.isloadImg = true; 
    } else{ 
            this.setState({
                amrLoader:false
            })
        }
    }

    updatePlayerInfo(){
        // alert('----   '+this.props.balance);
        if(this.props.targetResult.winAmount > 0 ){
            this.setState({ Total_Bet_Amount: 0})     
            setTimeout(()=>{
                document.getElementById('winPop').style.display = 'none';          
               if(this.updatePlayerBalnce == true){
                    this.setState({
                        disZoomBtn: 0,
                        disBetBtn: 0,
                        btnActive : false,
                        betButtonText:this.props.language.Prev,
                        betButtonValue:0,                        
                    });
                    document.getElementById('rouletteTableCover').style.display = 'none';
                }
            }, 3000)
        }else{
            if(this.updatePlayerBalnce == true){
                this.setState({
                    disZoomBtn: 0,
                    disBetBtn: 0,
                    btnActive : false,
                    betButtonText:this.props.language.Prev,
                    betButtonValue: 0, 
                    Total_Bet_Amount: 0,
                });
                document.getElementById('rouletteTableCover').style.display = 'none';
            }else{
                if(this.props.balance > 0){
                    document.getElementById('rouletteTableCover').style.display = 'none';
                    this.setState({disBetBtn: 0, disZoomBtn: 0 })
                }

                if(this.props.gameState.history && this.props.gameState.history.length == 0){
                    this.setState({disBetBtn: 1});
                }
            }
        }        
        
    //     for(var i=0; i<this.state.betString.length; i++){
    //         document.getElementById("id_"+i).innerHTML = ``;            
    //         this.state.betString[i].amount = 0;
    //     }

    //    // this.setState({betButtonText: "PREV", btnActive : false, disZoomBtn: 0 });

    //     this.state.Total_Bet_Amount = 0;
    //     this.setState({Total_Bet_Amount: 0});
    }

    render() {
        let winAmount = this.changeAmtlabl(this.props.targetResult.winAmount)
        return (
            <React.Fragment>
                    <div className="gameBox" id="gameBox">        
            <div className="gamePage FGAamericanRoulette"> 
                <div className="fd american_ht_1 p_30">
                    <div className="fd">
                    <div className="row">
                        <div className="fd">
                        <div className="wheelandhis">
                            <div className="row">
                                <div className="fun_chips">
                                    <BetChips
                                     childRef={ref => (this.BetChips = ref)}
                                        bet={this.betHandler.bind(this)}
                                        winAmount={this.state.winAmount}
                                        balance={this.props.balance}
                                        TotalBetAmouont={this.state.Total_Bet_Amount}
                                        totalBet={this.state.betString}
                                        betAmount={this.chipVal.bind(this)}
                                        action={this.rightButtonPanelHandler.bind(this)}
                                        disDoubleBtn ={this.state.disDoubleBtn}
                                        disClearBtn = {this.state.disClearBtn}
                                        ar_chipValues = {this.props.ar_chipValues}
                                    />
                                </div>
                                <div className="fun_routlet_table">
                                    <div className="fd">
                                        <Title className="fd"></Title>
                                        <div className="fd">
                                        {/* <div className="fd" style={{ height: '40vh' }}> */}
                                            <Wheel
                                                ref={this.artWheelRef}
                                                zindex={this.state.zindex}
                                                zoom={this.state.wheelZoom}
                                                height={this.wheelHeight}
                                                width={this.wheelWidth}
                                                action={this.wheelHandler.bind(this)}
                                                isMobile={this.props.isMobile}
                                                gameState={this.props.gameState}
                                                showGlow={this.glowImage.bind(this)}
                                                onFocus={this.props.onFocus}
                                                americanRouletteLoded={this.americanRouletteLoded.bind(this)}
                                                >
                                            </Wheel>
                                        </div>
                                    </div> 
                                </div>
                                <div className="ar_btns">
                                    <RightButtonPanel
                                        childRef={ref => (this.RightButtonPanel = ref)}
                                        user={this.props.user}
                                        betBtnText={this.state.betButtonText}
                                        betButtonValue={this.state.betButtonValue}
                                        gameState={this.props.gameState}
                                        targetResult={this.props.targetResult}
                                        zoomButtonText={this.state.zoomButtonText}
                                        zoomButtonValue={this.state.zoomButtonValue}

                                        betBtn={this.state.showBetButton}
                                        action={this.rightButtonPanelHandler.bind(this)}
                                        stop={this.state.stop}                                        
                                        disBetBtn ={this.state.disBetBtn}
                                        disZoomBtn = {this.state.disZoomBtn}   
                                        disDoubleBtn = {this.state.disDoubleBtn}                                     
                                        disClearBtn = {this.state.disClearBtn}
                                        glowTakeBtn = {this.state.glowTakeBtn}
                                        ref={this.RightButtonPanel}
                                        network={this.props.network}
                                        btnActive={this.state.btnActive}
                                        >
                                    </RightButtonPanel>                                    
                                    <div id="rouletteTableCover" className="rouletteTableCover"></div>
                                </div>
                            </div>
                        </div>
                        <div className="table_arnt">
                            <div className="fd">
                                <Table 
                                    betAmount={this.chipVal.bind(this)}
                                    action={this.benohandle.bind(this)}
                                    infoText = {this.state.infoText}
                                    className="fd">
                                </Table>
                            </div>
                        </div>
                        </div>
                    </div>
                    </div>
                    <div className="american_ins"> 
                    <div className="fd">
                        <div className="row">
                            <div className="col-3 col-sm-2">
                                <div className="amr_userLbl"> {this.props.user} </div> 
                            </div>
                            <div className="col-6 col-sm-8">
                                <div className="amr_info"> {this.state.infoText} </div>
                            </div>
                            { this.props.gameEntry == 'in' ?
                            <div className="col-3 col-sm-2">
                                <div className="fd box_clm"> 
                                    <button className="fd ar_bt_1 z_1500" disabled={this.props.disZoomBtn == 1}
                                    onClick={(e)=>{e.preventDefault(); this.gameCloseHandler("target");}}>
                                    {this.props.language.Exit} </button> 
                                </div>
                            </div> :  
                            <div className="col-3 col-sm-2">
                                <div className="amr_userLbl hId">
                                    <div>
                                    {this.props.language.Player_Id} {this.props.playerId } <br></br>  
                                    {this.props.language.Hand_Id}  {this.props.gameState.handId} 
                                </div> 
                                </div> 
                            </div>
                             }
                        </div>
                    </div> 
                </div> 
                </div>
            </div>
            </div>
            <div className="winPopAmrcRlt" id="winPop"> 
                <div className="popBorder">
                    {this.props.language.Win_Amt} &nbsp; 
                    <span> {winAmount} </span> 
                </div>
            </div>
            </React.Fragment>
        );
    }
}
const mapStatesToProps = (state)=>{
    return {
        language : state.languageObjs.languageObj
    }
  }
export default connect(mapStatesToProps)(AmericanRoulette) 
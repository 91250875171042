import React from "react";
import "../../../../../css/games/funTarget/leftButtonPanel.css";
import { useSelector } from "react-redux";
function LeftButtonPanel(props) {
    const language = useSelector((state) => state.languageObjs.languageObj)
    const roundNumber = props.balance - props.totalBet     
    const betAmount = (roundNumber)

    let updatebal = topLable_fn(betAmount)
    
    let updateBet = topLable_fn(props.totalBet);
    
    // function topLable_fn(betAmount){
    //     if(betAmount >= 1000 && betAmount <=999999){
    //         betAmount = betAmount / 1000+"K";      
    //     }else if(betAmount >= 1000000 && betAmount <=999999999){
    //       betAmount = betAmount / 1000+"M";    
    //     }else if(betAmount >999999999){
    //       betAmount = betAmount / 1000+"B";      
    //     }
    //     console.log(betAmount)
    //     return betAmount;
    // }

    // function topLable_fn(labelValue) 
    // {
    //     // Nine Zeroes for Billions
    //     return Math.abs(Number(labelValue)) >= 1.0e+9
    
    //     ? Math.abs(Number(labelValue)) / 1.0e+9 + "B"
    //     // Six Zeroes for Millions 
    //     : Math.abs(Number(labelValue)) >= 1.0e+6
    
    //     ? Math.abs(Number(labelValue)) / 1.0e+6 + "M"
    //     // Three Zeroes for Thousands
    //     : Math.abs(Number(labelValue)) >= 1.0e+3
    
    //     ? Math.abs(Number(labelValue)) / 1.0e+3 + "K"
    
    //     : Math.abs(Number(labelValue));
    // }

    function topLable_fn(Value) {
        let labelValue = Math.abs(Number(Value))
        // Nine Zeroes for Billions
        return labelValue >= 1.0e+9
      
        ? Number((labelValue / 1.0e+9).toFixed(2)) + "B"
        // Six Zeroes for Millions 
        : labelValue >= 1.0e+6
      
        ? Number((labelValue / 1.0e+6).toFixed(2)) + "M"
        // Three Zeroes for Thousands
        : labelValue >= 1.0e+3
      
        ? Number((labelValue / 1.0e+3).toFixed(2)) + "K"
      
        : Number(labelValue.toFixed(2));
      }
    
    console.log(language)
    return (
        <div className="sorat_leftPanl">
            <div className="fl_1">
                <div className="funBal fd">
                    {/* <div className=""> {props.balance - props.totalBet} </div> */}
                    <div className=""> {updatebal} </div>

                    <div className="">{language.Balance} </div>
                </div>
            </div>
            <div className="fl_1">
                <div className="funBal fd">
                    <div className=""> 
                      {updateBet}
                    </div>
                  
                    <div className=""> {language.TotalBet}</div>
                </div>
            </div> 
        </div>
         
    )
}export default LeftButtonPanel
import React from "react";
import "./UI/funcard.css";
import ChipsDealerPlayer from "./UI/ChipsDealerPlayer";
import BottomPanel from "./UI/bottomPanel";
import TopLable from "./UI/topLable";
import Close from "./UI/close";
import coin from "../../../../../assets/gold_coin.svg";
import fcBg from "../../../../../assets/games/funCard/fun-card-bg.png";
import clearSound from "../../../../../assets/sounds/clear.mp3";
import betSound from "../../../../../assets/sounds/bet.mp3";
import takeSound from "../../../../../assets/sounds/take.mp3";
import winSound from "../../../../../assets/sounds/win.mp3";
import duploseSound from "../../../../../assets/sounds/duplose.mp3";
import close from "../../../../../assets/sounds/exit.mp3";


import { connect } from "react-redux";

 class FunCard extends React.Component {
  constructor(props) {
    super(props);
    this.ChipsDealerPlayer = React.createRef();
    this.BottomPanel = React.createRef();
    this.state = {
      chipVal: 1,
      betAmt: 0,
      betCard: null,
      betCount: 0,
      //takeAmt: 0,
      delearFirst: true,
      message: this.props.language.Play_Njoy,
      cardsArray: "",
      betAmountArray: "",
      winnerArray: "",
      betButtonText: this.props.language.Deal,
      betButtonValue: 1,
      btnActive: false,
      disable: true, 
      disableDealBtn: true, 
      disableClearBtn: true,
      fcardLoader: true,
      targetResult: [],
    };
    this.clearSound = new Audio(clearSound);
    this.duploseSound = new Audio(duploseSound);
    this.takeSound = new Audio(takeSound);
    this.close= new Audio(close);
    this.winSound = new Audio(winSound);
    this.betSound = new Audio(betSound);
    this.betPlaced = false;
    this.resultShown = false;
    this.historyData = [];
    this.winLbl = 0;

    this.takeAmount = 0;

    this.getCardHistory();
    this.getBetHistory();
    this.getWinnerHistory(); 
    this.loadBg();  
  }
  /*************** THIS METHOD HITTING FROM APP.JS WHEN GET RESULT **********************/
  funCardGetResp() {
    //console.log("funCardGetResp  ")
    if(!this.resultShown){
      this.resultShown = true;
      this.setState({targetResult: this.props.targetResult});
      let cardLen = (this.props.targetResult.dealerCards.length + this.props.targetResult.playerCards.length);
      console.log( this.props.targetResult.dealerCards.length + " check " + this.props.targetResult.playerCards.length )
      let animTime = (cardLen * 700) + 1000;  
      if (this.props.targetResult && this.props.targetResult.dealerCards) {
        setTimeout(() => {
          this.winnerSelection();
          this.getPlayerInfo();
          //this.setState({betAmt: 0});
        }, animTime);
        if (this.props.targetResult.playerCards && this.ChipsDealerPlayer) {
          this.ChipsDealerPlayer.dealerCards();//current
        }
      }
    }
   
    
      if(this.props.targetResult.winAmount > 0){
        this.takeAmount = this.props.targetResult.winAmount;
        if (this.state.cardsArray.length >= 10 || this.state.winnerArray.length >= 10) {
          this.state.winnerArray.splice(0, 1);
          this.state.winnerArray.push("Player");

          this.state.cardsArray.splice(0, 1);
          this.state.cardsArray.push(this.props.targetResult.playerCards[this.props.targetResult.playerCards.length-1]);

          this.state.betAmountArray.splice(0, 1);
          this.state.betAmountArray.push(Number(this.state.betAmt.toFixed(2)));
        }else{
          this.state.winnerArray.push("Player");
          this.state.cardsArray.push(this.props.targetResult.playerCards[this.props.targetResult.playerCards.length-1]);
          this.state.betAmountArray.push(Number(this.state.betAmt.toFixed(2)));
        }
        
      }else{
        if (this.state.cardsArray.length >= 10 || this.state.winnerArray.length >= 10) {
          this.state.winnerArray.splice(0, 1);
          this.state.winnerArray.push("Dealer");

          this.state.cardsArray.splice(0, 1);
          this.state.cardsArray.push(this.props.targetResult.dealerCards[this.props.targetResult.dealerCards.length-1]);

          this.state.betAmountArray.splice(0, 1);
          this.state.betAmountArray.push(Number(this.state.betAmt.toFixed(2)));

        }else{
          this.state.winnerArray.push("Dealer");
          this.state.cardsArray.push(this.props.targetResult.dealerCards[this.props.targetResult.dealerCards.length-1]);
          this.state.betAmountArray.push(Number(this.state.betAmt.toFixed(2)));
        }

      }

      sessionStorage.setItem("winnerObj", JSON.stringify(this.state.winnerArray));
      sessionStorage.setItem("cardsObj", JSON.stringify(this.state.cardsArray));
      sessionStorage.setItem("betObj", JSON.stringify(this.state.betAmountArray));

      console.log( JSON.parse(sessionStorage.getItem("cardsObj")));
      console.log( JSON.parse(sessionStorage.getItem("betObj")));
      console.log(  JSON.parse(sessionStorage.getItem("winnerObj")));

      this.historyData[0] = JSON.parse(sessionStorage.getItem("cardsObj"));
      this.historyData[1] = JSON.parse(sessionStorage.getItem("betObj"));
      this.historyData[2] = JSON.parse(sessionStorage.getItem("winnerObj"));
  }

    componentDidMount() { 
      const { childRef } = this.props;
      childRef(this);

      this.historyData[0] = JSON.parse(sessionStorage.getItem("cardsObj"));
      this.historyData[1] = JSON.parse(sessionStorage.getItem("betObj"));
      this.historyData[2] = JSON.parse(sessionStorage.getItem("winnerObj"));
      // if(this.ChipsDealerPlayer && this.ChipsDealerPlayer.current){
        if(this.ChipsDealerPlayer ){

        if(this.historyData != null && this.historyData[0] != null){ 
          this.ChipsDealerPlayer.updateHistory(this.historyData);
        } 
      }
      this.setState({disableDealBtn: true, })

      document.addEventListener("keyup", this.fc_handleKeyboardEvent);    
      document.addEventListener("keydown", this.fc_bets_handleKeyboardEvent);

      if(this.props.fc_chipValues){
        let newChips = this.props.fc_chipValues.split(",");
        console.log(newChips[0]);  
        let initChipVal = newChips[0]; 
        console.log(initChipVal);
        this.state.chipVal = Number(initChipVal);
        this.setState({chipVal: Number(initChipVal)});
      }
      console.log(this.state.chipVal);
    }

  componentWillUnmount(){ 
    document.removeEventListener("keydown", this.fc_bets_handleKeyboardEvent);    
    document.removeEventListener("keyup", this.fc_handleKeyboardEvent);
   // this.winSound.pause();
    //this.gameCloseHandler('target');
  }

  fc_bets_handleKeyboardEvent= (evt) => {
    if(this.state.betButtonValue != 2){
      // console.log(document.getElementById("funCardCover").style.display)
      if(document.getElementById("funCardCover") && document.getElementById("funCardCover").style.display != "block"){
        
        if(evt.keyCode >= 48 && evt.keyCode <= 57 && evt.keyCode != 49){
          console.log(evt.key)      
          if(evt.keyCode == 48){
            this.selectedCard(9);

          }else{
            this.selectedCard(evt.key-1);
          }
        }
        if(evt.keyCode >= 96 && evt.keyCode <= 105 && evt.keyCode != 97){
          console.log(evt.key)      
          if(evt.keyCode == 96){
            this.selectedCard(9);

          }else{
            this.selectedCard(evt.key-1);
          }
        }
        if(evt.keyCode == 74){
          this.selectedCard(10);
        }
        if(evt.keyCode == 81){
          this.selectedCard(11);
        }
        if(evt.keyCode == 75){
          this.selectedCard(12);
        }
        if(evt.keyCode == 65){
          this.selectedCard(0)
        }
      }
    }
  }

  fc_handleKeyboardEvent = (evt) => {
    console.log(evt);
    console.log(evt.keyCode);
    console.log (this.state.betButtonText+"      "+this.state.disableDealBtn);
    switch(evt.code){
      case "Enter":
      case "NumpadEnter":
        if (this.state.betButtonValue == 1 && this.state.disableDealBtn == false) {
          this.setState({disableDealBtn: true});
          console.log (this.state.betButtonText+"      "+this.state.disableDealBtn);
          this.dealBtn(1);
        }
        break;
      case "Space":
        console.log(this.state.betButtonText);
        console.log(this.state.disableDealBtn);
        
        if(this.state.betButtonValue == 2 && this.state.disableDealBtn == false){
          this.dealBtn("TAKE") 
          // this.rightButtonPanelHandler("TAKE");
        }
        break;
      case "Escape":
        //this.gameCloseHandler();
        break;
      case "KeyC":      
        if(this.state.betButtonValue == 1 && this.state.disableDealBtn == false){
          this.cleareBtn();
        }
        break;    
      case "default":
        break;  
    }
  }
 
  gameCloseHandler(game) { 
      this.close.play();      
      console.log(this.winSound);
      if(this.winSound.playing){
        this.winSound.pause();
      }
      this.duploseSound.pause();
      this.clearSound.pause();
      this.betSound.pause();
      this.takeSound.pause();
      this.props.action(game);
  }

  getChipVal(val) {
    if(this.betSound){
      this.betSound.play();
    }
    this.setState({
        chipVal: Number(val),
    });
    console.log(this.state.chipVal);
  }

  changeAmtlabl(chngAmt){
    let newAmt = Number((chngAmt).toFixed(2));
    if(newAmt >= 1000 && newAmt <=999999){
      newAmt = Number((newAmt / 1000).toFixed(2))+"K";      
    }else if(newAmt >= 1000000 && newAmt <=999999999){
      newAmt = Number((newAmt / 1000).toFixed(2))+"M";    
    }else if(newAmt >999999999){
      newAmt = Number((newAmt / 1000).toFixed(2))+"B";      
    }
    return newAmt;
  }

  selectedCard(id) {
   
  
    let totalBetVal=0;
    console.log(this.state.betAmt);
    console.log(this.state.chipVal);
    console.log(id);
    this.setState({message: this.props.language.MinBetAmt+" "+this.props.minBetLbl+" "+this.props.language.MaxBetAmt+" "+this.props.maxBetLbl,});
    totalBetVal = Number((this.state.betAmt + this.state.chipVal).toFixed(2));

    console.log("Bet Amount: "+totalBetVal);
    console.log("Balance: "+this.props.balance);

    if(totalBetVal > this.props.maxBetAmt){
      // this.setState({message: this.props.language.Min_Bet+" "+this.props.minBetAmt+" "+this.props.language.Max_Bet+" "+this.props.maxBetAmt});
      this.setState({message: this.props.language.MinBetAmt+" "+this.props.minBetLbl+" "+this.props.language.MaxBetAmt+" "+this.props.maxBetLbl,});
    }else if (totalBetVal <= this.props.balance ) {
    
      if(totalBetVal <= this.props.maxBetAmt){
    
        this.state.betCard = id;
        // this.setState({betAmt: totalBetVal, disable : false, message:this.props.language.Play_Njoy, disableClearBtn: false, disBetBtn: 0, disableDealBtn: false});
        this.setState({betAmt: totalBetVal, disable : false, disableClearBtn: false, disBetBtn: 0, disableDealBtn: false});
        if(!this.state.btnActive){
          this.setState({btnActive:true, disableDealBtn: false});
        }
            
      if(Number(totalBetVal)  >=Number(this.props.maxBetAmt) ){
        this.setState({disable:true})
    }
      
        this.state.betAmt = totalBetVal;
        let betAmount = this.changeAmtlabl(this.state.betAmt);
      
          for (var i = 0; i < 13; i++) {
            if (this.state.chipVal != 0 && document.getElementById("fID_" + i) && document.getElementById("fID_" + id)) {
              document.getElementById("fID_" + i).innerHTML = `<p class="goldCoin_fcd"/>`;  
              document.getElementById("fID_" + id).innerHTML = `<p class="goldCoin_fcd"> <span> ${betAmount} </span> </p>`;          
            } else {
              this.state.betCard = null;
              this.setState({
                betAmt: 0,
                message: this.props.language.Play_Njoy,
                disable : true,
                btnActive:false, 
                disableDealBtn: true,
                disableClearBtn: true,
              });
              this.state.betAmt = 0;
              document.getElementById("fID_" + i).innerHTML = `<p class="goldCoin_fcd"/>`;
            }
          }
        }   else{
          // this.setState({ message: "Select Lesser Amount" });
          this.setState({ message: this.props.language.SelectLesserAmount});
        }
    } 
    else {
      this.setState({ message:  this.props.language.LowBalance});
      console.log("amount reduces to your balance");
      //alert('amount reduces to your balance');
    }

    if(this.state.betAmt == 0 && this.state.chipVal == 0){
      this.setState({ message: this.props.language.MinBetAmt+ " " +this.props.minBetLbl });
      setTimeout(()=>{
        this.setState({ message:this.props.language.Play_Njoy  });
      },1500)
    }else{
        //this.setState({ message: "For Amusement Only" });
    }
  }

  doubleAmount() {    
    console.log("Douoble Bet Amount");
    if(this.betSound){
      this.betSound.play()
    }
    if(this.state.betAmt * 2 > this.props.maxBetAmt){
      if((this.state.betAmt * 2) >= this.props.maxBetAmt){
         this.setState({disable:true,});
          }
      this.setState({message: this.props.language.MinBetAmt+" "+this.props.minBetLbl+" "+this.props.language.MaxBetAmt+" "+this.props.maxBetLbl});
    }else{
      if ((this.state.betAmt * 2) <= this.props.balance ) {     
        if((this.state.betAmt * 2) <= this.props.maxBetAmt){
          //if (this.state.takeAmt === 0) {
           
            this.setState({
              betAmt: this.state.betAmt * 2,
            });
            this.state.betAmt = this.state.betAmt * 2;
            let doubeBetAmount = this.changeAmtlabl(this.state.betAmt);
            // var betAmount = this.state.betAmt;
            document.getElementById("fID_" + this.state.betCard).innerHTML = `<p class="goldCoin_fcd"> <span> ${doubeBetAmount} </span> </p>`;
         // }
        }else{
          // this.setState({ message: "Select Lesser Amount" });
          console.log("You have reached maximum bet amount. ")
          this.setState({ message: this.props.language.SelectLesserAmount});
        } 
      } else {
        console.log("amount reduces to your balance");
        //this.setState({ message: "Amount reduces to your balance", disable: true, disableDealBtn: true });
        this.setState({ message: this.props.language.LowBalance , disable: true });
        //alert('amount reduces to your balance');
      }
      console.log("**************************")
    }
  }

  cleareBtn() {
    if(this.clearSound){
      this.clearSound.play();
    }
    //if (this.state.takeAmt === 0) {
      this.setState({
        betAmt: 0,
        disable : true,
        betCard: null,
        btnActive: false,
        disableDealBtn: true,
        disableClearBtn: true,
        message:  this.props.language.Play_Njoy,
      });
      for (var i = 0; i < 13; i++) {
        document.getElementById("fID_" + i).innerHTML = `<p class="goldCoin_fcd"/>`;
      }
      this.ChipsDealerPlayer.cleareCards();//current
 
  }

  dealBtn(val) {

    this.duploseSound = new Audio(duploseSound);
    this.takeSound = new Audio(takeSound);
    this.close= new Audio(close);
    this.winSound = new Audio(winSound);

    
    console.log("Deal Button Value: "+val);    
    this.ChipsDealerPlayer.cleareCards();//current
    
    if (val == 1) { 
    // if (1 == 1) { 
      if(this.state.betAmt != 0 && this.state.betAmt < this.props.minBetAmt){
          //  this.setState({message: this.props.language.Min_Bet+" "+this.props.minBetAmt+" "+this.props.language.Max_Bet+" "+this.props.maxBetAmt});
          this.setState({message: this.props.language.MinBetAmt+" "+this.props.minBetLbl+" "+this.props.language.MaxBetAmt+" "+this.props.maxBetLbl});
    
      }
      else{ 
        if( this.betSound){
          this.betSound.play()
        }
        var check = document.getElementById("playerDealer");
        if (check.checked === false) {
          this.state.delearFirst = true;
        } else {
          this.state.delearFirst = false;
        }
        if (this.state.betCard !== null && this.state.betAmt > 0) {
          this.setState({btnActive: false, disable : true, disableDealBtn: true, disableClearBtn: true});
          this.placebet();       
        }
        if (this.state.betCard == null) {
          this.setState({
            message: this.props.language.BetsEmpty ,
          });
        } else if (this.state.betAmt > 0) {
          document.getElementById("funCardCover").style.display = "block";
        }          
      }
    }
    else if (val == 2) {
      if(this.takeSound){
        this.takeSound.play();
      }
      this.setState({
        betButtonText: this.props.language.Deal,
        betButtonValue:1,
        btnActive: false,
      })

      const body = {
        sessionId: sessionStorage.getItem('sessionId'),
        CN: "TAKE_AMOUNT",
        object: {
          gameId: this.props.gameId,
          playerId: Number(this.props.playerId),
        },
      };
      // console.log(body);
      // this.props.network.sendof(body);
      this.body = {};
      
      this.setState({
        betAmt: 0,
        betCard: null,
        //takeAmt: 0,
        message:  this.props.language.Play_Njoy,
      });
      
      for (var i = 0; i < 13; i++) {
        document.getElementById("fID_" + i).innerHTML = `<p class="goldCoin_fcd"/>`;
      }

     // document.getElementById("funCardCover").style.display = "none";
      this.setState({ btnActive: false });
      this.ChipsDealerPlayer.current.cleareCards();
    }

    console.log("isPlayerBanned "+this.props.isPlayerBanned);
    if(this.props.isPlayerBanned){
      this.gameCloseHandler("target");
      this.props.player_banned();
    }

    console.log("gameUnderMaintanance "+this.props.gameUnderMaintanance);
    if(this.props.gameUnderMaintanance){
        this.gameCloseHandler("target");
        this.props.game_maintanance("Game under maintenance, reach to admin.");
    }

    console.log("deviceBlocked "+this.props.isdeviceBlocked);
    if (this.props.isdeviceBlocked){
         this.gameCloseHandler("target");
        this.props.device_blocked();
    }
  }

  updateMessage(){
    console.log("--------- "+this.props.infoText);
    this.setState({message: this.props.infoText});
     
    console.log("error_code: "+this.props.error_code);
    if(this.props.error_code== "game.bet.invalid"){
      this.setState({
        betButtonText: this.props.language.Prev,
        betButtonValue:0,
        btnActive : false,
        disBetBtn : 0,
        disZoomBtn :0,        
      });
      this.updateWinsArray(0);
      document.getElementById('funCardCover').style.display = 'none';
    }    
  }

  getCardHistory =()=> {
    var cardsObj = sessionStorage.getItem("cardsObj");
    if (cardsObj == null) {
      this.state.cardsArray = [];
    } else {
      this.state.cardsArray = JSON.parse(cardsObj);
      this.setState({cardsArray: JSON.parse(cardsObj)});      
    }
  }

  getBetHistory() {
    var betObj = sessionStorage.getItem("betObj");
    if (betObj == null) {
      this.state.betAmountArray = [];
    } else {
      this.setState({betAmountArray: JSON.parse(betObj)});
      this.state.betAmountArray = JSON.parse(betObj);
    }
  }

  getWinnerHistory() {
    var winnerObj = sessionStorage.getItem("winnerObj");
    if (winnerObj == null) {
      this.state.winnerArray = [];
    } else {
      this.setState({winnerArray: JSON.parse(winnerObj)});
      this.state.winnerArray = JSON.parse(winnerObj);
    }
  }
  /*************************** BET HISTORY END *******************************/
  winnerSelection() {
    this.betPlaced = false;
    let plrTxt = JSON.stringify( this.props.targetResult.playerCards ) 
    let lastCharPlayer = plrTxt.substring(plrTxt.length - 4) 
    var plr = lastCharPlayer.slice(0, -2);

    let dlrTxt = JSON.stringify( this.props.targetResult.dealerCards ) 
    let lastCharDealer = dlrTxt.substring(dlrTxt.length - 4) 
    var dlr = lastCharDealer.slice(0, -2);
    console.log( plr + " " + dlr );
    var charPlr = 0;
    if(plr && plr.charAt(0)){
      if (plr.charAt(0) > 1 && plr.charAt(0) < 10) {
        charPlr = plr.charAt(0);
      } else if (plr.charAt(0) == "A") {
        charPlr = 1;
      } else if (plr.charAt(0) == "T") {
        charPlr = 10;
      } else if (plr.charAt(0) == "J") {
        charPlr = 11;
      } else if (plr.charAt(0) == "Q") {
        charPlr = 12;
      } else if (plr.charAt(0) == "K") {
        charPlr = 13;
      }
    } 
    if (charPlr == Number(this.state.betCard) + 1) { 
      if (this.props.targetResult.winAmount > 0) {   
        this.setState({
          //takeAmt: this.props.targetResult.winAmount,
          message: this.props.language.WonHand,
          // betButtonText: "TAKE",
          btnActive: true,
        });
       // document.getElementById('funCardCover').style.display = 'none';
      }
    } else { 
      setTimeout(()=> {
        if(this.duploseSound){
          this.duploseSound.play();
        }
        this.setState({
          message:  this.props.language.Play_Njoy,
          btnActive: true,
          cardsArray: this.state.cardsArray,
        });
        this.setState({ disable : false,  disableClearBtn: true, disable: true});
        document.getElementById('funCardCover').style.display = 'none';
      },1000)

      if(document.getElementById('funCardCover')){
        document.getElementById('funCardCover').style.display = 'none';
      }
    }
    if(this.ChipsDealerPlayer){
      this.ChipsDealerPlayer.updateHistory(this.historyData);
    }
    this.props.gamstateHandle();    
  }
  placebet() {
    if(!this.betPlaced){
      const body = {
        sessionId: sessionStorage.getItem('sessionId'),
        CN: "FUN_CARD_BET",
        object: {
          betNumber: this.state.betCard,
          playerId: Number(this.props.playerId),
          betAmount: Number(this.state.betAmt.toFixed(2)),
          dealerFirst: this.state.delearFirst,
          gameId: this.props.gameId,
        },
      };
      // console.info(body);
      this.props.network.sendof(body);
      this.body ={};
      let betAmount
      if(this.state.betAmt){
        betAmount = this.changeAmtlabl(this.state.betAmt);
      }
      this.setState({message: this.props.language.BetAccepted + " "+betAmount}) ;

      // this.getPlayerInfo(); Commented_kiran_for_getting_win_pop_up_double_times.

      this.resultShown = false;
    }
  }

  getPlayerInfo(){ 
    const body2 = {
      CN: "GET_PLAYER_INFO",
      object: {
        gameId: Number(this.props.gameId),
        playerId: Number(this.props.playerId),
        },
      sessionId: sessionStorage.getItem('sessionId'),
    }
    this.props.network.sendof(body2);
    this.body2 ={};
  }
  loadBg(){
    var img = new window.Image()
    img.src = fcBg;
    img.addEventListener('load', this.loadImg)
  }
  loadImg = () => { 
    setTimeout(()=>{
      this.setState({
        fcardLoader : false
      })
    },2000)
    }

  updatePlayerInfo(){ 
    if (this.props.targetResult.winAmount > 0) {  
       this.winLbl = this.changeAmtlabl(this.props.targetResult.winAmount);
      setTimeout(()=>{
        document.getElementById('winPop').style.display = 'none';
        this.clearCardsBets();
       // this.setState({ disableDealBtn: false})
        document.getElementById('funCardCover').style.display = 'none';
        this.setState({ disable : true, disableDealBtn: true, disableClearBtn: true});
      }, 2000)       
      document.getElementById('winPop').style.display = 'block';
      if(this.winSound){ 
        this.winSound.play();
      } 
    } else {
      this.clearCardsBets(); 
      this.winLbl = 0;
      this.setState({
        disableDealBtn : false
      });
    } 
  }

  clearCardsBets(){
    this.setState({
      betAmt: 0,
      disable : true,
      betCard: null,
      btnActive: false,
     
      disableClearBtn: true,
      message:  this.props.language.Play_Njoy,
    });
    for (var i = 0; i < 13; i++) {
      document.getElementById("fID_" + i).innerHTML = `<p class="goldCoin_fcd"/>`;
    }
    // this.ChipsDealerPlayer.current.cleareCards();
    this.ChipsDealerPlayer.cleareCards();
  }

  render() { 
    return (
      <React.Fragment> 
        <div className="gameBox" id="gameBox">      
            <div className="FGAfunCard">
              <TopLable
         
                playerId={this.props.playerId} 
                funCardGameState={this.props.funCardGameState} 
                action={this.gameCloseHandler.bind(this)} 
                betAmt={this.state.betAmt} 
                balance={this.props.balance}
                user={this.props.userName} 
                gameEntry={this.props.gameEntry} 
              />
              <ChipsDealerPlayer
                childRef={ref => (this.ChipsDealerPlayer = ref)}
                delearFirst={this.state.delearFirst} 
                cardsArray={this.state.cardsArray} 
                betAmountArray={this.state.betAmountArray} 
                winnerArray={this.state.winnerArray} 
                funCardGameState={this.props.funCardGameState} 
                funCardResult={this.state.targetResult} 
                ref={this.ChipsDealerPlayer} 
                selectedCard={this.selectedCard.bind(this)} 
                getChipVal={this.getChipVal.bind(this)} 
                disableClearBtn = {this.state.disableClearBtn} 
                disableDealBtn = {this.state.disableDealBtn} 
                disable={this.state.disable} 
                btnActive={this.state.btnActive} 
                betBtnTxt={this.state.betButtonText} 
                betButtonValue = {this.state.betButtonValue}
                betCard={this.state.betCard} 
                doubleAmount={this.doubleAmount.bind(this)} 
                message={this.state.message}  
                dealBtn={this.dealBtn.bind(this)} 
                cleareBtn={this.cleareBtn.bind(this)}
                fc_chipValues={this.props.fc_chipValues}
              />
            </div>
          </div>
          <div className="winPopAndarBahar" id="winPop"> 
            <div className="popBorder">
              {this.props.language.Win_Amt} &nbsp; <br></br>
              <span> {this.winLbl} </span> 
            </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStatesToProps=(state)=>{
  return{
    language:state.languageObjs.languageObj
  }
}
export default connect(mapStatesToProps)(FunCard)
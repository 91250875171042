import React from "react";
import "../../../../../css/games/funTarget/betAmount.css"; 
import title from "../../../../../../assets/titles/indie/mini_andar_bahar.svg";  
import { useSelector } from "react-redux";
export default function TopLable(props) {  
  const language = useSelector((state) => state.languageObjs.languageObj)
  const roundNumber = props.balance - props.betAmt;
  const betAmount = (roundNumber)

 
  let updatebal = topLable_fn(betAmount);
  let updateBet = topLable_fn(props.betAmt)

  // function topLable_fn(betAmount){
  //   if(betAmount >= 1000 && betAmount <=999999){
  //       betAmount = betAmount / 1000+"K";      
  //   }else if(betAmount >= 1000000 && betAmount <=999999999){
  //     betAmount = betAmount / 1000+"M";    
  //   }else if(betAmount >999999999){
  //     betAmount = betAmount / 1000+"B";      
  //   }

  //   return betAmount;
  // }

  // function topLable_fn(labelValue) 
  // {
  //     // Nine Zeroes for Billions
  //     return Math.abs(Number(labelValue)) >= 1.0e+9
  
  //     ? Math.abs(Number(labelValue)) / 1.0e+9 + "B"
  //     // Six Zeroes for Millions 
  //     : Math.abs(Number(labelValue)) >= 1.0e+6
  
  //     ? Math.abs(Number(labelValue)) / 1.0e+6 + "M"
  //     // Three Zeroes for Thousands
  //     : Math.abs(Number(labelValue)) >= 1.0e+3
  
  //     ? Math.abs(Number(labelValue)) / 1.0e+3 + "K"
  
  //     : Math.abs(Number(labelValue));
  // }

function topLable_fn(Value) {
  let labelValue = Math.abs(Number(Value))
  // Nine Zeroes for Billions
  return labelValue >= 1.0e+9

  ? Number((labelValue / 1.0e+9).toFixed(2)) + "B"
  // Six Zeroes for Millions 
  : labelValue >= 1.0e+6

  ? Number((labelValue / 1.0e+6).toFixed(2)) + "M"
  // Three Zeroes for Thousands
  : labelValue >= 1.0e+3

  ? Number((labelValue / 1.0e+3).toFixed(2)) + "K"

  : Number(labelValue.toFixed(2));
}

  
  return (
    <div className="fd">
      <div className="f_card_balance">
            <div className="fl_1">
              <div className="funBal fd"> 
                <div className=""> {updatebal} </div>

                <div className=""> {language.Balance} </div>
              </div>
            </div>
            <div className="fl_1">
              <div className="funBal fd">
                <div className=""> {updateBet} </div>
                <div className="">{language.Total_Bet} </div>
              </div>
            </div>           
          </div>

      
      <div className="F_card_title">
        <img className="titleImg" src={title} alt={title}></img>
      </div> 

      <div className="betamt"> 
          <div className="float-left font_28">
            <p>
            <span> {language.Player_Id}: </span> {props.playerId} <br/> 
            <span> {language.Hand_Id}: </span> {props.funCardGameState.handId} <br/>
            <span> {language.User_Name} : </span> {props.user} <br/> 
            </p>
          </div> 
      </div>   
    </div>
  );
}

import React from "react";
import "../../../../css/games/funTargetTimer/funTargetTimer.css";
import "./UI/funtarget.css";
import Wheel from "./UI/wheel";
import Close from "./UI/close";
import Title from "./UI/title";
import BetAmount from "./UI/betAmount";
import BalanceLabel from "./UI/balanceLabel";
import FunTargetTimerInSeconds from "./UI/funTargetTimerSec";
import LeftButtonPanel from "./UI/leftButtonPanel";
import RightButtonPanel from "./UI/rightButtonPanel";
import BetPanel from "./UI/betPanel";
import InfoBoard from "./UI/infoBoard";
import ftTimerBg from "../../../../../assets/games/funTarget/fun-target-bg_1.png";
import takeSound from "../../../../../assets/sounds/take.mp3";
import betSound from "../../../../../assets/sounds/bet.mp3";
import clearSound from "../../../../../assets/sounds/clear.mp3";
import duploseSound from "../../../../../assets/sounds/duplose.mp3";
import winSound from "../../../../../assets/sounds/win.mp3";
import betoncardSound from "../../../../../assets/sounds/betoncard.mp3";
import exitSound from "../../../../../assets/sounds/exit.mp3";
import tickSound from "../../../../../assets/sounds/tick.mp3";
import wheelSound from "../../../../../assets/sounds/wheelStart.mp3";

import coin from "../../../../../assets/gold_coin.svg";

import { connect } from "react-redux";

class FunTargetTimer extends React.Component {
    constructor(props) {
        super(props);
        // alert(this.props.language)
        this.wheelDimensionHeight = window.innerHeight * 0.7;
        this.wheelDimensionWidth = window.innerWidth * 0.5;
        this.ftWheelRef = React.createRef();
        this.ftBetAmounts = React.createRef();
        this.betPanelRef =React.createRef();
        this.FunTargetTimerInSeconds = React.createRef();
        this.InfoBoard = React.createRef();
        this.state = {
            previousBetArray: [],
            finalPreviousBetArray: [],
            gameId: this.props.gameId,
            playerId: this.props.playerId,
            Total_Bet_Amount: 0,
            winBet: 0,
            winCover: false,
            showBetButton: false,
            betButtonText: this.props.language.Prev ,
            betButtonValue:0,
            infoText: this.props.language.Play_Njoy,
            betstr: "",
            bets: "",
            betsDone: false,
            resultNo: 10,
            targetResult: props.targetResult,
            disabulity: false,
            actBtn: false,
            trgtTmLoader: true,
            showWin: -1,
            wins: [
                { id: 0, amount: 0 },
                { id: 1, amount: 0 },
                { id: 2, amount: 0 },
                { id: 3, amount: 0 },
                { id: 4, amount: 0 },
                { id: 5, amount: 0 },
                { id: 6, amount: 0 },
                { id: 7, amount: 0 },
                { id: 8, amount: 0 },
                { id: 9, amount: 0 },                
            ],
            disDoubleBtn: 0,
            disBetBtn: 0,
            disClearBtn: 0,
            glowTakeBtn: 0,
        };
        this.betArray = [];        
        this.previousPosition = undefined;
        this.betAmount = 1;
        this.isReady = false;
        this.handId = 0;
        this.isloadImg = false;
        this.minInnerLimit=0 ;
        this.maxInnerLimit=0 ;
        this.minInnerLimitLbl=0 ;
        this.maxInnerLimitLbl=0 ;

        this.clearSound = new Audio(clearSound);
        this.exitSound = new Audio(exitSound);
        this.betSound = new Audio(betSound);
        this.takeSound = new Audio(takeSound);
        this.winSound = new Audio(winSound);
        this.duploseSound = new Audio(duploseSound);
        this.betoncardSound = new Audio(betoncardSound);
        this.tickSound = new Audio(tickSound);
        this.wheelSound = new Audio(wheelSound);
        this.loadBg();
    }
    componentDidMount() {

        const { childRef } = this.props;
        childRef(this);
        console.log(this.props.language);

        if(this.props.isIphone){
            document.getElementById('iospopup').style.display = 'block';  
        }else{
            document.getElementById('iospopup').style.display = 'none';  
        }
        
        document.getElementById('funTargetWinImg').style.display = 'none';

        if(this.props.ftt_chipValues){
            let newChips = this.props.ftt_chipValues.split(",");
            console.log(newChips[0]);  
            let initChipVal = newChips[0];      
            this.betAmount = initChipVal;
        }
        
        if(this.props.ftt_innerLimits){
            this.minInnerLimit = this.props.ftt_innerLimits.split("-")[0];      
            this.maxInnerLimit = this.props.ftt_innerLimits.split("-")[1];    
            
            this.minInnerLimitLbl = this.changeAmtlabl(this.minInnerLimit);
            this.maxInnerLimitLbl = this.changeAmtlabl(this.maxInnerLimit);

            console.log("Min Inner Limit: "+this.minInnerLimit+"     "+this.minInnerLimitLbl);
            console.log("Max Inner Limit: "+this.maxInnerLimit);
        }

        if (this.props.gameState.handId == this.props.gameState.oldHandId) {
            let newStr = this.props.gameState.betRequest.betString;
            let newStr1 = newStr.split('|');

            let prevBetString = [];
            for (var i = 0; i < newStr1.length; i++) {
                this.state.wins[i].amount = newStr1[i];
                //  this.betPanelRef.current.updateBetAmt(i, this.state.wins[i].amount);
                this.betPanelRef.current.updateBetAmt(i, this.state.wins[i].amount);
            }

            this.setState({
                btnActive: false,
                // betButtonText:this.props.language.Take , 
                // betButtonValue:2,
                betButtonText:this.props.language.BetOK , 
                actBtn:false,
                betButtonValue:1,
                disBetBtn: 1, 
                disClearBtn: 1,
                disDoubleBtn: 1,
                Total_Bet_Amount: this.props.gameState.betRequest.betAmount,
                winCover: true,
                // infoText: "Bet Accepted !! your bet amount is " +this.props.gameState.betRequest.betAmount,
                infoText:this.props.language.BetAccepted  + this.props.gameState.betRequest.betAmount,
                // infoText: this.props.FunTargetTimerInfoText,
                x_img: 1
            });
        } else {
            if (document.getElementById('bet_take')) {
                document.getElementById('bet_take').classList.remove('active');
            }
            if (this.props.gameState.betRequest) {
                this.setState({ disBetBtn: 0, disDoubleBtn: 0, disClearBtn: 0 });
            } else {
                this.setState({ disBetBtn: 1,disDoubleBtn: 1, disClearBtn: 1, });
            }
            this.setState({
                betButtonText: this.props.language.Prev,
                betButtonValue:0,
                 
                   winCover: false 
            });
        }
        if (this.props.gameState.history && this.props.gameState.history.length > 0) {
            this.bonusValue = this.props.gameState.history[this.props.gameState.history.length - 1].extraInfo;
            // console.log("history.length  "+this.props.gameState.history[this.props.gameState.history.length-1].extraInfo);
            // this.ftWheelRef.current.sendJock2(this.bonusValue);
            this.ftWheelRef.current.setJackpotNumber(this.bonusValue);
        }
        if (this.props.gameState.handId) {
            this.handId = this.props.gameState.handId;
        }

        
        document.addEventListener("keyup", this.ftt_handleKeyboardEvent);
        document.addEventListener("keydown", this.ftt_bets_handleKeyboardEvent);  
        
    }

    componentWillUnmount() {
       
        document.removeEventListener("keyup", this.ftt_handleKeyboardEvent);
        document.removeEventListener("keydown", this.ftt_bets_handleKeyboardEvent);    
    }

    ftt_bets_handleKeyboardEvent= (evt) => {
        // if(this.state.betButtonText != "TAKE" && this.state.disBetBtn == 0){
         if(this.state.betButtonValue != 2 && (this.props.gameState.betRequest == undefined || this.state.disBetBtn == 0)){
 
         
           if(evt.keyCode >= 48 && evt.keyCode <= 57){
             console.log(evt.key);            
             this.betPanelHandler(evt.key);
           }
           if(evt.keyCode >= 96 && evt.keyCode <= 105 ){
             console.log(evt.key);      
             this.betPanelHandler(evt.key);      
           }
         }
     }


     ftt_handleKeyboardEvent = (evt) => {
        console.log(evt.code);
        switch(evt.code){
            case "Enter":
                // alert("enter")
            case "NumpadEnter":
                // alert("numberPad")
              //  if (this.state.betButtonText == "PREV" && this.state.disBetBtn == 0) {
                if (this.state.betButtonValue == 0 && this.state.disBetBtn == 0) {

                    this.rightButtonPanelHandler("PREV");
               // }else if (this.state.betButtonText == "BET OK" && this.state.disBetBtn == 0) {
                }else if (this.state.betButtonValue == 1 && this.state.disBetBtn == 0) {

                    this.rightButtonPanelHandler("BET OK");
                }
            break;
            case "Space":
               // if(this.state.betButtonText == "TAKE" && this.state.disBetBtn == 0){
                if(this.state.betButtonValue == 2 && this.state.disBetBtn == 0){

                    this.rightButtonPanelHandler("TAKE");
                }
            break;
            case "Escape":
                //this.rightButtonPanelHandler("exit");
            break;
            case "KeyC":      
               // if(this.state.betButtonText == "BET OK" && this.state.disClearBtn == 0){
                if(this.state.betButtonValue == 1 && this.state.disClearBtn == 0){

                    this.rightButtonPanelHandler("clear");
                }
            break;      
        }
    }

    updateGameState() {
        // this.InfoBoard.current.historyDistract(); 
    }
    gameCloseHandler(game) {
        this.exitSound.play();
        this.ftWheelRef.current.stopWheelSound();
        this.clearSound.pause();
        //this.exitSound.pause();
        this.betSound.pause();
        this.takeSound.pause();
        this.winSound.pause();
        this.duploseSound.pause();
        this.betoncardSound.pause();
        // this.clearSound = {};
        // this.exitSound = {};
        // this.betSound = {};
        // this.takeSound = {};
        // this.winSound = {};
        // this.duploseSound = {};
        // this.betoncardSound = {};
        this.props.action(game);
    }
    wheelHandler(position) {
        this.declareWin();
        if (this.props.gameState.handId) {
            this.handId = this.props.gameState.handId;
        }

        if (this.props.targetResult.winAmount > 0 && position) {
            document.getElementById('funTargetWinImg').style.display = 'block';
            setTimeout(() => {
                document.getElementById('funTargetWinImg').style.display = 'none';
            }, 3000)

        }
    }
    declareWin(value) {

        if (this.props.targetResult.winAmount > 0) {
            document.getElementById('funTargetWinImg').style.display = 'block';
            
            setTimeout(() => {
                document.getElementById('funTargetWinImg').style.display = 'none';
                this.updateWinsArray(0)
                this.setState({ betButtonText: this.props.language.Prev, betButtonValue:0, disBetBtn: 0, });
            }, 2000)
            
            if(document.getElementById('iospopup').style.display == "none"){
                this.winSound.play();
            }
            this.setState({
                // infoText: "You won the hand!!!", 
                infoText: this.props.language.WonHand, 
                showWin: this.props.targetResult.resultNum,
                winningAmount: this.props.targetResult.winAmount
            });
            this.state.resultNo = this.props.targetResult.resultNum;
            this.rightButtonPanelHandler("TAKE")
            this.state.winningAmount = this.props.targetResult.winAmount;
        } else {
            setTimeout(() => {
                this.updateWinsArray(0);
              }, 500)
            if(document.getElementById('iospopup').style.display == "none"){
                this.duploseSound.play();
            }            
            setTimeout(() => {
                this.setState({
                    showBetButton: false,
                    betButtonText: this.props.language.Prev,
                    betButtonValue:0,
                    infoText: this.props.language.Play_Njoy,
                    winBet: 0,
                    actBtn: false,
                    disBetBtn: 0,
                    winCover: false,
                })
            }, 1000);            
            this.rightButtonPanelHandler("clear"); 
        }
        this.getPlayerInfo();
        this.isReady = false;
        this.props.gamstateHandle(this.props.gameId);
    }
    getTimer() {
        const body = {
            sessionId: sessionStorage.getItem('sessionId'),
            CN: "GET_GAME_TIMER",
            object: {
                gameId: this.props.gameId,
                playerId: Number(this.props.playerId),
            },
        };
        this.props.network.sendof(body);
        this.body = {};
    }

    betHandler(amount) {
        console.log("Selected Chip Value: "+amount);
        this.playTakeSound();
        this.betAmount = amount;
    }
    playTakeSound() {
        this.takeSound.play();
    }

    betPanelHandler(betOn) {
        this.setState({infoText: this.props.language.MinBetAmt+" "+this.props.minBetLbl+" "+this.props.language.MaxBetAmt+" "+this.props.maxBetLbl});
        console.log(betOn);
        console.log(this.betAmount);
        this.betoncardSound = new Audio(betoncardSound);
        this.betoncardSound.play();
        
        console.log( this.state.wins);
        let totabBetAmt = this.state.wins.reduce((prev, cur) => Number(prev) + Number(cur.amount), this.betAmount);            

        console.log("this.betAmount "+this.betAmount);
        if (Number(this.betAmount) != 0) {
            console.log("Total bet Amt: "+totabBetAmt);
            console.log("Balance: "+this.props.balance);
           if(totabBetAmt > this.props.maxBetAmt){
            this.setState({infoText: this.props.language.MinBetAmt+" "+this.props.minBetLbl+" "+this.props.language.MaxBetAmt+" "+this.props.maxBetLbl});
                // this.setState({infoText: this.props.language.Min_Bet+" " +this.props.minBetAmt+" " +this.props.language.Max_Bet+" " +this.props.maxBetAmt});
            }else if (totabBetAmt <= this.props.balance) {   
                console.log("FTT Max Inner Lmt: "+this.maxInnerLimit);
               
                this.setState({ betButtonText: this.props.language.BetOK, betButtonValue:1 , disDoubleBtn:0, disClearBtn: 0, disBetBtn: 0, actBtn: true });           
                
                if(this.state.wins[betOn].amount+Number(this.betAmount) <= this.maxInnerLimit){
                    this.state.Total_Bet_Amount = totabBetAmt;
                    this.setState({Total_Bet_Amount:totabBetAmt })
                    this.state.wins[betOn].amount += Number(this.betAmount);
                    if(this.betPanelRef && this.betPanelRef.current){
                        console.log("Update Bet Numbers..............")
                        this.betPanelRef.current.updateBetAmt(Number(betOn), this.state.wins[betOn].amount);
                    }
this.setState({ wins: this.state.wins, });
                    this.previousPosition = betOn;
                    console.log(`${this.betAmount} rupees place on ${betOn}`);
                    this.isReady = true;
                }else{
                    this.setState({infoText: this.props.language.MaxInrBetAmt+""+this.maxInnerLimitLbl});
                }
                // this.setState({ infoText: this.props.language.Play_Njoy });
               // this.setState({infoText: this.props.language.Play_Njoy});
            } else {
                this.setState({ infoText: this.props.language.LowBalance });
                console.log("amount reduces to your balance");
                //alert('amount reduces to your balance');
            }
        } else {
            if(this.props.gameState.betRequest){
                this.setState({ betButtonText: this.props.language.Prev, betButtonValue:0,actBtn:false})
            }else{
                this.setState({ disBetBtn:1})
            }

            this.state.wins[betOn].amount = 0;
            

            if(this.betAmount == 0 && this.state.Total_Bet_Amount == 0){
                this.setState({ infoText: this.props.language.MinBetAmt + " "+this.props.minBetLbl});
                setTimeout(()=>{
                    this.setState({ infoText: this.props.language.Play_Njoy });                  
                },1500)
            }else{
                this.setState({
                    // infoText: this.props.language.Play_Njoy, 
                    betButtonText: this.props.language.BetOK, betButtonValue:1,actBtn:true});
                // this.setState({ infoText: this.props.language.Play_Njoy });
            }

            let totabBetAmt = this.state.wins.reduce((prev, cur) => Number(prev) + Number(cur.amount), this.betAmount);
            console.log(betOn);
            console.log(this.state.wins[betOn].amount);
            this.betPanelRef.current.updateBetAmt(betOn, this.state.wins[betOn].amount);
            this.state.Total_Bet_Amount = totabBetAmt;
            if(this.state.Total_Bet_Amount == 0){
                this.setState({ disClearBtn: 1, disDoubleBtn: 1,betButtonText: this.props.language.Prev, betButtonValue:0,actBtn:false});
            }            
        }
    }

    rightButtonPanelHandler(action) {
        // alert("Button Action " + action);
        //console.log("Button Action " + action);
        switch (action) {
            case "BET OK":                
                if(this.state.Total_Bet_Amount != 0 && this.state.Total_Bet_Amount < this.props.minBetAmt){                    
                    this.setState({infoText: this.props.language.MinBetAmt+" "+this.props.minBetLbl+" "+this.props.language.MaxBetAmt+" "+this.props.maxBetLbl});          
                }else{                     
                    

                    if (this.state.Total_Bet_Amount != 0 && this.isReady) {
                       
                        this.state.betstr = "";
                        this.state.bets = "";
                        let betValid=[];

                        console.log(this.props.ftt_innerLimits)
                        let innerMin_Limit = this.props.ftt_innerLimits.split("-")[0];
                        console.log("---------   "+innerMin_Limit);

                        if (this.props.balance >= this.state.Total_Bet_Amount) {
                            for (let i = 0; i < this.state.wins.length; i++) {
                                this.state.bets += Number(this.state.wins[i].amount.toFixed(2)) + "|";

                                if(this.state.wins[i].amount > 0){
                                    if(this.state.wins[i].amount >= innerMin_Limit){
                                        betValid[i] = true;
                                    }else{
                                      betValid[i] = false;
                                    }
                                }else{
                                    betValid[i] = true;
                                }
                                
                            }
                            this.state.betstr = this.state.bets.slice(0, -1);
                            console.log(this.state.betstr);
                            console.info("betValid: "+betValid);
                            let sendBet = true;;
                            for(let u=0; u<betValid.length;u++){
                                if(betValid[u] ==  false){
                                    sendBet = false;
                                    // console.log("Dont send bet reques");
                                    break;  
                                }
                            }
                            if(sendBet){
                                this.setState({
                                    disDoubleBtn: 1,
                                    disBetBtn: 1,
                                    disClearBtn: 1,
                                    actBtn: false,                    
                                });
                                this.isReady = false;
                                this.placebet();
                            }else{
                                console.log("Dont Send Bet Request.")
                                const minInrLmt = this.changeAmtlabl(innerMin_Limit)
                                this.setState({ infoText: this.props.language.InerLmt + minInrLmt});                
                            }
                            return this.state.betstr;
                        } else {
                            this.setState({ infoText: this.props.language.LowBalance  });
                        }
                    }
                }
                   
                break;
            case "TAKE":
                console.log("take amount action");
                if(document.getElementById('iospopup').style.display == "none"){
                    this.takeSound.play();
                }
                console.log("take amount action");
                this.takeAmountResult();
                break;
            case "PREV":
                console.log("Previous Bet action");
                this.betSound.play();
                this.PreviousBetMethod();
            break;
            case "double":
                this.betSound.play();
                this.updateWinsArray(2);
                break;
            case "clear":
                if(document.getElementById('iospopup').style.display == "none"){
                    this.clearSound.play();
                }
                this.updateWinsArray(0);
                console.log("322")
                this.setState({
                    betButtonText: this.props.language.Prev,
                    betButtonValue:0,
                    disDoubleBtn: 1,
                    disClearBtn: 1,
                    infoText: this.props.language.Play_Njoy,
                    btnActive: false,
                    actBtn:false,
                    Total_Bet_Amount: 0
                });
                if (this.props.gameState.betRequest) {
                    this.setState({ disBetBtn: 0 });
                } else {
                    this.setState({ disBetBtn: 1 });
                }

                break;
             case "exit":
                 this.gameCloseHandler("target");
                 break;
            case "disableBtns":
                this.setState({ disDoubleBtn: 1, disClearBtn: 1,  disBetBtn: 1, actBtn:false, });                
                this.rightButtonPanelHandler("BET OK");
                break;
            case "enableBtns":
                if (this.isReady) {
                    this.setState({ disDoubleBtn: 0, disBetBtn: 0, disClearBtn: 0 });
                }
                break;
            case "PlayTickSound":
                if(document.getElementById('iospopup').style.display == "none"){
                    this.tickSound.play();
                }
            break;
            case "StopTickSound":
                if(document.getElementById('iospopup').style.display == "none"){
                    this.tickSound.pause();
                }
            break;
            default:
                break;
        }
    }

    PreviousBetMethod() {
        console.log("Show Previous Bet");
        console.log(this.state.Total_Bet_Amount);
        if (this.props.gameState.betRequest.betAmount <= this.props.balance) {
            if(this.props.gameState.betRequest.betAmount > this.props.maxBetAmt){
                this.setState({ infoText:this.props.language.PreBet_MaxLmt });
            }else{ 
                this.updateWinsArray(0);
                var preBet = this.props.gameState["betRequest"]["betString"];
                this.state.previousBetArray.push({
                    amount: preBet.split("|"),
                });
                this.setState({
                    previousBetArray: this.state.previousBetArray,
                    disClearBtn: 0,
                    disDoubleBtn: 0,
                });
                if (this.state.previousBetArray) {
                    this.isReady = true;
                    this.toCreatePreviousBetArray();
                }
                console.log(this.state.previousBetArray[0].amount[0]);
                if (this.props.gameState.handId) {
                    this.handId = this.props.gameState.handId;
                }
            }
        } else {
            this.setState({ infoText: this.props.language.Bet_Amt_MaxBal});
        }
    }

    toCreatePreviousBetArray() {
        for (let i = 0; i < this.state.previousBetArray[0].amount.length; i++) {
            this.state.finalPreviousBetArray.push({
                id: i,
                amount: Number(this.state.previousBetArray[0].amount[i]),
            });
        }
        this.setState({ finalPreviousBetArray: this.state.finalPreviousBetArray });
        console.log(this.state.finalPreviousBetArray);
        if (this.state.finalPreviousBetArray) {
            this.toGenerteBetString();
        }
    }

    toGenerteBetString() {
        console.log("GenerteBetString")
        for (let i = 0; i < this.state.wins.length; i++) {
            if (this.state.wins[i].id == this.state.finalPreviousBetArray[i].id) {        
                this.state.wins[i].amount += this.state.finalPreviousBetArray[i].amount;
                this.betPanelRef.current.updateBetAmt(i, this.state.wins[i].amount);
            }
        }
        this.setState({
            wins: this.state.wins,
            betButtonText: this.props.language.BetOK,
            actBtn:true,
            betButtonValue:1,
            previousBetArray: [],
            finalPreviousBetArray: [],
            Total_Bet_Amount: this.state.wins.reduce((prev, cur) => prev + cur.amount, 0)
        });
        console.log(this.state.wins);
    }

    takeAmountResult() {
        console.log("Take Amount Function");
        document.getElementById('funTargetWinImg').style.display = 'none';
        console.log("isPlayerBanned " + this.props.isPlayerBanned);
        this.setState({            
            winningAmount: 0,
            actBtn: false,
            betsDone: false,
            infoText: this.props.language.Play_Njoy,
            showBetButton: false,
            winBet: 0,
            winCover: false,
            showWin: -1,
        })
        const body = {
            sessionId: sessionStorage.getItem('sessionId'),
            CN: "TAKE_AMOUNT",
            object: {
                gameId: this.props.gameId,
                playerId: Number(this.props.playerId),
            },
        };
        console.log(body)
        // this.props.network.sendof(body);
        this.body = {};

        if (this.props.isPlayerBanned) {
            this.gameCloseHandler("target");
            this.props.player_banned();
        }

        console.log("gameUnderMaintanance " + this.props.gameUnderMaintanance);
        if (this.props.gameUnderMaintanance) {
            this.gameCloseHandler("target");
            this.props.game_maintanance("Game under maintenance, reach to admin.");
        }

        console.log("deviceBlocked " + this.props.isdeviceBlocked);

        if (this.props.isdeviceBlocked) {
            console.log("---------------------------")
            this.gameCloseHandler("target");
            console.log("---------------------------");
            this.props.device_blocked();
        }
    }

    updateWinsArray(num) {
        console.log(this.state.wins);
        let totBet = 0;
        if (num == 2) {         

            if(this.state.Total_Bet_Amount*2 > this.props.maxBetAmt){
                this.setState({infoText: this.props.language.MinBetAmt+" "+this.props.minBetLbl+" "+this.props.language.MaxBetAmt+" "+this.props.maxBetLbl});
            }else{
                if(this.state.Total_Bet_Amount*2 <= this.props.balance){                
                    for (let k = 0; k < this.state.wins.length; k++) {
                        if (this.state.wins[k].amount > 0 && this.state.wins[k].amount <= this.maxInnerLimit && totBet <= this.props.balance) {
                            console.log(this.state.wins[k].amount);
                            if (this.state.wins[k].amount * num <= this.maxInnerLimit) {    
                                if (this.state.Total_Bet_Amount + this.state.wins[k].amount <= this.props.balance) {
                                    this.state.Total_Bet_Amount = this.state.Total_Bet_Amount + this.state.wins[k].amount;
                                    this.state.wins[k].amount = this.state.wins[k].amount * num;
                                    this.betPanelRef.current.updateBetAmt(k, this.state.wins[k].amount);                                   
                                }else{
                                    this.setState({infoText: this.props.language.MaxInrBetAmt+this.maxInnerLimitLbl});
                                }
                            }else{
                                this.setState({infoText: this.props.language.MaxInrBetAmt+this.maxInnerLimitLbl});
                            }
                            totBet += Number(this.state.wins[k].amount);
                            this.setState({
                                Total_Bet_Amount: totBet,
                            });
                        }
                    }
    
                    if (totBet <= this.props.balance) {
                        this.setState({
                            //  Total_Bet_Amount: totBet,
                        });
    
                        this.setState({
                            wins: this.state.wins,
                            disDoubleBtn: 0,
                            disClearBtn: 0,
                            disZoomBtn: 0,
                            // infoText: this.props.language.Play_Njoy,
                        });
    
                    } else {
                        this.isReady = false;
                        this.setState({
                            wins: this.state.wins,
                            disDoubleBtn: 1,
                            disClearBtn: 0,
                            disZoomBtn: 0,
                            infoText: this.props.language.LowBalance,
                        });
                    }
                }else{
                    //this.isReady = false;
                    this.setState({      
                      disClearBtn: 0,
                      disZoomBtn: 0,
                      infoText: this.props.language.LowBalance ,
                    });        
                }

            }
           
        } else {
            for (let a = 0; a < this.state.wins.length; a++) {
                this.state.wins[a].amount = Number(0);
                this.betPanelRef.current.updateBetAmt(a, this.state.wins[a].amount);
                if (this.state.wins[a].amount > 0) {
                    this.state.wins[a].amount = 0;
                    totBet += Number(this.state.wins[a].amount);
                }
            }

            this.setState({
                wins: this.state.wins,
                disDoubleBtn: 1,
                disClearBtn: 1,
                disZoomBtn: 0,
                infoText: this.props.language.Play_Njoy,
                //Total_Bet_Amount: totBet,
            });
        }
    }

    updateMessage() {
       
        console.log("--------- " + this.props.infoText);
        this.setState({ infoText: this.props.infoText });

        // console.log("error_code: " + this.props.error_code);
        if (this.props.error_code == "game.bet.invalid") {
            console.log("515")
            this.setState({
                betButtonText: this.props.language.Prev,
                betButtonValue:0,

                // betButtonValue:1,
                btnActive: false,
                disBetBtn: 0,
                disZoomBtn: 0,
                winCover: false,
            });
            this.updateWinsArray(0);
        }
    }

    placebet() {
        // this.setState({disabulity:false});
        const body = {
            sessionId: sessionStorage.getItem('sessionId'),
            CN: "FUN_TARGET_BET",
            object: {
                betString: this.state.betstr,
                playerId: Number(this.props.playerId),
                gameId: this.props.gameId,
            },
        };
        this.props.network.sendof(body);
        this.body = {};

        console.log("Send Bet Request");
        console.log(body);

     
        this.setState({ betsDone: true, winCover: true });
    }

    // changeAmtlabl(chngAmt){
    //     let newAmt = chngAmt;
    //     if(newAmt >= 1000 && newAmt <=999999){
    //       newAmt = newAmt / 1000+"K";      
    //     }else if(newAmt >= 1000000 && newAmt <=999999999){
    //       newAmt = newAmt / 1000+"M";    
    //     }else if(newAmt >999999999){
    //       newAmt = newAmt / 1000+"B";      
    //     }
    //     return newAmt;
    //   }
      changeAmtlabl(chngAmt) {
        let newAmt = Number(Number(chngAmt).toFixed(2));
        if (newAmt >= 1000 && newAmt <= 999999) {
            newAmt = Number((newAmt / 1000).toFixed(2)) + "K";
        } else if (newAmt >= 1000000 && newAmt <= 999999999) {
            newAmt = Number((newAmt / 1000).toFixed(2)) + "M";
        } else if (newAmt > 999999999) {
            newAmt = Number((newAmt / 1000).toFixed(2)) + "B";
        }
        return newAmt;
    }


    getPlayerInfo(){
        const body2 = {
            CN: "GET_PLAYER_INFO",
            object: {
                gameId: Number(this.state.gameId),
                playerId: Number(this.props.playerId),
            },
            sessionId: sessionStorage.getItem('sessionId'),
        }
        this.props.network.sendof(body2);
        this.body2 ={};
    }
    funTargetTimerGetwheelresp() {
        if (this.props.targetResult && this.ftWheelRef.current) {
            this.ftWheelRef.current.spinTheWheel(this.props.targetResult.resultNum);
            if(document.getElementById('iospopup').style.display == "none"){
                this.wheelSound.play();
            }
            this.setState({x_img: 1});
           this.ftWheelRef.current.sendimg(this.state.x_img);
        }
    }

    funTargetTimerGetTime() {
        console.log(this.props.timesend);
        if (this.props.timesend) {
            console.log(this.props.timesend);
            this.FunTargetTimerInSeconds.sendTimer(this.props.timesend);
        }
    }
    // disabulity={this.state.disabulity}
    jackpotHandler() {
        setTimeout(() => {
            if (this.ftWheelRef.current) {
                this.ftWheelRef.current.sendJock();
            }
        }, 1000);

    }
    loadBg() {
        this.LoadImg = new window.Image()
        this.LoadImg.src = ftTimerBg;
        console.log(this.LoadImg)
        // this.LoadImg.addEventListener('load', this.trgtTmLoded)
    }

    trgtTmLoded = () => {
        // if (!this.isloadImg) {
        //     this.isloadImg = true;
        // } else {
        //     this.setState({
        //         trgtTmLoader: false,
        //     })
        // }
    }

    updatePlayerInfo () {
        this.setState({ Total_Bet_Amount: 0,});
        console.log("FTT: PlayerInfo: "+this.props.targetResult.winAmount)
        if (this.props.targetResult.winAmount > 0 && !this.winOnload) {
            setTimeout(() => {                
                this.setState({  betButtonText: this.props.language.Prev, betButtonValue:0, disBetBtn: 0, });                
                document.getElementById('funTargetWinImg').style.display = 'none';
            }, 3000)
        }        
        if (this.winOnload == true) {
            this.winOnload = false;
        }
    }

    closeWin() {
        document.getElementById('iospopup').style.display="none";
        this.takeSound = new Audio(takeSound);
        this.winSound = new Audio(winSound);
        this.duploseSound = new Audio(duploseSound);
        this.tickSound = new Audio(tickSound);
        this.wheelSound = new Audio(wheelSound);
        this.clearSound = new Audio(clearSound);
      }

    render() {
        return (
            <React.Fragment>
                <div className="gameBox" id="gameBox">
                                        
                    <div className="gamePage targetTimerInd">
                        <div className="iosFetchSounds" id="iospopup" onClick={(e) => this.closeWin()}>
                            <div className="popupTooLong">
                                <div className=""> Welcome to TARGET TIMER GAME </div>
                                <br></br>
                                Please click on OK button to continue.
                                <br></br>
                                <br></br>
                                <div className="fd">                    
                                    <button className="btn_iosPopup" > OK </button>
                                    <br></br>
                                </div>
                            </div>
                        </div>
                        <div className="fd p_30">
                            <div className="fd">
                            <div className="row">
                                <div className="target_chips">
                                    <div className="fd p_5 clr_ff font_30">
                                      {this.props.language.Player_Id} {this.props.playerId} <br />
                                      {this.props.language.Hand_Id} {this.props.gameState.handId}
                                    </div>
                                    <BetAmount 
                                        bet={this.betHandler.bind(this)} 
                                        ref={this.ftBetAmounts}
                                        ftt_chipValues={this.props.ftt_chipValues}
                                    >                                        
                                    </BetAmount>
                                </div>
                                <div className="target_table">
                                    <div className="fd">
                                        <Title></Title>
                                        <Wheel
                                            gameState={this.props.gameState}
                                            targetResult={this.props.targetResult}
                                            ref={this.ftWheelRef}
                                            height={this.wheelDimensionHeight}
                                            width={this.wheelDimensionWidth}
                                            trgtTmLoded={this.trgtTmLoded.bind(this)}
                                            action={this.wheelHandler.bind(this)}
                                            jackpotHandler={this.jackpotHandler.bind(this)}
                                            isMobile={this.props.isMobile}
                                            onFocus={this.props.onFocus}>
                                        </Wheel>
                                    </div>
                                </div>
                                <div className="target_btns">
                                    
                                    {/* <Close action={this.gameCloseHandler.bind(this)} closeBtn={this.state.showBetButton} ></Close> */}
                                    <BalanceLabel name={this.props.user}></BalanceLabel>
                                    <FunTargetTimerInSeconds 
                                    childRef = {ref => (this.FunTargetTimerInSeconds  = ref)}
                                    winCover={this.state.winCover}
                                    betsDone={this.state.betsDone}
                                    action={this.rightButtonPanelHandler.bind(this)} 
                                    playerId={this.props.playerId}
                                    gameId={this.props.gameId}
                                    network={this.props.network}
                                    timesend={this.props.timesend}
                                    ref={this.FunTargetTimerInSeconds}
                                    showWin={this.state.showWin}
                                    winningAmount={this.state.winningAmount}
                                    resultNo={this.state.resultNo}
                                    ></FunTargetTimerInSeconds>
                                    <InfoBoard
                                        ref={this.InfoBoard}
                                        history={this.props.gameState.history}
                                        gameState={this.props.gameState}
                                        text={this.state.infoText}
                                        text1={this.state.infoText1}
                                        text2={this.state.infoText2}>
                                    </InfoBoard>
                                </div>
                            </div>
                            </div>
                        <div className="bottomFixFuntarget_timer">
                            <div className="fd fun_trg_timer_btns p_lr_10">
                                <LeftButtonPanel
                                    balance={this.props.balance}
                                    totalBet={this.state.Total_Bet_Amount}
                                    winning={this.state.winBet}>
                                </LeftButtonPanel>

                                <RightButtonPanel
                                    actBtn={this.state.actBtn}
                                    betBtnText={this.state.betButtonText}
                                    betButtonValue = {this.state.betButtonValue}
                                    betBtn={this.state.showBetButton}
                                    action={this.rightButtonPanelHandler.bind(this)}
                                    disDoubleBtn={this.state.disDoubleBtn}
                                    disBetBtn={this.state.disBetBtn}
                                    disClearBtn={this.state.disClearBtn}
                                    glowTakeBtn={this.state.glowTakeBtn}>
                                </RightButtonPanel>
                            </div>
                            <div className="fd betPanelMrg">
                                <BetPanel
                                    ref={this.betPanelRef}
                                    action={this.betPanelHandler.bind(this)}
                                    resultNo={this.state.resultNo}
                                    showWin={this.state.showWin}
                                    wins={this.state.wins}>
                                </BetPanel>
                            </div>
                        </div>
                        </div>
                    </div>                    
                </div>
            </React.Fragment>
        );
    }
}

const mapStatesToProps = (state)=>{
    return {
        language : state.languageObjs.languageObj
    }
}

export default connect(mapStatesToProps)(FunTargetTimer)
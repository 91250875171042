import React from "react";
import whellOuterFrame from "../../../../../../assets/games/money_Wheel/mw_wheel_border.gif"
import wheelInnerFrame from "../../../../../../assets/games/money_Wheel/mw_wheel.png"
import jackpat_5_Bg from "../../../../../../assets/games/money_Wheel/mw_jack_5.png"
import jackpat_6_Bg from "../../../../../../assets/games/money_Wheel/mw_jack_6.png"
import numberOne from "../../../../../../assets/games/money_Wheel/mw_bet_1.png"
import numberThree from "../../../../../../assets/games/money_Wheel/mw_bet_3.png"
import numberFive from "../../../../../../assets/games/money_Wheel/mw_bet_5.png"
import numberTen from "../../../../../../assets/games/money_Wheel/mw_bet_10.png"
import numberTwenty from "../../../../../../assets/games/money_Wheel/mw_bet_20.png"
import startPoint from "../../../../../../assets/games/money_Wheel/mw_btn_org.png"
import betOuter from "../../../../../../assets/games/money_Wheel/bet_outer.png"
import MoneyWheelTimerInSeconds from "./moneyWheelTimerSec";

export default class Wheel extends React.Component {
    constructor(props) {
        super(props);
        this.ftWheelRef = React.createRef();
        this.MoneyWheelTimerInSeconds = React.createRef();
        this.state = {
            // targetResult:this.props.targetResult,
            betAmount: [
                { id: 0, amount: 0 },
                { id: 1, amount: 0 },
                { id: 2, amount: 0 },
                { id: 3, amount: 0 },
                { id: 4, amount: 0 },
                { id: 5, amount: 0 },
                { id: 6, amount: 0 },
            ]
        }
        this.resNo = 0;
        this.firstload = true;
        this.resultNumber = 0;
        this.mw_wheelAnim = "";
        this.wheelString = [
            20, 1, 3, 1, 5, 1, 3, 1, 5, 1,
            10, 3, 1,
            45, 1, 3, 1, 5, 1, 3, 1,
            10, 3, 1, 5, 1,
            20, 1, 3, 1, 3, 1, 5, 1,
            10, 1, 3, 5, 1,
            45, 1, 3, 1, 5, 1, 3, 1,
            10, 5, 1, 3, 1
        ];
    };

    componentDidMount() {
        this.mw_wheelAnim = document.getElementById("mw_wheelAnim");
        console.log(this.props.gameState)
        if(this.props.gameState.takeAmount > 0){
            document.getElementById("mw_Cover").style.display = "block";
        }
        console.log(this.props.gameState.history)
        if (this.props.gameState.history != undefined && this.firstload && this.props.gameState.history.length > 0) {
            console.log(this.props.gameState.history.length)
            this.firstload = false;
            let lengthOfHistory = this.props.gameState.history.length
            console.log(this.props.gameState.history[lengthOfHistory - 1])
            console.log(this.props.gameState.history[lengthOfHistory - 1].resultNo);

            // let resNo = this.props.gameState.histrory[this.props.gameState.history.length-1].resultNo;
            let resNo = this.props.gameState.history[lengthOfHistory - 1].resultNo;
            // console.log("last Login result Number : " +resNo)
            this.spinTheWheelOnLoad(resNo)
            // this.spinTheWheelOnLoad(0)
        } else {
            let defaultNo = 0;
            this.spinTheWheelOnLoad(defaultNo);
        }
    }

    spinTheWheelOnLoad(s) {
        console.log(s);
        this.resNo = Number(s)

        const styles = {
            transition: 'all 0s',
            transform: `rotate(${((52 - this.resNo) * 6.92)}deg)`
        }

        Object.assign(this.mw_wheelAnim.style, styles);
    }

    componentStateHandler() {

    }
    stopWheelSound = () => {
        this.ftWheelRef.current.stopWheel();
    }

    wheelHandler(position) {
        this.props.action(position);
    }

    topLable_fn(labelValue) {
        // Nine Zeroes for Billions
        return Math.abs(Number(labelValue)) 
        // >= 1.0e+9

        //     ? Math.abs(Number(labelValue)) / 1.0e+9 + "B"
        //     // Six Zeroes for Millions 
        //     : Math.abs(Number(labelValue)) >= 1.0e+6

        //         ? Math.abs(Number(labelValue)) / 1.0e+6 + "M"
        //         // Three Zeroes for Thousands
        //         : Math.abs(Number(labelValue)) >= 1.0e+3

        //             ? Math.abs(Number(labelValue)) / 1.0e+3 + "K"

        //             : Math.abs(Number(labelValue));
    }


    updateBetAmt(id, val) {
        console.log(val + " BET PLACED ON BET sLOT    " + id);
        // this.state.betAmount[id].amount = val;

        
        let chipBetValue = Number(Number(val).toFixed(2));

        if (Number(chipBetValue) >= 1000 && Number(chipBetValue) <= 999999) {
          chipBetValue = Number((chipBetValue / 1000).toFixed(2)) + "K";
          console.log(chipBetValue);
        } else if (chipBetValue >= 1000000 && chipBetValue <= 999999999) {
          chipBetValue = Number((chipBetValue / 1000).toFixed(2)) + "M";
          console.log(chipBetValue);
        } else if (chipBetValue > 999999999) {
          chipBetValue = Number((chipBetValue / 1000).toFixed(2)) + "B";
          console.log(chipBetValue);
        }

        this.state.betAmount[id].amount = chipBetValue;


        


        // for (let i = 0; i < this.state.betAmount.length; i++) {
        //     if (this.state.betAmount[i].id == id) {

        //         const totalIbetAmount = val;
        //         console.log(totalIbetAmount);

        //         this.state.betAmount[i].amount = totalIbetAmount;
        //         this.setState({ betAmount: this.state.betAmount })
        //     }
        // }
    }


    mouseDownSelectCards(id) {
        console.log(id)
        clearInterval(this.clearId)
        this.props.betAmountAction(id)
        this.clearId = setInterval(() => {
            this.props.betAmountAction(id)
        }, 120)
    }

    mouseUpStopCards() {
        // console.log("Clear Interval is Called.")
        clearInterval(this.clearId)
        this.props.betDone();
    }

    spinTheWheel(s) {
        window.clearTimeout(this.whelltimeOut_1);
        this.whelltimeOut_1 = null;

        document.getElementById("whlmdlnum").textContent = "";
        console.log("moneyWheelResultNumber-----: " + s)
        const styles = {
            transition: 'all 4s',
            transform: `rotate(${(2160 - (s * 6.92))}deg)`
        }

        Object.assign(this.mw_wheelAnim.style, styles);

        this.whelltimeOut_1 = setTimeout(() => {
            this.afterRotate(s)
        }, 4200)
    }


    afterRotate(resultNumber) {
        const styles = {
            transition: 'all 0s',
            transform: `rotate(${((52 - resultNumber) * 6.92)}deg)`
        }
        Object.assign(this.mw_wheelAnim.style, styles);
        this.annouseWinner(resultNumber);
    };


    annouseWinner(resultNumber) {
        this.props.wheelHandler(resultNumber);
        const wheelValue = this.wheelString[resultNumber];
        console.log(wheelValue)
        if (wheelValue !== undefined && document.getElementById("whlmdlnum")) {
            document.getElementById("whlmdlnum").textContent = wheelValue;
            //document.getElementById("whlmdlnum").style = "wheelVal";
        }
    }
    sendTimer(timeData) {       
        console.log(timeData)
        this.MoneyWheelTimerInSeconds.current.sendTimer(timeData);
    }

    timerEvents(action) {     
        console.log(action)
        this.props.action(action);
    }

    Chek_Spec() {
        this.props.Chek_Spec();
    }
    disableKeyBoard(){
        this.props.disableKeyBoard();
    }

    clearBetAnim(){
        this.props.clearBetAnim();
        clearInterval(this.clearId)
    }

    closeGame(){
        clearInterval(this.clearId);
        this.MoneyWheelTimerInSeconds.current.exitGame();
    }


    render() {

        return (
            <div id="chpmnwheel" className="wheel_container">

                <div id="chipmnwhtwo" className="wheel_inner_outer_frame_container">
                    <div className="wheel_number_container">
                        <div className="wheel_inner_frame_container ccc" id="mw_wheelAnim">
                            <img className="wheel_inner_frame" src={wheelInnerFrame} alt="" />
                        </div>

                        <div className="wheel_outer_frame_container">
                            <img className="wheel_outer_frame" src={whellOuterFrame} id="map-image" alt="" />
                            {/* <span class="timerTxt">{this.props.timeTxt}</span>                                 */}
                        </div>
                        <div className="start_point_container">
                            <img className="start_point_image" src={startPoint} alt="" />
                            <div id="whlmdlnum" className="wheelVal">
                            </div>
                        </div>

                        <MoneyWheelTimerInSeconds
                            ref={this.MoneyWheelTimerInSeconds}
                            winCover={this.props.winCover}
                            betsDone={this.state.betsDone}
                            action={this.timerEvents.bind(this)}
                            chek_Spec={this.Chek_Spec.bind(this)}
                            clearBetAnim={this.clearBetAnim.bind(this)}
                            playerId={this.props.playerId}
                            gameId={this.props.gameId}
                            network={this.props.network}
                            timesend={this.props.ftt_timesend}
                            takeAmount={this.props.takeAmount}
                            isBetSent={this.props.isBetSent}
                            disableKeyBoard={this.disableKeyBoard.bind(this)}
                        ></MoneyWheelTimerInSeconds>

                    </div>

                    <div className="bet_ratio_container" >

                        <div className={(this.props.isMobile) ? "mwh_jackpat" : "mwh_jackpat addCursorPointer" }
                            onPointerUp={(e) => {
                                e.preventDefault();
                                this.mouseUpStopCards();
                                document.getElementById("jacFiveButton").style.transform = "scale(1)";
                            }}
                            onPointerOut={(e) => {
                                e.preventDefault();
                                this.mouseUpStopCards();
                                document.getElementById("jacFiveButton").style.transform = "scale(1)";
                            }}
                            onPointerLeave={(e) => {
                                e.preventDefault();
                                this.mouseUpStopCards();
                                document.getElementById("jacFiveButton").style.transform = "scale(1)";
                            }}
                            onPointerCancel={(e) => {
                                e.preventDefault();
                                this.mouseUpStopCards();
                                document.getElementById("jacFiveButton").style.transform = "scale(1)";
                            }}
                            onPointerDown={(e) => {
                                e.preventDefault();
                                document.getElementById("jacFiveButton").style.transform = "scale(0.9)";
                                // document.getElementById("jacFiveButton").style.scale = "0.9";
                                this.mouseDownSelectCards(this.state.betAmount[5].id);
                            }}
                        >
                            <img id="jacFiveButton" className="jackpat_Bg" src={jackpat_5_Bg} alt="" />                            
                            {Number(this.state.betAmount[5].amount) === 0 ? null :
                                <div className=" emty_chip_container emty_chip_0 goldCoin_mw">
                                    <span className="bet_amount_label_0">{this.state.betAmount[5].amount}</span>
                                </div>
                            }
                        </div>
                        <span className="jack_pat_one_1_45">1:45</span>

                        <div className={(this.props.isMobile) ? "mw_jackpat_1" : "mw_jackpat_1 addCursorPointer" }
                            onPointerUp={(e) => {
                                e.preventDefault();
                                this.mouseUpStopCards();
                                document.getElementById("jacSixButton").style.transform = "scale(1)";
                            }}
                            onPointerOut={(e) => {
                                e.preventDefault(); this.mouseUpStopCards();
                                document.getElementById("jacSixButton").style.transform = "scale(1)";
                            }}
                            onPointerLeave={(e) => {
                                e.preventDefault(); this.mouseUpStopCards();
                                document.getElementById("jacSixButton").style.transform = "scale(1)";
                            }}
                            onPointerCancel={(e) => {
                                e.preventDefault(); this.mouseUpStopCards();
                                document.getElementById("jacSixButton").style.transform = "scale(1)";
                            }}
                            onPointerDown={(e) => {
                                e.preventDefault();
                                document.getElementById("jacSixButton").style.transform = "scale(0.9)";
                                // document.getElementById("jacSixButton").style.scale = "0.9";
                                this.mouseDownSelectCards(this.state.betAmount[6].id);
                            }}
                        >
                            <img id="jacSixButton" className="jackpat_Bg" src={jackpat_6_Bg} alt="" />                            
                            {Number(this.state.betAmount[6].amount) === 0 ? null :
                                <div className="emty_chip_container emty_chip_1 goldCoin_mw">
                                    <span className="bet_amount_label_1">{this.state.betAmount[6].amount}</span>
                                </div>
                            }
                        </div>
                    </div>
                </div>

                <div className="numbers_ratio_container">

                    <div className={(this.props.isMobile) ? "number_Twenty" : "number_Twenty addCursorPointer" }
                        onPointerUp={(e) => {
                            e.preventDefault();
                            this.mouseUpStopCards();
                            // e.target.setAttribute('style', 'transform: scale(1);');
                            document.getElementById("number_20").style.transform = "scale(1)";
                        }}
                        onPointerOut={(e) => {
                            e.preventDefault();
                            this.mouseUpStopCards();                            
                            document.getElementById("number_20").style.transform = "scale(1)";
                        }}
                        onPointerLeave={(e) => {
                            e.preventDefault();
                            this.mouseUpStopCards();                            
                            document.getElementById("number_20").style.transform = "scale(1)";
                        }}
                        onPointerCancel={(e) => {
                            e.preventDefault();
                            this.mouseUpStopCards();                            
                            document.getElementById("number_20").style.transform = "scale(1)";
                        }}
                        onPointerDown={(e) => {
                            e.preventDefault();
                            // e.target.setAttribute('style', 'transform: scale(0.9);');
                            document.getElementById("number_20").style.transform = "scale(0.9)";
                            this.mouseDownSelectCards(this.state.betAmount[4].id);
                        }}
                    >
                        <img id="number_20" src={numberTwenty} className="number_20" alt="" />

                        {Number(this.state.betAmount[4].amount) === 0 ? null :
                            <div className=" emty_chip_container empty_chip_6 goldCoin_mw coinmw">
                                <span className="bet_amount_label_6 ">{this.state.betAmount[4].amount}</span>
                            </div>
                        }
                    </div>

                    <div className={(this.props.isMobile) ? "number_Ten" : "number_Ten addCursorPointer" }
                        onPointerUp={(e) => {
                            e.preventDefault(); this.mouseUpStopCards();
                            document.getElementById("number_10").style.transform = "scale(1)";
                        }}
                        onPointerOut={(e) => {
                            e.preventDefault(); this.mouseUpStopCards();
                            document.getElementById("number_10").style.transform = "scale(1)";
                        }}
                        onPointerLeave={(e) => {
                            e.preventDefault(); this.mouseUpStopCards();
                            document.getElementById("number_10").style.transform = "scale(1)";
                        }}
                        onPointerCancel={(e) => {
                            e.preventDefault(); this.mouseUpStopCards();
                            document.getElementById("number_10").style.transform = "scale(1)";
                        }}
                        onPointerDown={(e) => {
                            e.preventDefault();
                            document.getElementById("number_10").style.transform = "scale(0.9)";
                            this.mouseDownSelectCards(this.state.betAmount[3].id)
                        }}
                    >
                        <img id="number_10" className="number_10" src={numberTen} alt="" />

                        {Number(this.state.betAmount[3].amount) === 0 ? null :
                            <div className=" emty_chip_container emty_chip_5 goldCoin_mw">
                                <span className="bet_amount_label_5 ">{this.state.betAmount[3].amount}</span>
                            </div>
                        }
                    </div>

                    <div className={(this.props.isMobile) ? "number_Five" : "number_Five addCursorPointer" }
                        onPointerUp={(e) => {
                            e.preventDefault(); this.mouseUpStopCards();
                            document.getElementById("number_5").style.transform = "scale(1)";
                        }}
                        onPointerOut={(e) => {
                            e.preventDefault(); this.mouseUpStopCards();
                            document.getElementById("number_5").style.transform = "scale(1)";
                        }}
                        onPointerLeave={(e) => {
                            e.preventDefault(); this.mouseUpStopCards();
                            document.getElementById("number_5").style.transform = "scale(1)";
                        }}
                        onPointerCancel={(e) => {
                            e.preventDefault(); this.mouseUpStopCards();
                            document.getElementById("number_5").style.transform = "scale(1)";
                        }}
                        onPointerDown={(e) => {
                            e.preventDefault();
                            document.getElementById("number_5").style.transform = "scale(0.9)";
                            this.mouseDownSelectCards(this.state.betAmount[2].id)
                        }}
                    >

                        <img id="number_5" className="number_5" src={numberFive} alt="" />
                        {Number(this.state.betAmount[2].amount) === 0 ? null :
                            <div className=" emty_chip_container emty_chip_4 goldCoin_mw">
                                <span className="bet_amount_label_4 ">{this.state.betAmount[2].amount}</span>
                            </div>
                        }
                    </div>

                    <div className={(this.props.isMobile) ? "number_Three" : "number_Three addCursorPointer" }
                        onPointerUp={(e) => {
                            e.preventDefault(); this.mouseUpStopCards();
                            document.getElementById("number_3").style.transform = "scale(1)";
                        }}
                        onPointerOut={(e) => {
                            e.preventDefault(); this.mouseUpStopCards();
                            document.getElementById("number_3").style.transform = "scale(1)";
                        }}
                        onPointerLeave={(e) => {
                            e.preventDefault(); 
                            this.mouseUpStopCards();
                            document.getElementById("number_3").style.transform = "scale(1)";
                        }}
                        onPointerCancel={(e) => {
                            e.preventDefault(); this.mouseUpStopCards();
                            document.getElementById("number_3").style.transform = "scale(1)";
                        }}
                        onPointerDown={(e) => {
                            e.preventDefault();
                            document.getElementById("number_3").style.transform = "scale(0.9)";
                            this.mouseDownSelectCards(this.state.betAmount[1].id)
                        }}
                    >
                        <img id="number_3" className="number_3" src={numberThree} alt="" />

                        {Number(this.state.betAmount[1].amount) === 0 ? null :
                            <div className=" emty_chip_container emty_chip_3 goldCoin_mw">
                                <span className="bet_amount_label_3 ">{this.state.betAmount[1].amount}</span>
                            </div>
                        }
                    </div>

                    <div className={(this.props.isMobile) ? "number_One" : "number_One addCursorPointer" }                    
                        onPointerUp={(e) => {
                            e.preventDefault();
                            this.mouseUpStopCards();
                            if (document.getElementById("number_1")) {
                                document.getElementById("number_1").style.transform = "scale(1)";
                            }
                        }}
                        onPointerOut={(e) => {
                            e.preventDefault();
                            this.mouseUpStopCards();
                            if (document.getElementById("number_1")) {
                                document.getElementById("number_1").style.transform = "scale(1)";
                            }
                        }}
                        onPointerLeave={(e) => {
                            e.preventDefault();
                            this.mouseUpStopCards();
                            if (document.getElementById("number_1")) {
                                document.getElementById("number_1").style.transform = "scale(1)";
                            }
                        }}
                        onPointerCancel={(e) => {
                            e.preventDefault();
                            this.mouseUpStopCards();
                            if (document.getElementById("number_1")) {
                                document.getElementById("number_1").style.transform = "scale(1)";
                            }
                        }}
                        onPointerDown={(e) => {
                            e.preventDefault();
                            if (document.getElementById("number_1")) {
                                document.getElementById("number_1").style.transform = "scale(0.9)";
                            }
                            this.mouseDownSelectCards(this.state.betAmount[0].id)
                        }}
                    >
                        <img id="number_1" className="number_1" src={numberOne} />

                        {Number(this.state.betAmount[0].amount) === 0 ? null :
                            <div className=" emty_chip_container emty_chip_2 goldCoin_mw">
                                <span className="bet_amount_label_2 ">{this.state.betAmount[0].amount}</span>
                            </div>
                        }
                    </div>
                </div>
            </div>
        )
    }
}

import winning from "../../../../../../assets/games/keno/winning.png";

import clearSound from "../../../../../../assets/sounds/clear.mp3";
import betSound from "../../../../../../assets/sounds/bet.mp3";
import takeSound from "../../../../../../assets/sounds/take.mp3";
import winSound from "../../../../../../assets/sounds/win.mp3";
import loseSound from "../../../../../../assets/sounds/duplose.mp3";
import betincreaseSound from "../../../../../../assets/sounds/betoncard.mp3";
import winningpanel from "../../../../../../assets/games/keno/winning.png";
import chips from "../../.././chips";
import resultBalls from "../UI/kenoballs";
//import kenoD_up from "../../KenoD_up/kenoD_up";
import buttons from "../../../../../../assets/games/keno/exit button.png";
import React, { Component, Fragment } from "react";
import BetPannels from "../../../../../../assets/games/keno/betpa2.png";
import BetPannel from "../../../../../../assets/games/keno/bet.png";
import ammusement from "../../../../../../assets/games/keno/ammusementonly.png";
import takebutton from "../../../../../../assets/games/keno/exit button.png";
import betokglow from "../../../../../../assets/games/keno/betokglow.png";
import takewin from "../../../../../../assets/games/keno/takewinning.png";
import betokbtn from "../../../../../../assets/games/keno/betok.png";

import jar from "../../../../../../assets/games/keno/jar.png";
import cage from "../../../../../../assets/games/keno/cage.png";
import plus from "../../../../../../assets/games/keno/plus.png";
import minus from "../../../../../../assets/games/keno/minus.png";
import clearbtn from "../../../../../../assets/games/keno/clearBtn.png";
//import kenoanimation from "../../../../../../assets/games/keno/kenoanimation.gif";
//import Keno from "../keno";

import { connect } from "react-redux";

var kenoBet = [];
let arrowkeyshandle = true;
let keyBhandle = true;
let isEscapecliked = true;

class KenoTable extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      btn: false,
      takeamount: 0,
      winAmount: 0,
      betsdeduction:this.props.minBetAmt,
      balance: this.props.balance,
      Animationballs: [],
      showbutton: false,
      showWinningAmount: false,
      takeimagebtn: false,
      isTakeButtonClicked: true,
      chipsdisableenable: false,
      intervalIds: 0,
      balls: [],
      betbuttonglow: false,
      glowimage: false,
      disTakeBtn: true,
      unmatchedNumberselect: [],
      remainingNumbersclick: [],
      matchedNumberselect: [],
      kn_takeAmount: this.props.kn_takeAmount,
      chipSelect: 1,
      plusbtn: false,
      minusbtn: false,
      clearbtn: false,
      doublebtn: false,
      betAmounts: this.props.minBetAmt,
      betbtn: false,
      balls: [],
      Total_Bet_Amount: 0,
      resultNo: this.props.resultNo,
      gameId: this.props.gameId,
      hidebtn: true,
      infoText:
        this.props.language.Play_Njoy +" "+ this.props.language.keno_bet_info,
      tableBtn: false,
      rows: [],
      clicked: [],
      updatedBalance: 0,
      isspaceenabled: false,
      keyboardEnable: true,
    };
    this.updatePlayerBalnce = false;
    this.clickedNumbers = [];
    this.randomButtons = [];
    this.kenoclrnbet = [];
    this.matchedNumberselect = [];
    this.remainingNumbersclickselect = [];
    this.clicked = [];
    this.clickedA = [];

    this.table_timeout1 = null;
    this.table_timeout2 = null;
    this.table_timeout3 = null;
    this.table_timeout4 = null;
    this.table_timeout5 = null;
    this.incrementIntervalId = null;
    this.decrementIntervalId = null;

    this.isRandBtnClicked = false;
  }

  componentDidMount() {
    isEscapecliked = true;

    const { childRef } = this.props;
    childRef(this);
    this.clearSound = new Audio(clearSound);
    this.betSound = new Audio(betSound);
    this.takeSound = new Audio(takeSound);
    this.winSound = new Audio(winSound);
    this.loseSound = new Audio(loseSound);
    this.betincreaseSound = new Audio(betincreaseSound);

    document.getElementById("clearBtn").classList.add("disableBtns");

    this.setState({
      clearbtn: true,
      betbtn: true,
      chipsdisableenable: false,
      plusbtn: false,
      minusbtn: false,
      infoText: this.props.language.Play_Njoy +" "+ this.props.language.keno_bet_info,
    });
    document.getElementById("betBtn").classList.add("disableBtns");
    // document.getElementById("takeBtn").classList.add("disableBtns");
    this.resetBetList();
    document.getElementById("bet_coverpannel").style.display = "none";
    // console.log(this.props.gameState.takeAmount);

    // console.log("Min Bet Amount: "+this.props.minBetAmt);
    // console.log("Max Bet Amount: "+this.props.maxBetAmt);

    // console.log(this.props.gameState.takeAmount)
    document.addEventListener("keyup", this.er_handleKeyboardEvent);
    document.addEventListener("keydown", this.kn_arrow_handleKeyboardEvent);

    // if (this.props.gameState.takeAmount > 0) {

    //   this.setState({
    //     isTakeButtonClicked: false,
    //     isspaceenabled: true,
    //     winAmount: this.props.gameState.takeAmount
    //   })

    //   if (document.getElementById("takeBtn")) {
    //     document.getElementById("takeBtn").classList.remove("disableBtns");
    //   }
    //   if (document.getElementById("doubledBtn")) {
    //     document.getElementById("doubledBtn").classList.add("disableBtns");
    //   }
    //   // console.log(this.props.takeAmount);
    //   // console.log(this.props.gameState)
    //   let kn_betString = this.props.gameState.betRequest.betString;
    //   let resString = this.props.gameState.response.resultNo;

    //   // console.log(kn_betString);
    //   // console.log(resString)
    //   let betString2 = kn_betString.replaceAll("|", "");
    //   // console.log(betString2.length)
    //   for (let k = 0; k < betString2.length; k++) {
    //     let idNum3 = k + 1;
    //     if (betString2[k] == 1) {
    //       this.clickedNumbers[k] = 1;
    //       if (this.clickedNumbers[k] = 1) {
    //         this.randomButtons.push(k + 1);
    //         if (this.randomButtons.length > 10) {
    //           this.randomButtons = this.randomButtons.slice(0, 10);
    //         }
    //       }
    //       document.getElementById("bet_" + idNum3).classList.add("fill-color-mixedb");
    //     }
    //   }
    //   // console.log(betString2);

    //   for (let m = 0; m < resString.length; m++) {
    //     if (betString2[resString[m] - 1] == 1) {
    //       document.getElementById("bet_" + resString[m]).classList.add("fill-color-mixedgclick");
    //     } else {
    //       document.getElementById("bet_" + resString[m]).classList.add("fill-color-mixedr");
    //     }
    //   }

    // } else {
    //   for (let m = 1; m < 80; m++) {
    //     document.getElementById("bet_" + m).classList.add("fill-color-mixed");
    //     this.clickedNumbers[m] = 0;
    //   }
    // }
  }
  componentWillUnmount() {
    document.removeEventListener("keyup", this.er_handleKeyboardEvent());
    document.removeEventListener("keydown", this.kn_arrow_handleKeyboardEvent);
  }

  er_handleKeyboardEvent = (evt) => {
    // if (!this.props.showdupbutton && evt && this.props.isGameActive) {
      if (evt && this.props.isGameActive) {
      // console.log(evt.code);
      // console.log(this.state.clearbtn);
      switch (evt.code) {
        case "Enter":
        case "NumpadEnter":
          console.log(this.state.betbtn);
          console.log(this.state.keyboardEnable)
          if (!this.state.betbtn && this.state.keyboardEnable) {            
            console.log(this.clickedA.length)
            if (this.clickedA.length == 10) {              
              arrowkeyshandle = false;
              keyBhandle = false;
              if (document.getElementById("bet_coverpannel")) {
                document.getElementById("bet_coverpannel").style.display =
                  "block";
              }
              this.sendBet();
            } else if (this.randomButtons.length == 10) {
              arrowkeyshandle = false;
              keyBhandle = false;
              if (document.getElementById("bet_coverpannel")) {
                document.getElementById("bet_coverpannel").style.display =
                  "block";
              }
              this.sendBet();
            }
          }
          break;
        // case "Space":
        //   console.log(this.state.winAmount > 0)
        //   console.log(this.state.isspaceenabled)
        //   console.log(this.state.isTakeButtonClicked)
        //   if (this.state.winAmount > 0 && this.state.isspaceenabled && !this.state.isTakeButtonClicked && this.state.keyboardEnable) {
        //     console.log("Keno Send Take")
        //     this.sendTake();
        //   }
        //   break;
        case "Escape":
          if (isEscapecliked) {
            //this.props.gameCloseHandler();
          }
          break;
        case "KeyC":
          if (!this.state.clearbtn && this.state.keyboardEnable) {
            this.clearSound.play();
            this.clearBet();
            this.isRandBtnClicked = false;
          }
          break;
        case "KeyR":
          console.log(this.isRandBtnClicked);
          console.log(document.getElementById("randBtn"));
          console.log(document.getElementById("randBtn").classList.contains("disableBtns"));
          if (
            !this.isRandBtnClicked &&
            document.getElementById("randBtn") &&
            !document
              .getElementById("randBtn")
              .classList.contains("disableBtns") &&
            this.state.keyboardEnable
          ) {
            this.randomTableClick();
            this.isRandBtnClicked = true;
          }
          break;
        case "KeyD":
          if (
            this.state.winAmount > 0 &&
            this.state.takeamount <= 5000 &&
            this.state.winAmount <= 5000 &&
            this.state.isspaceenabled &&
            this.state.keyboardEnable
          ) {
            this.props.showDoubleUP();
          }
          break;
        default:
          break;
      }
    }
  };

  kn_arrow_handleKeyboardEvent = (evt) => {
    // if (!this.props.showdupbutton && this.state.keyboardEnable) {
      if (this.state.keyboardEnable) {
      console.log(evt.code);
      console.log(arrowkeyshandle);
      switch (evt.code) {
        case "ArrowUp":
        case "KeyB":
          if (arrowkeyshandle) {
            this.betincreament();
          }
          break;
        case "ArrowDown":
          if (arrowkeyshandle) {
            this.betdecreament();
          }
          break;
        default:
          break;
      }
    }
  };

  clearTimeout_fn() {
    isEscapecliked = false;
    document.removeEventListener("keyup", this.er_handleKeyboardEvent);
    document.removeEventListener("keydown", this.kn_arrow_handleKeyboardEvent);
    this.randomButtons = [];
    arrowkeyshandle = true;
    this.clickedA = [];

    clearTimeout(this.table_timeout1);
    clearTimeout(this.table_timeout2);
    clearTimeout(this.table_timeout3);
    clearTimeout(this.table_timeout4);
    clearInterval(this.incrementIntervalId);
    clearInterval(this.decrementIntervalId);

    if (document.getElementById("winPop")) {
      document.getElementById("winPop").style.display = "none";
    }
  }

  gamestate(event) {
    //alert("........................1")
    if (event.betRequest.betString) {
      console.log(event.betRequest.betString);
      kenoBet = event.betRequest.betString;
    }

    console.log(event.takeAmount);
    arrowkeyshandle = true;
    keyBhandle = true;
    // if (event.takeAmount > 0) {
    //   //this.isRandBtnClicked = true; //20102023
    //   arrowkeyshandle = false;
    //   keyBhandle = false

    //   this.isRandBtnClicked = true;

    //   this.setState({
    //     infoText: this.props.language.ClickTake,
    //     doublebtn: true,
    //     takeamount: event.takeAmount,
    //     disTakeBtn: false,
    //     winAmount: event.takeAmount,
    //     betAmounts: this.props.gameState.betRequest.betAmount,
    //     plusbtn: true,
    //     minusbtn: true,

    //   })

    //   this.table_timeout1 = setTimeout(() => {
    //     if (this.props.isGameActive) {
    //       this.setState({ infoText: this.props.language.Play_Njoy });
    //     }
    //   }, 2000);

    //   if (document.getElementById("kenoCover")) {
    //     document.getElementById("kenoCover").style.display = "block";
    //   }

    //   this.props.takehandlerandombtn();

    //   this.setState(prevState => ({
    //     glowimage: !prevState.glowimage
    //   }));

    //   this.props.showballstake();

    //   if (document.getElementById("bet_coverpannel")) {
    //     document.getElementById("bet_coverpannel").style.display = "block";
    //   }
    // }
    // else {
    //   this.setState({
    //     // disTakeBtn: true,
    //     showbutton: false,
    //     // infoText: this.props.language.Play_Njoy,
    //     plusbtn: false,
    //     minusbtn: false
    //   })

    //   if (document.getElementById("bet_coverpannel")) {
    //     document.getElementById("bet_coverpannel").style.display = "none";
    //   }
    // }
  }

  disabletake() {
    this.setState({
      isTakeButtonClicked: true,
      disTakeBtn: true,
      showbutton: false,
      winAmount: 0,
    });
    // if (document.getElementById("takeBtn")) {
    //   document.getElementById("takeBtn").classList.add("disableBtns");
    // }
  }

  resetBetList() {
    this.randomButtons = this.randomButtons;
    //console.log(this.randomButtons)

    for (let k = 0; k < 80; k++) {
      this.clickedNumbers[k] = 0;
    }
  }

  clearBet() {
    // if (!this.props.showdupbutton && this.props.isGameActive) {
      if (this.props.isGameActive) {
      isEscapecliked = true;

      if (document.getElementById("doubledBtn")) {
        document.getElementById("doubledBtn").classList.remove("disableBtns");
      }

      if (document.getElementById("bet_coverpannel")) {
        document.getElementById("bet_coverpannel").style.display = "none";
      }
      arrowkeyshandle = true;
      keyBhandle = true;
      const buttonDisable = document.getElementsByClassName("kenobetpannel");

      const buttons = document.getElementsByClassName("kenobetpannel");
      for (let k = 0; k < 80; k++) {
        //console.log(" -------------------  2");
        this.clickedNumbers[k] = 0;
        // const buttonNum = parseInt(buttonDisable[k].innerText);
        //console.log(buttonDisable[k]);
        if (buttonDisable[k]) {
          buttonDisable[k].disabled = false;
          buttonDisable[k].classList.remove("fill-color-mixedb");
          buttonDisable[k].classList.remove("fill-color-mixedgran");
          buttonDisable[k].classList.remove("fill-color-mixedr");
          buttonDisable[k].classList.remove("fill-color-mixedgclick");
          document.getElementById("bet_" + (k + 1)).disabled = false;
          if (buttons && k < buttons.length) {
            buttons[k].classList.remove("fill-color-mixedb");
          }
        }
      }
      this.randomButtons = [];
      this.clickedA = [];
      this.props.afterclearenable();
      // this.props.randenable();
      this.props.closeBallanimation();

      this.setState({
        infoText:
          this.props.language.Play_Njoy +" "+ this.props.language.keno_bet_info,
        chipsdisableenable: false,
        betbtn: true,
        plusbtn: false,
        minusbtn: false,
        betAmounts: this.props.minBetAmt,
        betsdeduction:this.props.minBetAmt,
      });
      if (document.getElementById("betBtn")) {
        document.getElementById("betBtn").classList.add("disableBtns");
      }
      this.clickedA.length = 0;
      if (document.getElementById("kenoCover")) {
        document.getElementById("kenoCover").style.display = "none";
      }

      this.setState({ clearbtn: true, betbuttonglow: false });
      if (document.getElementById("clearBtn")) {
        document.getElementById("clearBtn").classList.add("disableBtns");
      }
      this.props.clearbetAmount();
      this.isRandBtnClicked = false;
    }
  }

  randomTableClick() {
    isEscapecliked = true;
    //alert(".....................2"+ this.randomButtons )
    document.getElementById("bet_coverpannel").style.display = "none";
    // document.getElementById("plusbtn").style.zIndex = "1400"
    // document.getElementById("minusbtn").style.zIndex = "1400"
    this.betSound.play();
    this.randomButtons = [];
    this.setState({
      plusbtn: false,
      minusbtn: false,
    });
    console.log(this.randomButtons.length);
    if (this.randomButtons.length === 0) {
      this.isRandBtnClicked = true;
    }
    console.log(this.props.balance);

    this.setState({
      
      infoText:
      this.props.language.MinBetAmt + " " +this.props.minBetLbl+" "+ this.props.language.MaxBetAmt + " " +this.props.maxBetLbl,
      showbutton: false,
      clearbtn: false,
      betbtn: false,
      betbuttonglow: true,
      chipsdisableenable: true,
    });
    this.props.dis_randBtn();
    console.log("Button clicked in kenotable component");
    console.log(this.props.resultNo);

    document.getElementById("betBtn").classList.remove("disableBtns");
    document.getElementById("clearBtn").classList.remove("disableBtns");
    document.getElementById("kenoCover").style.display = "block";

    const availableButtons = [];

    for (let i = 1; i <= 80; i++) {
      if (!this.clickedA.includes(i)) {
        availableButtons.push(i);
      }
    }

    for (let i = 0; i < 10; i++) {
      const randomIndex = Math.floor(Math.random() * availableButtons.length);
      const randomButtonNumber = availableButtons[randomIndex];
      this.randomButtons.push(randomButtonNumber);
      availableButtons.splice(randomIndex, 1);
    }

    // console.log('Selected random buttons:', this.randomButtons);
    //alert(">................................"+this.randomButtons)
    this.randomButtons = this.randomButtons;
    // let storedrandombuttons=localStorage.setItem(this.randomButtons)

    console.log(this.randomButtons);

    if (this.randomButtons.length) {
      this.setState({ tableBtn: true, betbtn: false });
      document.getElementById("betBtn").classList.remove("disableBtns");
    }

    const buttonr = document.getElementsByClassName("kenobetpannel");

    console.log(this.matchedNumberselect);
    console.log(this.remainingNumbersclickselect);
    console.log(buttonr.length);

    for (let i = 0; i < buttonr.length; i++) {
      const buttonNumbers = parseInt(buttonr[i].innerText);
      if (this.randomButtons.includes(buttonNumbers)) {
        buttonr[i].classList.add("fill-color-mixedb");
      }
    }

    console.log(this.randomButtons.length);
    for (let i = 0; i < this.randomButtons.length; i++) {
      if (this.randomButtons !== 0) {
        this.clickedNumbers[this.randomButtons[i] - 1] = 1;
      }
    }
    console.log(this.clickedNumbers);
  }

  handleResult() {
    if (this.props.isGameActive) {
      console.log(this.props.resultNo);

      if (this.props.winAmount > 0) {
        const getPlayerinfo = {
          sessionId: sessionStorage.getItem("sessionId"),
          CN: "GET_PLAYER_INFO",
          object: {
            playerId: Number(this.props.playerId),
            gameId: this.props.gameId,
          },
        };
        this.props.network.sendof(getPlayerinfo);

        // console.log(this.props.resultNo);
        this.setState({
          infoText: this.props.language.You_won,
          winAmount: this.props.winAmount,
          takeAmount: this.props.winAmount,
        });

        this.winSound.play();
        this.props.clearballsballstake();
        this.table_timeout3 = setTimeout(() => {
          document.getElementById("bet_coverpannel").style.display = "block";
          document.getElementById("kenoCover").style.display = "block";
          document.getElementById("bet_coverpannel").style.display = "none";
         
          arrowkeyshandle = true;
          keyBhandle = true;
          this.props.dis_randBtn();
          this.props.closeBallanimation();
        
          if (this.props.isGameActive) {
            this.setState({
              betbuttonglow: true,
              clearbtn: false,
              betbtn:false,
              betAmounts:this.props.minBetAmt,
              betsdeduction:this.props.minBetAmt,
              doublebtn: false,
              winAmount: 0,
              infoText: this.props.language.Play_Njoy,
            });
          }
        }, 3000);

        this.table_timeout4 = setTimeout(() => {
          if (this.props.isGameActive) {
           // this.enableSpaceBar();
            this.enableKeyboard(true);
          }
        }, 3500);

        const getGameStateBody = {
          sessionId: sessionStorage.getItem("sessionId"),
          CN: "GET_GAME_STATE",
          object: {
            playerId: Number(this.props.playerId),
            gameId: this.props.gameId,
          },
        };

        this.props.network.sendof(getGameStateBody);
      } else {
        isEscapecliked = true;
        if (this.props.isGameActive) {
          this.loseSound.play();
        }

        this.table_timeout5 = setTimeout(() => {
          //document.getElementById("bet_coverpannel").style.display = "block";
         // document.getElementById("kenoCover").style.display = "";
          arrowkeyshandle = true;
          keyBhandle = true;
        
          if (this.props.isGameActive) {
            document.getElementById("bet_coverpannel").style.display = "block";
            document.getElementById("kenoCover").style.display = "block";
            this.setState({
              winAmount: 0,
              infoText: this.props.language.Play_Njoy,
              betAmounts:this.props.minBetAmt,
              betsdeduction:this.props.minBetAmt,
              clearbtn: false,
              betbuttonglow: true,
              doublebtn: false,
             betbtn: false,
              plusbtn: false,
              minusbtn: false,
            });
            this.enableKeyboard(true);
            arrowkeyshandle = true;
            const getPlayerInfoBody = {
              sessionId: sessionStorage.getItem("sessionId"),
              CN: "GET_PLAYER_INFO",
              object: {
                playerId: Number(this.props.playerId),
                gameId: this.props.gameId,
              },
            };
            this.props.network.sendof(getPlayerInfoBody);

            const getGameStateBody = {
              sessionId: sessionStorage.getItem("sessionId"),
              CN: "GET_GAME_STATE",
              object: {
                playerId: Number(this.props.playerId),
                gameId: this.props.gameId,
              },
            };
            this.props.network.sendof(getGameStateBody);
            document.getElementById("bet_coverpannel").style.display = "none";
          }
        }, 1000);
      }
    }
  }

  enableKeyboard = (status) => {
    this.setState({
      keyboardEnable: status,
    });
  };

  // enableSpaceBar() {
  //   this.setState({
  //     isspaceenabled: true,
  //     disTakeBtn: false,
  //     isTakeButtonClicked: false,
  //     infoText: this.props.language.Play_Njoy,
  //   });
    // if (document.getElementById("takeBtn")) {
    //   document.getElementById("takeBtn").classList.remove("disableBtns");
    // }
  //}

  // sendTake() {

  //   if (!this.state.disTakeBtn) {
  //     isEscapecliked = true;
  //     if (document.getElementById("betBtn")) {
  //       document.getElementById("betBtn").classList.remove("disableBtns");
  //     }
  //     document.getElementById("bet_coverpannel").style.display = "none";
  //     document.getElementById("kenoCover").style.display = "block";
  //     arrowkeyshandle = true;
  //     keyBhandle = true;

  //     this.props.dis_randBtn();
  //     this.props.closeBallanimation();
  //     this.props.disableD_UP();

  //     console.log("-----------  Keno Check Game Under Maintenance------------")
  //     this.props.showgameundermaintence();
  //     this.takeSound.play();
  //     document.getElementById("takeBtn").classList.add("disableBtns");
  //     document.getElementById("d_upBtn").classList.add("disableBtns");

  //     document.getElementById("clearBtn").classList.remove("disableBtns");
  //     document.getElementById("doubledBtn").classList.remove("disableBtns");

  //     this.setState({
  //       chipsdisableenable: false,
  //       winAmount: 0,
  //       showImage: true,
  //       isTakeButtonClicked: true,
  //       showbutton: false,
  //       plusbtn: false,
  //       minusbtn: false,
  //       clearbtn: false,
  //       betbuttonglow: true,
  //       betbtn: false,
  //       doublebtn: false,
  //       isspaceenabled: false,
  //       disTakeBtn: true,
  //       infoText: this.props.language.Play_Njoy,
  //       glowimage: false
  //     })

  //     const buttons = document.getElementsByClassName('kenobetpannel');
  //     // console.log(buttons)
  //     for (let i = 0; i < buttons.length; i++) {

  //       buttons[i].classList.remove('fill-color-mixedgran');
  //       buttons[i].classList.remove('fill-color-mixedgclick');
  //       buttons[i].classList.remove('fill-color-mixedr');
  //       const buttonNumber = parseInt(buttons[i].innerText);

  //       document.getElementById("bet_" + (i + 1)).disabled = false;

  //       if (this.matchedNumberselect.includes(buttonNumber)) {
  //         buttons[i].classList.remove("fill-color-mixedgran");
  //       }
  //     }

  //     const body = {
  //       sessionId: localStorage.getItem('sessionId'),
  //       CN: "TAKE_AMOUNT",
  //       object: {
  //         playerId: Number(this.props.playerId),
  //         gameId: this.props.gameId
  //       }
  //     };
  //     this.props.network.sendof(body);

  //     const getGameStateBody = {
  //       sessionId: localStorage.getItem('sessionId'),
  //       CN: "GET_GAME_STATE",
  //       object: {
  //         playerId: Number(this.props.playerId),
  //         gameId: this.props.gameId
  //       },
  //     };
  //     this.props.network.sendof(getGameStateBody);
  //   }
  // };

  handleBetAmountChange(num) {
    if (this.state.betAmounts <= this.props.balance) {
      isEscapecliked = true;
      // alert("...................")
      this.tablebetclick = true;
      this.betSound.pause();
      this.betSound.currentTime = 0;
      this.betSound.play();

      document.getElementById("kenoCover").style.display = "none";
      console.log(this.state.chipSelect);
      console.log(this.props.balance);

      this.setState({
        infoText:
          this.props.language.Play_Njoy +" "+ this.props.language.keno_bet_info,
        showbutton: false,
        betbtn: true,
      });
      document.getElementById("betBtn").classList.add("disableBtns");
      console.log("...................");
      this.props.dis_randBtn();
      console.log(num);
      let k = num;
      if (!this.clickedA.includes(num)) {
        this.clickedA.push(k);
        console.log(this.clickedA);
        console.log(this.clickedA.length);

        if (this.clickedA.length >= 10) {
          isEscapecliked = true;
          document.getElementById("kenoCover").style.display = "block";
          this.setState({ betbtn: false, betbuttonglow: true });
          document.getElementById("betBtn").classList.remove("disableBtns");
          document.getElementById("bet_coverpannel").style.display = "none";
          this.setState({
            infoText:
            this.props.language.MinBetAmt + " " +this.props.minBetLbl+" "+ this.props.language.MaxBetAmt + " " +this.props.maxBetLbl,
          });
        } else if (this.clickedA.length == 1) {
          this.setState({ clearbtn: false });
        }
      }

      document.getElementById("bet_" + num).disabled = true;
      document.getElementById("bet_" + num).classList.add("fill-color-mixedb");
      document.getElementById("clearBtn").classList.remove("disableBtns");

      if (num !== null) {
        this.clickedNumbers[num - 1] = 1;
      } else if (this.randomButton !== null) {
        this.clickedNumbers[this.randomButton - 1] = 1;
      }
    } else {
      this.betSound.play();
      this.setState({ infoText: this.props.language.LowBalance });
    }
  }

  enablebetandclear() {
    this.setState({
      clearbtn: false,
      betbuttonglow: true,
      betbtn: false,
      doublebtn: false,
      isspaceenabled: false,
    });
    arrowkeyshandle = true;
  }
  changeAmtlabl(chngAmt){
    let newAmt = Number(Number(chngAmt).toFixed(2));
    if(newAmt >= 1000 && newAmt <=999999){
      newAmt = Number((newAmt / 1000).toFixed(2))+"K";      
    }else if(newAmt >= 1000000 && newAmt <=999999999){
      newAmt = Number((newAmt / 1000).toFixed(2))+"M";    
    }else if(newAmt >999999999){
      newAmt = Number((newAmt / 1000).toFixed(2))+"B";      
    }
    return newAmt;
  }
  sendBet() {
    if (this.state.betAmounts < this.props.minBetAmt) {
      document.getElementById("bet_coverpannel").style.display = "none";
      arrowkeyshandle = true;
      keyBhandle = true;
      isEscapecliked = true;
    } else {
      document.getElementById("bet_coverpannel").style.display = "block";
      arrowkeyshandle = false;
      keyBhandle = false;
      isEscapecliked = true;
    }

    if (Number(this.state.betAmounts) <= Number(this.props.balance)) {
      this.props.closeBallanimation();

      this.setState({
        betbtn: false,
      });

      const buttonDisable = document.getElementsByClassName("kenobetpannel");
      for (let k = 0; k < 80; k++) {
        // this.clickedNumbers[k] = 0;
        // const buttonNum = parseInt(buttonDisable[k].innerText);
        buttonDisable[k].disabled = false;

        //buttonDisable[k].classList.remove('fill-color-mixedb');
        buttonDisable[k].classList.remove("fill-color-mixedgran");
        buttonDisable[k].classList.remove("fill-color-mixedr");
        buttonDisable[k].classList.remove("fill-color-mixedgclick");
        document.getElementById("bet_" + (k + 1)).disabled = false;
      }

      //this.randomButtons = [];
      this.betSound.play();
      document.getElementById("bet_coverpannel").style.display = "block";
      if (this.state.betAmounts >= this.props.minBetAmt) {
        // document.getElementById("takeBtn").classList.add("disableBtns");
        document.getElementById("kenoCover").style.display = "block";
        document.getElementById("betBtn").classList.add("disableBtns");
        document.getElementById("clearBtn").classList.add("disableBtns");
        document.getElementById("doubledBtn").classList.add("disableBtns");
        this.setState({
          chipsdisableenable: true,
          betbuttonglow: false,
          // disTakeBtn: true,
          betbtn: true,
          doublebtn: true,
        });
        this.setState({ clearbtn: true });

        kenoBet = String(this.clickedNumbers);
        console.log(kenoBet);

        if (this.isRandBtnClicked) {
          this.props.updateBetString(this.randomButtons);
        } else if (this.clickedA) {
          this.props.updateBetString(this.clickedA);
        }

        kenoBet = kenoBet.replace(/,/g, "|");
        console.log(this.clickedA);
        const body = {
          sessionId: sessionStorage.getItem("sessionId"),
          CN: "KENO_BET",
          object: {
            betString: kenoBet,
            playerId: Number(this.props.playerId),
            gameId: this.props.gameId,
            betAmount: Number(this.state.betAmounts),
          },
        };
        this.props.network.sendof(body);

        const body2 = {
          CN: "GET_PLAYER_INFO",
          object: {
            playerId: Number(this.props.playerId),
            gameId: this.props.gameId

          },
          sessionId: sessionStorage.getItem('sessionId'),
        }
        this.props.network.sendof(body2);
        this.body2 = {};
        let betAmountLabel_keno;
        if(this.state.betAmounts){
          betAmountLabel_keno = this.changeAmtlabl(this.state.betAmounts)
        }
        this.setState({
          infoText: this.props.language.BetAccepted + betAmountLabel_keno
        });
      } else {
        this.setState({
          infoText:
          this.props.language.MinBetAmt + " " +this.props.minBetLbl+" "+ this.props.language.MaxBetAmt + " " +this.props.maxBetLbl,
        });
      }
    } else {
      this.setState({ infoText: this.props.language.LowBalance });
    }
  }

  betdecreament() {
    if (this.state.betAmounts > this.props.minBetAmt) {
      this.betincreaseSound = new Audio(betincreaseSound);
      this.betincreaseSound.play();
  
      const newBetAmounts = Number((Number(this.state.betAmounts) - Number(this.props.minBetAmt)).toFixed(2));
      if (newBetAmounts <= this.props.balance) {
        if (newBetAmounts <= this.props.maxBetAmt) {
          this.setState({
            betAmounts: newBetAmounts,
            betsdeduction:newBetAmounts,
            infoText:
              this.props.language.Play_Njoy
              //  +
              // this.props.language.MinBetAmt + " " +this.props.minBetLbl+" "+ this.props.language.MaxBetAmt + " " +this.props.maxBetLbl,
          });
        } else {
          this.setState({
            infoText:this.props.language.MinBetAmt + " " +this.props.minBetLbl+" "+ this.props.language.MaxBetAmt + " " +this.props.maxBetLbl,
          });
        }
      } else {
        this.setState({
          infoText: this.props.language.LowBalance,
          betAmounts: this.state.betAmounts,
          betsdeduction:newBetAmounts,
        });
      }
    }
  }

  betincreament() {
    // console.log(this.props.maxBetAmt)
    // console.log(this.props.minBetAmt)

    if (this.state.betAmounts + 1 <= this.props.balance) {
      //alert("...1")
      if (this.state.betAmounts < this.props.maxBetAmt) {
        //document.getElementById("bet_coverpannel").style.display = "none";
        this.betincreaseSound = new Audio(betincreaseSound);
        this.betincreaseSound.play();
      } else {
        this.betincreaseSound.pause();
        //document.getElementById("bet_coverpannel").style.display = "none";
        this.setState({
          infoText:
            // this.props.language.Play_Njoy 
            // +
            this.props.language.MinBetAmt + " " +this.props.minBetLbl+" "+ this.props.language.MaxBetAmt + " " +this.props.maxBetLbl,
        });
      }

      if (
        this.state.betAmounts < this.props.balance &&
        this.state.betAmounts < this.props.maxBetAmt
      ) {
        // const newBetAmounts = Number(this.state.betAmounts + this.props.minBetAmt).toFixed(2);
        const newBetAmounts = Number((Number(this.state.betAmounts) + Number(this.props.minBetAmt)).toFixed(2));


        if (newBetAmounts <= this.props.balance) {
          if (newBetAmounts <= this.props.maxBetAmt) {
            this.setState({
              betAmounts: newBetAmounts,
              betsdeduction:newBetAmounts,
              infoText:
                this.props.language.Play_Njoy 
                // +
                // this.props.language.MinBetAmt + " " +this.props.minBetLbl+" "+ this.props.language.MaxBetAmt + " " +this.props.maxBetLbl,
            });
          } else {
            this.setState({
              infoText:
              this.props.language.MinBetAmt + " " +this.props.minBetLbl+" "+ this.props.language.MaxBetAmt + " " +this.props.maxBetLbl,
            });
          }
        } else {
          this.setState({
            infoText: this.props.language.LowBalance,
            betAmounts: this.state.betAmounts,
            betsdeduction:this.state.betAmounts,
          });
        }
      }
    } else {
      this.setState({
        infoText: this.props.language.LowBalance,
      });
    }
  }
  betAmountdouble() {
    // alert("double")
    console.log(this.state.betAmounts);
    this.betSound.play();
    if (this.state.betAmounts * 2 <= this.props.balance) {
      if (
        this.state.betAmounts < this.props.balance &&
        this.state.betAmounts < this.props.maxBetAmt
      ) {
        const newBetAmounts = this.state.betAmounts * 2;
        if (newBetAmounts <= this.props.balance) {
          if (newBetAmounts <= this.props.maxBetAmt) {
            document.getElementById("clearBtn").classList.remove("disableBtns");
            this.setState({
              betAmounts: newBetAmounts,
              betsdeduction:newBetAmounts,
              clearbtn: false,
              infoText:
                this.props.language.Play_Njoy 
                // +
                // this.props.language.MinBetAmt + " " +this.props.minBetLbl+" "+ this.props.language.MaxBetAmt + " " +this.props.maxBetLbl,
            });
          } else {
            // this.setState({ infoText: this.props.language.LowBalance })
            this.setState({
              infoText:
              this.props.language.MinBetAmt + " " +this.props.minBetLbl+" "+ this.props.language.MaxBetAmt + " " +this.props.maxBetLbl,
            });
          }
        }
      }
    } else {
      this.setState({ infoText: this.props.language.LowBalance });
    }
  }

  // topLable_fn(labelValue) {
  //   // Nine Zeroes for Billions
  //   return Math.abs(Number(labelValue)) >= 1.0e9
  //     ? Math.abs(Number(labelValue)) / 1.0e9 + "B"
  //     : // Six Zeroes for Millions
  //     Math.abs(Number(labelValue)) >= 1.0e6
  //     ? Math.abs(Number(labelValue)) / 1.0e6 + "M"
  //     : // Three Zeroes for Thousands
  //     Math.abs(Number(labelValue)) >= 1.0e3
  //     ? Math.abs(Number(labelValue)) / 1.0e3 + "K"
  //     : Math.abs(Number(labelValue));
  // }

 topLable_fn(Value) {
  let labelValue = Math.abs(Number(Value))
  // Nine Zeroes for Billions
  return labelValue >= 1.0e+9

  ? Number((labelValue / 1.0e+9).toFixed(2)) + "B"
  // Six Zeroes for Millions 
  : labelValue >= 1.0e+6

  ? Number((labelValue / 1.0e+6).toFixed(2)) + "M"
  // Three Zeroes for Thousands
  : labelValue >= 1.0e+3

  ? Number((labelValue / 1.0e+3).toFixed(2)) + "K"

  : Number(labelValue.toFixed(2));
}
kenoupdatePlayerInfo=()=>{
 
  this.setState({ betsdeduction: 0})

}


  render() {
    // const roundNumber = this.props.balance - this.state.betsdeduction;
    const roundNumber = this.props.balance - this.state.betsdeduction
    console.log(this.state.betsdeduction)
    console.log(this.props.balance)
    // const betAmount = Math.round(roundNumber*100,2)/100
    const betAmount = (roundNumber);
    console.log(betAmount)
    let updatebal = this.topLable_fn((betAmount))
    console.log(updatebal);
    // let updatebal = this.topLable_fn(betAmount);
   let updatebets = this.topLable_fn(this.state.betAmounts);

    return (
      <Fragment>
        <div>
          <table className="betTable">
            <tbody>
              {(() => {
                const rows = [];
                for (let rowIndex = 0; rowIndex < 8; rowIndex++) {
                  const cells = [];
                  for (let colIndex = 0; colIndex < 10; colIndex++) {
                    const i = rowIndex * 10 + colIndex + 1;
                    const buttonClass = "kenobetpannel fill-color-mixed";
                    cells.push(
                      <td key={colIndex}>
                        {i <= 80 && (
                          <button
                            disabled={this.state.btn}
                            id={"bet_" + i}
                            onClick={() => this.handleBetAmountChange(i)}
                            className={buttonClass}
                          >
                            {i}
                          </button>
                        )}
                      </td>
                    );
                  }
                  rows.push(<tr key={rowIndex}>{cells}</tr>);
                }
                return rows;
              })()}
            </tbody>
          </table>

          <div>
            <img className="Tube" src={jar} />
            <div className="containerballs">{this.state.Animationballs}</div>
          </div>
          <div>
            <button
              id="betBtn"
              className={
                this.props.isMobile
                  ? "butbet emptyPointer"
                  : "butbet addCursorPointer"
              }
              disabled={this.state.betbtn}
              onClick={(e) => {
                e.preventDefault();
                this.sendBet();
              }}
            >
              <div className="text_overlaygr">
                <span className={this.state.betbuttonglow ? "textbetok" : ""}>
                  {this.props.language.BetOK}
                </span>
              </div>
              {this.state.betbuttonglow ? (
                <img className="betokBtn" src={betokglow} />
              ) : (
                <img
                  style={{ width: "250px", height: "100px" }}
                  src={betokbtn}
                />
              )}
            </button>
          </div>
          <div>
            <button
              id="doubledBtn"
              className={
                this.props.isMobile
                  ? "butdouble emptyPointer"
                  : "butdouble addCursorPointer"
              }
              disabled={this.state.doublebtn}
              onClick={(e) => {
                e.preventDefault();
                this.betAmountdouble();
              }}
            >
              <img src={buttons} style={{ width: "250px", height: "100px" }} />
              <div className="text_overlaydouble">
                <span>{this.props.language.Double}</span>
              </div>
            </button>
          </div>

          {/* <div>
            <button id="takeBtn" className={(this.props.isMobile) ? "buttake emptyPointer" : "buttake addCursorPointer"} disabled={this.state.disTakeBtn} onClick={(e) => { e.preventDefault(); this.sendTake(); }}>
              <img
                src={this.state.isTakeButtonClicked ? takebutton : this.props.winAmount > 0 || this.props.gameState.takeAmount > 0 ? takewin : takebutton}
                style={{
                  position: "relative",
                  width: this.state.isTakeButtonClicked ? "250px" : this.props.winAmount > 0 || this.props.gameState.takeAmount > 0 ? "250px" : "250px",
                  height: this.state.isTakeButtonClicked ? "100px" : this.props.winAmount > 0 || this.props.gameState.takeAmount > 0 ? "100px" : "100px",
                  animation: this.state.isTakeButtonClicked ? undefined : (this.props.winAmount > 0 || this.props.gameState.takeAmount > 0) ? "scaleUp 0.5s ease-in-out infinite alternate" : undefined,
                }}
                alt="Button Image"
              />

              <div className="text_overlayst" >
                <span className={!this.state.isTakeButtonClicked ? "addanimationtotext" : ""}>{this.props.language.Take}</span>
              </div>
            </button>
          </div> */}

          <div className="leftInfo">
            <div className="row">
              <div className="text_overlaywin">
                <span>{this.props.language.Score}</span>
              </div>
              <img
                src={winningpanel}
                style={{
                  width: "428px",
                  height: "212px",
                }}
              />
              <div className="text_overlaybalance" id="blnce">
                <span>{updatebal} </span>
              </div>
            </div>

            <div className="winner row">
              <div className="text_overlaybonus">
                <span>{this.props.language.Winner}</span>
              </div>
              <img className="betpannelbonus" src={BetPannels} />
              <div className="text_overlaywinning">
                <span>{this.state.winAmount}</span>
              </div>
            </div>

            <div className="betAmt row">
              <div className="text_overlaybetpannel">
                <span>{this.props.language.Bet}</span>
              </div>
              <div className="betBtnImg row ">
                <img
                  className="betpannel bet_cover"
                  id="bet_cover"
                  src={BetPannel}
                />
                <div className="col-2">
                  <button
                    disabled={this.state.plusbtn}
                    // onClick={(e) => { e.preventDefault();
                    //   this.betincreament();
                    // }}
                    onPointerDown={(e) => {
                      this.setState({
                        keyboardEnable: false,
                      });
                      this.betincreament();
                      e.preventDefault();
                      this.incrementIntervalId = setInterval(() => {
                        this.betincreament();
                      }, 150);
                    }}
                    onPointerUp={() => {
                      this.setState({
                        keyboardEnable: true,
                      });
                      clearInterval(this.incrementIntervalId);
                    }}
                    onPointerOut={() => {
                      this.setState({
                        keyboardEnable: true,
                      });
                      clearInterval(this.incrementIntervalId);
                    }}
                    onPointerLeave={() => {
                      this.setState({
                        keyboardEnable: true,
                      });
                      clearInterval(this.incrementIntervalId);
                    }}
                    className={
                      this.props.isMobile
                        ? "betplus emptyPointer"
                        : "betplus addCursorPointer"
                    }
                  >
                    <img className="incrBtn" src={plus} />
                  </button>
                </div>
                <div className="col-8">
                  <span className="text_overlaywinningbet">
                    {updatebets}
                  </span>
                </div>
                <div className="col-2">
                  <button
                    disabled={this.state.minusbtn}
                    // onClick={(e) => { e.preventDefault(); this.betdecreament(); }}
                    className={
                      this.props.isMobile
                        ? "betminus emptyPointer"
                        : "betminus addCursorPointer"
                    }
                    id="minusbtn"
                  >
                    <img
                      className="decrBtn"
                      src={minus}
                      onPointerDown={(e) => {
                        this.setState({
                          keyboardEnable: false,
                        });
                        this.betdecreament();
                        e.preventDefault();
                        this.decrementIntervalId = setInterval(() => {
                          this.betdecreament();
                        }, 150);
                      }}
                      onPointerUp={() => {
                        this.setState({
                          keyboardEnable: true,
                        });
                        clearInterval(this.decrementIntervalId);
                      }}
                      onPointerOut={() => {
                        this.setState({
                          keyboardEnable: true,
                        });
                        clearInterval(this.decrementIntervalId);
                      }}
                      onPointerLeave={() => {
                        this.setState({
                          keyboardEnable: true,
                        });
                        clearInterval(this.decrementIntervalId);
                      }}
                    />
                  </button>
                </div>
                <div className="kenoCover_betpannel" id="bet_coverpannel" />
              </div>
            </div>

            <div className="keno_InfoPanel row">
              <img className="keno_InfoPanelBg" src={ammusement} />
              <span className="keno_InfoTxt "> {this.state.infoText}</span>
            </div>
          </div>

          <div>
            <button
              id="clearBtn"
              className={
                this.props.isMobile
                  ? "clear emptyPointer"
                  : "clear addCursorPointer"
              }
              disabled={this.state.clearbtn}
              onClick={(e) => {
                e.preventDefault();
                this.clearSound.play();
                this.clearBet();
              }}
            >
              <img src={clearbtn} style={{ width: "250px", height: "100px" }} />
              <div className="text_overlayclear">
                <span>{this.props.language.Clear}</span>
              </div>
            </button>
          </div>
        </div>
      </Fragment>
    );
  }
}

const mapStatesToProps = (state) => {
  return {
    language: state.languageObjs.languageObj,
  };
};
export default connect(mapStatesToProps)(KenoTable);

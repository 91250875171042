import React from "react";
import "./UI/dragontiger.css";

import resultCards from "../../non_timer/funCard/UI/resultCards";
import GameBox from "./UI/gamebox";
// import Close from "./UI/close";
import duploseSound from "../../../../../assets/sounds/duplose.mp3";
import winSoundFile from "../../../../../assets/sounds/win.mp3";
import cardFallSound from "../../../../../assets/sounds/slide.mp3";
import betSoundFile from "../../../../../assets/sounds/bet.mp3";
import clearSoundFile from "../../../../../assets/sounds/clear.mp3";
import takeSoundFile from "../../../../../assets/sounds/take.mp3";
import exitSoundFile from "../../../../../assets/sounds/exit.mp3";
import betChipSnd from "../../../../../assets/sounds/betoncard.mp3";
// import staranimation from "../../../../../assets/games/d_up/staranimation.gif"
import dtTimerBg from "../../../../../assets/games/dragon_tiger/DT_BG.png";
import Close from './UI/close';
import { connect } from "react-redux";
import dragonsparkle from "../../../../js/games/sparkanim";
class DragonTigerTimer extends React.Component {
    // class DragonTigerTimer extends React.Component {
    constructor(props) {
        super(props);
        this.GameBox = React.createRef();
        this.resultCardsArray = [];

        this.state = {
            balanceLbl: 0,
            totalBetAmt: 0,
            betidReq: ["dr_id", "tr_id", "ti_id", "dbig_id", "dsml_id", "tbig_id", "tsml_id"],
            winAmount: 0,
            isBetSent: false,
            isGameActive: true,
            dt_isSpectSent: false,
            infoText: this.props.language.Play_Njoy,
            betBtnStatus: 0,
            betBtnTxt: this.props.language.Prev,
            totalBetAmount: 0,
            isstaranimation: false,
            betPanelIds: [],
            myBetAmt: 0,
            chipValue: 0,
            winCover: false,
            betAmount: 0,
            enableKeyboard: false,
            isResultShowing: false,
            selectedChipVal: 10,

        }
        this.innerLimits = {};
        this.dragonsparklemw = 1;
        if (document.getElementById("betBoxCover")) {
            //document.getElementById("betBoxCover").style.display = "block";
            // document.getElementById("betBoxCover").style.zIndex = 300;
        }
    }

    componentDidMount() {

        this.preloadSparkAnim();

        this.resetBet = {
            dragon: { dr: 0, dbig: 0, dsml: 0 },
            tie: { ti: 0 },
            tiger: { tr: 0, tbig: 0, tsml: 0 },
        };

        this.betObj = {
            dragon: { dr: 0, dbig: 0, dsml: 0 },
            tie: { ti: 0 },
            tiger: { tr: 0, tbig: 0, tsml: 0 },
        };

        this.undoArray = [];
        this.winSound = new Audio(winSoundFile);
        this.loseSound = new Audio(duploseSound);
        this.betSound = new Audio(betSoundFile);
        this.takeBtnSnd = new Audio(takeSoundFile);
        this.clearSound = new Audio(clearSoundFile);
        this.exitSound = new Audio(exitSoundFile);
        this.betChipSound = new Audio(betChipSnd);

        this.betimg = ["dr_img", "tr_img", "ti_img", "dbig_img", "dsml_img", "tbig_img", "tsml_img"];
        this.coinId = ["dr", "tr", "ti", "dbig", "dsml", "tbig", "tsml"];
        this.table_timeout1 = null;
        this.table_timeout2 = null;
        this.table_timeout3 = null;
        this.table_timeout4 = null;
        this.table_timeout5 = null;
        this.table_timeout6 = null;
        this.table_timeout7 = null;
        this.table_timeout8 = null;
        this.table_timeout9 = null;
        this.table_timeout10 = null;
        this.table_timeout11 = null;
        this.table_timeout12 = null;
        this.table_timeout13 = null;
        this.table_timeout14 = null;
        this.table_timeout15 = null;

        if (this.props.dtt_chipValues) {
            let newChips = this.props.dtt_chipValues.split(",");
            console.log(newChips[0]);
            let initChipVal = newChips[0];
            let lblTxt;
            if (initChipVal >= 1000 && initChipVal <= 999999) {
                lblTxt = "K"
            } else if (initChipVal >= 1000000 && initChipVal <= 999999999) {
                lblTxt = "M"
            } else if (initChipVal > 999999999) {
                lblTxt = "B"
            }

            this.state.selectedChipVal = initChipVal;
        }

        if (this.props.isIphone) {
            document.getElementById('iospopup').style.display = 'block';
        } else {
            document.getElementById('iospopup').style.display = 'none';
        }

        // document.getElementById("dt_exitBtn").disabled = true;
        // this.disableBtn("exit_img");
        this.table_timeout13 = setTimeout(() => {
            // document.getElementById("dt_exitBtn").disabled = false;
            this.enableBtn("exit_img");
            if (!this.state.isResultShowing) {
                this.setState({
                    enableKeyboard: true
                })
            }
            document.addEventListener("keyup", this.dt_handleKeyboardEvent);
        }, 1000);


        if (this.props.dt_innerLimits) {
            this.innerLimits = this.props.dt_innerLimits.split("|");
            for (let t = 0; t < this.innerLimits.length; t++) {
                this.innerLimits[t] = this.innerLimits[t].split("-");
            }
        }

        if (document.getElementById("betBoxCover")) {
            document.getElementById("betBoxCover").style.display = "block";
        }

        this.setState({
            balanceLbl: this.props.balance,
            winCover: true
        })
        // this.getAllIds();

        this.disableBtn("dealBtn");
        this.disableBtn("unDoBtn");
        this.disableBtn("clrBtn");
        this.disableBtn("doubleBtn");

        this.resutlsTarget = "";
        this.targetGameState = "";
        this.winBetArray = [];
        this.allIds = [];


        if (this.props.gameState.handId != undefined) {
            if (this.props.gameState.handId == this.props.gameState.oldHandId) {
                document.getElementById("betBoxCover").style.display = "block";
                this.setState({
                    betBtnStatus: 1,
                    betBtnTxt: this.props.language.BetOK,
                    isBetSent: true,
                    disBetBtn: 1,
                    disClearBtn: 1,
                    disDoubleBtn: 1,
                    winCover: true,
                    infoText: this.props.language.BetAccepted + this.props.gameState.betRequest.betAmount,
                    totalBetAmt: this.props.gameState.betRequest.betAmount,
                    totalBetAmount: this.props.gameState.betRequest.betAmount
                })

                this.updateBetString();

                for (let j = 0; j < this.coinId.length; j++) {
                    let betId = this.coinId[j];
                    document.getElementById(betId).innerHTML = `<div> <p class="goldCoin_dr" } /> </div>`;
                    switch (betId) {
                        case "dr":
                        case "dbig":
                        case "dsml":
                            if (this.betObj.dragon[betId] > 0) {
                                let num_id = betId + "_id";
                                let dr_id_bet = this.convertBetValue(this.betObj.dragon[betId]);
                                document.getElementById(betId).innerHTML = `<div> <p class="goldCoin_dr" } /> <span class="t_Count" id=${num_id}> ${dr_id_bet} </span </div>`;
                            }
                            break;
                        case "ti":
                            if (this.betObj.tie[betId] > 0) {
                                let num_id = "ti_id";
                                let ti_id_bet = this.convertBetValue(this.betObj.tie[betId]);
                                document.getElementById(betId).innerHTML = `<div> <p class="goldCoin_dr" } /> <span class="t_Count" id=${num_id}> ${ti_id_bet} </span </div>`;
                            }
                            break;
                        case "tr":
                        case "tbig":
                        case "tsml":
                            if (this.betObj.tiger[betId] > 0) {
                                let num_id = betId + "_id";
                                let tr_id_bet = this.convertBetValue(this.betObj.tiger[betId]);
                                document.getElementById(betId).innerHTML = `<div> <p class="goldCoin_dr" } /> <span class="t_Count" id=${num_id}> ${tr_id_bet} </span </div>`;
                            }
                            break;
                    }
                }

            } else {

                this.setState({
                    betBtnStatus: 0,
                    betBtnTxt: this.props.language.Prev,
                    disDoubleBtn: 0,
                    disClearBtn: 0,
                    disZoomBtn: 0,
                    winCover: false,
                });
                if (this.props.gameState.betRequest == undefined) {
                    this.setState({ disBetBtn: 1 });
                } else {
                    this.setState({ disBetBtn: 0 });
                    this.enableBtn("dealBtn")
                }

                if (document.getElementById("betBoxCover")) {
                    document.getElementById("betBoxCover").style.display = "none";
                }
            }

        } else {
            if (this.props.gameState.betRequest) {
                this.setState({ disBetBtn: 0 });
            } else {
                this.setState({ disBetBtn: 1 });
            }

            this.setState({
                betBtnStatus: 0,
                betBtnTxt: this.props.language.Prev,
                disDoubleBtn: 0,
                disClearBtn: 0,
                disZoomBtn: 0,
                winCover: false,
            });
            console.log(this.state.betString.length);

            console.log(document.getElementById("betBoxCover"))

            if (document.getElementById("betBoxCover")) {
                document.getElementById("betBoxCover").style.display = "none";
            }
        }

        if (this.props.gameState && this.props.gameState.history) {
            this.update_DT_GameState();
        }
        // if (this.props.gameState.handId) {
        //     this.handId = this.props.gameState.handId;
        // }

        // const { childRefDT } = this.props;
        // childRefDT(this);

        const { childRef } = this.props;
        childRef(this);
    }

    componentWillUnmount() {
        document.removeEventListener("keyup", this.dt_handleKeyboardEvent);
        if (document.getElementById("betBoxCover")) {
            document.getElementById("betBoxCover").style.display = "none";
        }
        this.setState({
            enableKeyboard: false,
            winCover: false
        })
    }

    preloadSparkAnim() {
        let loadedSparkImages = 0;
        // Preload each image
        for (let n = 1; n <= 100; n++) {
          let spark_img = new Image();
          if (loadedSparkImages !== 100) {
            loadedSparkImages++;
            spark_img.onload = loadedSparkImages;
            spark_img.onerror = loadedSparkImages; // Call imageLoaded even if there's an error loading the image
            const sparkImageName =  `sparkanimation${n}`;
            spark_img.src = dragonsparkle[sparkImageName];
            console.log(spark_img.src);
          }
        }
      }

    disableBtn(btnId) {
        let disBtn = document.getElementById(btnId);
        if (disBtn) {
            disBtn.classList.add("disBtn");
            disBtn.disabled = true;
        }
    }

    enableBtn(btnId) {
        let enableBtn = document.getElementById(btnId);
        if (enableBtn) {
            enableBtn.classList.remove("disBtn");
            enableBtn.disabled = false;
            enableBtn.style.zIndex = "150";
        }
    }

    dragonTigerResults(gameResult) {

        this.setState({ isResultShowing: true, dt_isSpectSent: false, enableKeyboard: false, winCover: true })

        if (document.getElementById("betBoxCover")) {
            document.getElementById("betBoxCover").style.display = "block";
            document.getElementById("betBoxCover").style.zIndex = 300;
        }
        this.resutlsTarget = gameResult;
        this.getResults(gameResult);
        this.setState({ isBetSent: false });


        if (this.state.totalBetAmt == 0 && gameResult.winAmount > 0) {
            //    alert(" 0000000000000000000000000000000000  ");
            this.setState({
                winCover: true,
                totalBetAmt: this.props.gameState.betRequest.betAmount,
                totalBetAmount: this.props.gameState.betRequest.betAmount
            })

            // document.getElementById("betBoxCover").style.display = "block";
            // document.getElementById("betBoxCover").style.zIndex = 99;

            this.enableBtn("dealBtn");
            this.disableBtn("unDoBtn");
            this.disableBtn("clrBtn");

            this.updateBetString();

            console.log(this.betObj);

            for (let j = 0; j < this.coinId.length; j++) {
                let betId = this.coinId[j];
                document.getElementById(betId).innerHTML = `<div> <p class="goldCoin_dr" } /> </div>`;
                switch (betId) {
                    case "dr":
                    case "dbig":
                    case "dsml":
                        if (this.betObj.dragon[betId] > 0) {
                            let num_id = betId + "_id";
                            let dr_id_bet2 = this.convertBetValue(this.betObj.dragon[betId]);
                            document.getElementById(betId).innerHTML = `<div> <p class="goldCoin_dr" } /> <span class="t_Count" id=${num_id}> ${dr_id_bet2} </span </div>`;
                        }
                        break;
                    case "ti":
                        if (this.betObj.tie[betId] > 0) {
                            let num_id = "ti_id";
                            let ti_id_bet2 = this.convertBetValue(this.betObj.tie[betId]);
                            document.getElementById(betId).innerHTML = `<div> <p class="goldCoin_dr" } /> <span class="t_Count" id=${num_id}> ${ti_id_bet2} </span </div>`;
                        }
                        break;
                    case "tr":
                    case "tbig":
                    case "tsml":
                        if (this.betObj.tiger[betId] > 0) {
                            let num_id = betId + "_id";
                            let tr_id_bet2 = this.convertBetValue(this.betObj.tiger[betId]);
                            document.getElementById(betId).innerHTML = `<div> <p class="goldCoin_dr" } /> <span class="t_Count" id=${num_id}> ${tr_id_bet2} </span </div>`;
                        }
                        break;
                }
            }
        }
    }

    update_DT_GameState(newGameState) {
        console.log("DragTiger Game State ");
        console.log(this.props.gameState.history);
        console.log(">>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> ");

        this.targetGameState = this.props.gameState;
        this.GameBox.updateHistory(this.props.gameState.history);
        console.log(this.targetGameState);
    }

    dragonTigerPlayerInfo() {        
        console.log("DTT: Update Palyer Balance")
        this.setState({ balanceLbl: this.props.balance });
    }

    updateMessage(erroCode) {
        console.log("Update messages " + this.props.infoText);
        this.setState({ infoText: this.props.infoText });
        //console.log(erroCode);
        if (erroCode == "game.bet.invalid") {
            this.updateBetString();
        } else if (erroCode == "game.bet.accepted") {
            console.log(this.state.totalBetAmt) //Don't delete
            this.setState({ isBetSent: true });
        }
    }

    dt_GetTime() {
        if (this.props.dt_timeSend) {
            console.log("*************  -----------------------  ***************************");
            console.log(this.props.dt_timeSend);
            console.log("*************  -----------------------  ***************************");
            // console.log(this.props.dt_timeSend);
            // console.log(GameBox);
            if (this.GameBox) {
                this.GameBox.sendTimer(this.props.dt_timeSend);
            }
        }
    }

    exitGame() {
        this.setState({ isGameActive: false, enableKeyboard: false });
        console.log("Exit Dragon Tiger Game. ")
        document.removeEventListener("keyup", this.dt_handleKeyboardEvent);
        console.log(this.GameBox);
        if (this.GameBox) {
            this.GameBox.clearTimer_fn();
        }
    }

    updateBetString() {
        console.log("Game State betstring: ")
        if (this.props.gameState && this.props.gameState.betRequest && this.props.gameState.betRequest.betString) {
            console.log(this.props.gameState.betRequest.betString);
            let newStr = this.props.gameState.betRequest.betString.replace(/,/g, "|");
            let newStr1 = newStr.replace(/ /g, "0");
            //console.log(newStr1)
            let newStr2 = newStr1.split('|');
            //console.log(newStr2);            

            for (let z = 0; z < newStr2.length; z++) {
                switch (z) {
                    case 0:
                        this.betObj.dragon["dr"] = Number(newStr2[0]);
                        break;
                    case 1:
                        this.betObj.tiger["tr"] = Number(newStr2[1]);
                        break;
                    case 2:
                        this.betObj.tie["ti"] = Number(newStr2[2]);
                        break;
                    case 3:
                        this.betObj.dragon["dbig"] = Number(newStr2[3]);
                        break;
                    case 4:
                        this.betObj.dragon["dsml"] = Number(newStr2[4]);
                        break;
                    case 5:
                        this.betObj.tiger["tbig"] = Number(newStr2[5]);
                        break;
                    case 6:
                        this.betObj.tiger["tsml"] = Number(newStr2[6]);
                        break;
                    default:
                        break;
                }
            }

            console.log(this.betObj);
        }
    }

    dt_handleKeyboardEvent = (evt) => {
        console.log(evt);
        console.log(evt.code);
        if (this.state.isGameActive) {
            switch (evt.code) {
                case "Enter":
                case "NumpadEnter":
                    //console.log(this.props.gameState);
                    //V1 change condition with buttonStaus number.
                    if (this.state.betBtnStatus == 1 && this.state.enableKeyboard) {
                        if (document.getElementById("dt_timeSpan") && document.getElementById("dt_timeSpan").innerHTML > 5) {
                            if (document.getElementById("dealBtn") && !document.getElementById("dealBtn").disabled && Number(document.getElementById("betAmt").innerHTML.split(": ")[1]) > 0) {
                                this.betSound.play();
                                console.log("<----- Bet OK --->")
                                this.deal();
                                break;
                            }
                        }
                    } else if (this.state.betBtnStatus == 0 && this.state.enableKeyboard) {
                        if (!document.getElementById("dealBtn").disabled && this.props.gameState.betRequest && this.props.gameState.betRequest.betAmount > 0) {
                            this.betSound.play();
                            this.rebetFun();
                            break;
                        }
                    }
                    break;
                case "KeyC":
                    if (document.getElementById("dt_timeSpan").innerHTML > 5 && this.state.betBtnStatus == 1 && this.state.enableKeyboard) {
                        if (document.getElementById("dealBtn") && !document.getElementById("dealBtn").disabled) {
                            if (document.getElementById('iospopup').style.display == "none") {
                                this.clearSound.play();
                            }

                            //console.log("Clear");  
                            this.disableBtn("dealBtn");
                            this.disableBtn("unDoBtn");
                            this.disableBtn("clrBtn");

                            document.getElementById("dealBtn").classList.remove("dr_bt_active");
                            this.cleareBets();
                            //this.betObj = this.initBet;
                            this.setState({ undoArray: [] });
                        }
                    }
                    break;
                // case 'Escape':
                //     //console.log('Esc');
                //     if (document.getElementById("dt_exitBtn").disabled == false) {
                //         document.removeEventListener("keyup", this.dt_handleKeyboardEvent);
                //         this.exitSound.play();
                //         this.closeGame();
                //     }
                //     break;
                default:
                    break;
            }
        }
    }

    dragonsparkleanimat = () => {
        this.dragonsparklemw++;
        if (this.dragonsparklemw < 101) {
            const sparkledragon = `sparkanimation${this.dragonsparklemw}`;
            //console.log(sparkledragon)
            const starssprinklemw = dragonsparkle[sparkledragon];
            //console.log(starssprinklemw)
            if (document.getElementById("dragon_sprkl")) {
                document.getElementById("dragon_sprkl").src = starssprinklemw
            }
        }
        else {
            this.dragonsparklemw = 1;
            clearInterval(this.dragponsparkl)
            //document.getElementById("sparkID").src = ""
        }
    }

    getResults(resultRes) {
        if (document.getElementById("betBoxCover")) {
            document.getElementById("betBoxCover").style.display = "block";
            document.getElementById("betBoxCover").style.zIndex = 300;
        }
        //console.log(resutlsTarget)
        this.resultCardsArray = [];
        this.winBetArray = [];
        this.setState({ enableKeyboard: false })
        this.removeGlow();

        this.resultCardsArray.push(resultRes.dragonCards, resultRes.tigerCards);
        for (let r = 0; r < resultRes.rs.length; r++) {
            this.winBetArray.push(resultRes.rs[r])
        }
        this.showCards();
    }

    showCards() {
        this.setState({ enableKeyboard: false, winCover: true });
        this.state.enableKeyboard = false;
        if (document.getElementById("betBoxCover")) {
            document.getElementById("betBoxCover").style.display = "block";
            document.getElementById("betBoxCover").style.zIndex = 300;
        }

        var resImg = [];
        let cardSound = new Audio(cardFallSound);
        let cardSound2 = new Audio(cardFallSound);
        if (this.state.isGameActive) {
            if (document.getElementById('iospopup').style.display == "none") {
                cardSound.play();
            }

            this.table_timeout2 = setTimeout(() => {
                if (document.getElementById('iospopup').style.display == "none") {
                    cardSound2.play();
                }

            }, 700);
        }

        for (let i = 0; i < this.resultCardsArray.length; i++) {
            for (let a = 0; a < resultCards.length; a++) {
                if (this.resultCardsArray[i] == resultCards[a].rsc_val) {
                    resImg.push(resultCards[a].src);
                }
            }
        }

        if (resImg != '') {
            if (document.getElementById("betBoxCover")) {
                document.getElementById("betBoxCover").style.display = "block";
                document.getElementById("betBoxCover").style.zIndex = 300;
            }
            let dragonWinCard = document.getElementById('dragonWinCard');
            let tigerWinCard = document.getElementById('tigerWinCard');
            this.table_timeout3 = setTimeout(() => {
                if (dragonWinCard) {
                    dragonWinCard.innerHTML = `<img class="dragon_card" src=${resImg[0]} />`
                }
            }, 50)
            this.table_timeout4 = setTimeout(() => {
                if (tigerWinCard) {
                    tigerWinCard.innerHTML = `<img class="tiger_card" src=${resImg[1]} />`
                }
            }, 500)
        }
        this.table_timeout5 = setTimeout(() => {
            this.winBoxHighilights();
        }, 1500)
    }

    /*********************** WIN BOXES HIGHILIGHTS *********************/
    winBoxHighilights() {
        if (this.resutlsTarget.winAmount > 0) {
            if (this.state.isGameActive) {
                if (document.getElementById('iospopup').style.display == "none") {
                    this.winSound.play();
                }

                this.setState({ isstaranimation: true })
            }
            this.setState({
                infoText: this.props.language.WonHand,
                winAmount: this.resutlsTarget.winAmount,
                winCover: true,
            });

            if (document.getElementById("betBoxCover")) {
                document.getElementById("betBoxCover").style.zIndex = 99;
            }
            this.table_timeout7 = setTimeout(() => {

                let pinfo_body = {
                    CN: "GET_PLAYER_INFO",
                    object: {
                        gameId: Number(this.props.gameId),
                        playerId: Number(this.props.playerId),
                    },
                    sessionId: sessionStorage.getItem('sessionId'),
                };
                this.props.network.sendof(pinfo_body);


                this.setState({
                    enableKeyboard: true,
                    isResultShowing: false
                })
                this.setState({ infoText: this.props.language.Play_Njoy })

                if (document.getElementById("betBoxCover")) {
                    document.getElementById("betBoxCover").style.display = "none";
                    document.getElementById("betBoxCover").style.zIndex = 99;
                }
                // this.TakeAmount_fn();

                let dragonWinCard = document.getElementById('dragonWinCard');
                let tigerWinCard = document.getElementById('tigerWinCard');
                if (dragonWinCard) {
                    dragonWinCard.innerHTML = ``
                }
                if (tigerWinCard) {
                    tigerWinCard.innerHTML = ``
                }

                this.betObj.dragon["dr"] = 0;
                this.betObj.dragon["dbig"] = 0;
                this.betObj.dragon["dsml"] = 0;
                this.betObj.tie["ti"] = 0;
                this.betObj.tiger["tr"] = 0;
                this.betObj.tiger["tbig"] = 0;
                this.betObj.tiger["tsml"] = 0;

                this.setState({
                    betBtnStatus: 0,
                    betBtnTxt: this.props.language.Prev,
                    infoText: this.props.language.Play_Njoy,
                    totalBetAmount: 0,
                    totalBetAmt: 0,
                    winCover: false,
                    winAmount: 0,
                    undoArray: [],
                });
                this.enableBtn("dealBtn");

                for (let j = 0; j < this.coinId.length; j++) {
                    let betId = this.coinId[j];
                    document.getElementById(betId).innerHTML = `<div> <p class="goldCoin_dr" } /> </div>`;

                    if (document.getElementById((betId + "_img")) && document.getElementById((betId + "_img")).classList.contains("active_win")) {
                        document.getElementById(betId + "_img").classList.remove("active_win");
                    }
                }
                if (this.props.isPlayerBanned) {
                    this.closeGame();
                    this.props.player_banned();
                }

                //console.log("gameUnderMaintanance " + props.gameUnderMaintanance);
                if (this.props.gameUnderMaintanance) {
                    this.closeGame();
                    this.props.game_maintanance("Game under maintenance, reach to admin.");
                }

                //console.log("deviceBlocked " + props.isdeviceBlocked);
                if (this.props.isdeviceBlocked) {
                    this.closeGame();
                    this.props.device_blocked();
                }
            }, 2500)

            if (this.state.isGameActive) {
                if (document.getElementById("winPop")) {
                    document.getElementById("winPop").style.display = "block"
                    // document.getElementById("sparkAnim").src = {staranimation}
                    this.dragponsparkl = setInterval(this.dragonsparkleanimat, 35);
                }
                this.table_timeout9 = setTimeout(() => {
                    if (document.getElementById("winPop")) {
                        document.getElementById("winPop").style.display = "none";
                    }
                }, 2000);

                this.table_timeout15 = setTimeout(() => {
                    //this.enableBtn("dealBtn");
                    // if (document.getElementById("dealBtn")) {
                    //     document.getElementById("dealBtn").classList.add("dr_bt_active");
                    // }
                    
                }, 3000);

                this.table_timeout10 = setTimeout(() => {
                    this.setState({ isstaranimation: false });
                }, 1700);
            }

        } else {
            if (this.state.isGameActive) {
                if (document.getElementById('iospopup').style.display == "none") {
                    this.loseSound.play();
                }                
            }
            this.setState({ infoText: this.props.language.Play_Njoy });

            this.disableBtn("unDoBtn");

            if (this.props.gameState.betRequest && this.props.gameState.betRequest.betAmount > 0 && this.props.gameState.betRequest.betString) {

            } else {
                this.disableBtn("dealBtn");
            }

            if (document.getElementById("dealBtn")) {
                document.getElementById("dealBtn").classList.remove("dr_bt_active");
            }

            this.table_timeout11 = setTimeout(() => {

                this.cleareBets();
                this.setState({
                    betBtnStatus: 0,
                    betBtnTxt: this.props.language.Prev,
                    enableKeyboard: true,
                    winCover: false,
                    isResultShowing: false
                });
                if (document.getElementById("betBoxCover")) {
                    document.getElementById("betBoxCover").style.zIndex = 99;
                    document.getElementById("betBoxCover").style.display = "none";
                }
                if (this.props.gameState.betRequest && this.props.gameState.betRequest.betAmount > 0 && this.props.gameState.betRequest.betString) {
                    this.enableBtn("dealBtn");
                }
            }, 1000);

            this.setState({totalBetAmount: 0 });

            let body2 = {
                CN: "GET_PLAYER_INFO",
                object: {
                    gameId: Number(this.props.gameId),
                    playerId: Number(this.props.playerId),
                },
                sessionId: sessionStorage.getItem('sessionId'),
            };
            this.props.network.sendof(body2);
        }
        let body = {
            sessionId: sessionStorage.getItem('sessionId'),
            CN: "GET_GAME_STATE",
            object: {
                playerId: Number(this.props.playerId),
                gameId: this.props.gameId,
            },
        };
        this.props.network.sendof(body);

        for (let a = 0; a < this.winBetArray.length; a++) {
            if (document.getElementById((this.winBetArray[a] + "_img"))) {
                document.getElementById((this.winBetArray[a] + "_img")).classList.add("active_win");
            }
        }
    }

    /**************************** YOUR BET AMOUNT ON WIN BOX *************************/
    winBoxBetAmount(ids, vals) {
        let id = ids;
        let val = vals;
        for (let i = 0; i < id.length; i++) {
            if (val[i] > 0) {
                if (document.getElementById(id[i])) {
                    let num_id6 = id[i] + "_id";
                    let win_amt = this.convertBetValue(val[i]);
                    document.getElementById(id[i]).innerHTML = `<div> <p class="goldCoin_dr" } /> <span class="t_Count" id=${num_id6}> ${win_amt} </span </div>`
                }
            }
        }
    }

    /****************************** GETTING ALL IDS OF BET PANEL **********************/
    getAllIds() {
        this.allIds = this.state.betPanelIds;

        let betIdObj = {};
        betIdObj = this.betObj;

        for (let i = 0; i < Object.getOwnPropertyNames(betIdObj.dragon).length; i++) {
            let dragonIds = document.getElementById(Object.getOwnPropertyNames(betIdObj.dragon)[i]);
            this.allIds.push(dragonIds.id);

            let tigerIds = document.getElementById(Object.getOwnPropertyNames(betIdObj.tiger)[i]);
            this.allIds.push(tigerIds.id);
        }
        let tieIds = document.getElementById(Object.getOwnPropertyNames(betIdObj.tie)[0]);
        if (this.state.betPanelIds.length < 7) {
            this.allIds.push(tieIds.id);
        }
    };


    /************************ UNDO BET BUTTON *****************************/
    undoFun = () => {
        console.log(this.undoArray);
        console.log("undoArray.length: " + this.undoArray.length,this.undoArray.length !== 0);
        this.setState({ infoText: this.props.language.Play_Njoy });
        if (this.undoArray.length == 1) {
            this.disableBtn("unDoBtn");
        }
        if (this.undoArray.length !== 0) {
            let undoLog = this.undoArray;
            let resObj = undoLog.at(-1);
            console.log(resObj);
            undoLog.splice(-1);
            //console.log(Object.keys(resObj)[0])
            //console.log(Object.values(resObj)[0])
            let id = Object.keys(resObj)[0];
            let val = Number(Object.values(resObj)[0]);
            this.undoBet(id, val);
        } else {
            if (this.props.gameState.betRequest && this.props.gameState.betRequest.betAmount > 0 && this.props.gameState.betRequest.betString) {
                this.setState({
                    betBtnStatus: 0,
                    betBtnTxt: this.props.language.Prev,
                });
                this.enableBtn("dealBtn");
            }
            this.disableBtn("doubleBtn");
        }
    };
    /************************ UNDO BET FUNCTIONALITY *****************************/
    undoBet(id, val) {
        let idName = id;
        let undoBetObj = this.betObj;
        let receiveVal = Number(Number(val).toFixed(2))
        this.setState({
            totalBetAmount: Number((Number(this.state.totalBetAmt) - receiveVal).toFixed(2)), betAmount: Number((Number(this.state.totalBetAmt) - receiveVal).toFixed(2))
        })
        console.log((Number(this.state.totalBetAmt) - receiveVal) == 0, Number(this.state.totalBetAmt) , receiveVal)
        if ((Number(this.state.totalBetAmt) - receiveVal) == 0) {
            this.disableBtn("unDoBtn");
            this.disableBtn("dealBtn");
            this.disableBtn("clrBtn");
            this.disableBtn("doubleBtn");
            if (this.props.gameState.betRequest && this.props.gameState.betRequest.betAmount > 0 && this.props.gameState.betRequest.betString) {
                this.setState({
                    betBtnStatus: 0,
                    betBtnTxt: this.props.language.Prev,
                });
                this.enableBtn("dealBtn");
            }
            document.getElementById("dealBtn").classList.remove("dr_bt_active");
        }

        for (let i = 0; i < Object.getOwnPropertyNames(undoBetObj.dragon).length; i++) {
            if (Object.getOwnPropertyNames(undoBetObj.dragon)[i] == id) {
                let oldAndNewDragon = Number((Number(undoBetObj.dragon[idName]) - receiveVal).toFixed(2));
                console.log(oldAndNewDragon)
                undoBetObj.dragon[idName] = Number((Math.round(oldAndNewDragon * 100) / 100).toFixed(2));

                if (undoBetObj.dragon[idName] != 0) {
                    let num_id7 = id + "_id";
                    let dr_und_bet = this.convertBetValue(undoBetObj.dragon[idName]);

                    document.getElementById(id).innerHTML = `<div> <p class="goldCoin_dr" } /> <span class="t_Count" id=${num_id7}> ${dr_und_bet} </span </div>`;
                } else {
                    document.getElementById(id).innerHTML = `<div><p class="goldCoin_dr">  </div>`;
                }
            } else if (Object.getOwnPropertyNames(undoBetObj.tie)[i] == id) {
                let oldAndNewTie = Number((Number(undoBetObj.tie[idName]) - receiveVal));
                undoBetObj.tie[idName] = Number((Math.round(oldAndNewTie * 100) / 100).toFixed(2));

                if (undoBetObj.tie[idName] != 0) {
                    let num_id8 = id + "_id";
                    let ti_und_bet = this.convertBetValue(undoBetObj.tie[idName]);
                    document.getElementById(id).innerHTML = `<div> <p class="goldCoin_dr" } /> <span class="t_Count" id=${num_id8}> ${ti_und_bet} </span </div>`;
                } else {
                    document.getElementById(id).innerHTML = `<div><p class="goldCoin_ar">  </div>`;
                }
            } else if (Object.getOwnPropertyNames(undoBetObj.tiger)[i] == id) {
                let oldAndNewTiger = Number((Number(undoBetObj.tiger[idName]) - receiveVal).toFixed(2));
                undoBetObj.tiger[idName] = Number((Math.round(oldAndNewTiger * 100) / 100).toFixed(2));
                console.log(Number(undoBetObj.tiger[idName]) , receiveVal,   oldAndNewTiger , undoBetObj.tiger[idName])
                if (undoBetObj.tiger[idName] != 0) {
                    let num_id9 = id + "_id";
                    let tr_und_bet = this.convertBetValue(undoBetObj.tiger[idName]);
                    document.getElementById(id).innerHTML = `<div> <p class="goldCoin_dr" } /> <span class="t_Count" id=${num_id9}> ${tr_und_bet} </span </div>`;
                } else {
                    document.getElementById(id).innerHTML = `<div><p class="goldCoin_ar">  </div>`;
                }
            }
        }

        this.betObj = undoBetObj;
        this.setState({ totalBetAmt:Number((Number(this.state.totalBetAmt) - receiveVal).toFixed(2)) , totalBetAmount:Number((Number(this.state.totalBetAmt) - receiveVal).toFixed(2)) });
    }


    stopBetSound() {
        // this.betChipSound.currentTime = 0;
        // this.betChipSound.pause();

        this.setState({ enableKeyboard: true });
        this.state.enableKeyboard = true;
    }

    chipValue(chipVal) {
        this.setState({
            chipValue: Number(chipVal)
        })

    }

    convertBetValue(chngAmt) {
    
        let newAmt = Number((chngAmt).toFixed(2));
        if(newAmt >= 1000 && newAmt <=999999){
          newAmt = Number((newAmt / 1000).toFixed(2))+"K";      
        }else if(newAmt >= 1000000 && newAmt <=999999999){
          newAmt = Number((newAmt / 1000).toFixed(2))+"M";    
        }else if(newAmt >999999999){
          newAmt = Number((newAmt / 1000).toFixed(2))+"B";      
        }
        return newAmt;
    }

    changeAmtlabl(chngAmt){
      }

    /************************ BET ONCLICK ON BET BOX *****************************/

    getBetBoxId(idName) {
        this.setState({ enableKeyboard: false });
        this.state.enableKeyboard = false;

        // console.log(" --------------------------***********************----------------------------------- ")
        if (this.state.totalBetAmount == 0) {
            for (let j = 0; j < this.coinId.length; j++) {
                let betId = this.coinId[j];
                if (document.getElementById((betId + "_img")) && document.getElementById((betId + "_img")).classList.contains("active_win")) {
                    document.getElementById(betId + "_img").classList.remove("active_win");
                }
            }
        }

        // console.log(this.state.chipValue);
        // console.log(this.resetBet);
        // console.log(this.betObj);
        let currentBtnAmt = 0;

        for (let s = 0; s < 3; s++) {
            currentBtnAmt += Number(Object.values(this.betObj.tiger)[s]);
            currentBtnAmt += Number(Object.values(this.betObj.dragon)[s]);
        }
        currentBtnAmt += Number(Object.values(this.betObj.tie)[0]);

        console.log(currentBtnAmt);
        console.log(this.state.totalBetAmount);
        console.log(this.props.maxBetAmt);
        this.setState({ infoText: this.props.language.MinBetAmt + " " + this.props.minBetLbl + " " + this.props.language.MaxBetAmt + " " + this.props.maxBetLbl });

        // this.setState({
        //     infoText: this.props.language.Min_Bet + " " + this.props.minBetAmt + " " + this.props.language.Max_Bet + " " + this.props.maxBetAmt
        // })


        if (currentBtnAmt + Number(this.state.chipValue) <= this.props.balance) {
            console.log((currentBtnAmt + Number(this.state.chipValue) <= this.props.maxBetAmt))
            if (currentBtnAmt + Number(this.state.chipValue) <= this.props.maxBetAmt) {
                this.betChipSound.currentTime = 0;
                this.betChipSound.play();

                console.log(this.state.totalBetAmount);
                console.log(this.state.chipValue);

                let betAmt = this.state.totalBetAmount;
                let chipVal = this.state.chipValue;
                let totbetAmt = Number(betAmt) + Number(chipVal);
                console.log(totbetAmt);

                console.log("Total Bet Amount: " + this.state.totalBetAmt)
                console.log("currentBtnAmt: " + (Number(currentBtnAmt) + Number(this.state.chipValue)))

                this.setState({balanceLbl: (this.props.balance - (Number(currentBtnAmt) + Number(this.state.chipValue)))})

                if (Number(currentBtnAmt) + Number(this.state.chipValue) > 0) {
                    console.log(this.state.betBtnStatus)
                    if (this.state.betBtnStatus != 1) {
                        this.setState({
                            betBtnStatus: 1,
                            betBtnTxt: this.props.language.BetOK,
                        });
                        this.enableBtn("dealBtn");
                        if (document.getElementById("dealBtn")) {
                            document.getElementById("dealBtn").classList.add("dr_bt_active");
                        }
                    }
                    if (document.getElementById("unDoBtn") && document.getElementById("unDoBtn").disabled) {
                        this.enableBtn("unDoBtn");
                    }
                    if (document.getElementById("clrBtn") && document.getElementById("clrBtn").disabled) {
                        this.enableBtn("clrBtn");
                    }
                    if (document.getElementById("doubleBtn") && document.getElementById("doubleBtn").disabled) {
                        this.enableBtn("doubleBtn");
                    }
                }

                let inrMaxLmt;

                switch (idName) {
                    case "dr":
                    case "tr":
                        inrMaxLmt = this.innerLimits[0][1];
                        break;
                    case "ti":
                        inrMaxLmt = this.innerLimits[1][1];
                        break;
                    case "dbig":
                    case "tbig":
                    case "dsml":
                    case "tsml":
                        inrMaxLmt = this.innerLimits[2][1];
                        break;
                }

                let betArray = {};
                betArray = this.betObj;

                for (let i = 0; i < Object.getOwnPropertyNames(this.betObj.dragon).length; i++) {

                    if (Object.getOwnPropertyNames(this.betObj.dragon)[i] == idName) {

                        let checkVal = Number(this.betObj.dragon[idName]);

                        if ((Number(checkVal) + Number(this.state.chipValue)) <= this.props.maxBetAmt) {
                            if (Number(this.betObj.dragon[idName] + Number(this.state.chipValue) <= Number(inrMaxLmt))) {
                                betArray.dragon[idName] = (Number(this.betObj.dragon[idName]) + Number(this.state.chipValue));
                                checkVal = Number(this.betObj.dragon[idName]);

                                console.log("checkVal: " + checkVal);

                                // if (Number(checkVal) >= 1000 && Number(checkVal) <= 999999) {
                                //     checkVal = checkVal / 1000 + "K";
                                //     console.log(checkVal);
                                //   } else if (checkVal >= 1000000 && checkVal <= 999999999) {
                                //     checkVal = checkVal / 1000 + "M";
                                //     console.log(checkVal);
                                //   } else if (checkVal > 999999999) {
                                //     checkVal = checkVal / 1000 + "B";
                                //     console.log(checkVal);
                                //   }

                                checkVal = this.convertBetValue(checkVal);


                                this.setState({ totalBetAmount: this.state.betAmount, totalBetAmt: totbetAmt });
                                // let oldAndNewDragon = Number(betArray.dragon[idName]) + Number(value);
                                this.undoArray.push({ [idName]: Number(this.state.chipValue) });
                                let num_id = idName + "_id";
                                document.getElementById(idName).innerHTML = `<div> <p class="goldCoin_dr" } /> <span class="t_Count" id=${num_id}> ${checkVal} </span </div>`;
                            }
                        } else {
                            this.setState({
                                infoText: this.props.language.LowBalance
                            })
                        }
                    } else if (Object.getOwnPropertyNames(this.betObj.tie)[i] == idName) {

                        if ((this.betObj.tie[idName] + Number(this.state.chipValue)) <= this.props.maxBetAmt) {
                            if (Number(this.betObj.tie[idName] + Number(this.state.chipValue) <= Number(inrMaxLmt))) {
                                this.setState({ totalBetAmount: this.state.betAmount, totalBetAmt: totbetAmt });
                                betArray.tie[idName] = Number(this.betObj.tie.ti) + Number(this.state.chipValue);
                                var checkVal2 = this.betObj.tie[idName]
                                //console.log("checkVal2: " + checkVal2); 
                                let betVal2 = this.convertBetValue(checkVal2);
                                this.undoArray.push({ [idName]: Number(this.state.chipValue) });
                                let num_id2 = idName + "_id";
                                document.getElementById(idName).innerHTML = `<div> <p class="goldCoin_dr" } /> <span class="t_Count" id=${num_id2}> ${betVal2} </span </div>`;
                            }
                        } else {
                            this.setState({
                                infoText: this.props.language.LowBalance
                            })
                        }

                    } else if (Object.getOwnPropertyNames(this.betObj.tiger)[i] == idName) {
                        if ((this.betObj.tiger[idName] + Number(this.state.chipValue)) <= this.props.maxBetAmt) {
                            if (Number(this.betObj.tiger[idName] + Number(this.state.chipValue) <= Number(inrMaxLmt))) {
                                this.setState({ myBetAmt: this.state.myBetAmt + 100 });
                                betArray.tiger[idName] = (Number(this.betObj.tiger[idName]) + Number(this.state.chipValue));
                                let checkVal3 = this.betObj.tiger[idName];
                                let betVal3 = this.convertBetValue(checkVal3);
                                // this.setState({ totalBetAmt: (this.state.totalBetAmount + this.state.chipValue) });
                                this.setState({ totalBetAmount: this.state.betAmount, totalBetAmt: totbetAmt });
                                this.undoArray.push({ [idName]: Number(this.state.chipValue) });
                                let num_id3 = idName + "_id";
                                document.getElementById(idName).innerHTML = `<div> <p class="goldCoin_dr" } /> <span class="t_Count" id=${num_id3}> ${betVal3} </span </div>`;
                            }
                        } else {
                            this.setState({
                                infoText: this.props.language.LowBalance
                            })
                        }
                    }
                }

                let myAmt = 0;
                for (let j = 0; j < 3; j++) {
                    myAmt += Number(Object.values(betArray.tiger)[j]);
                    myAmt += Number(Object.values(betArray.dragon)[j]);
                }
                myAmt += Number(Object.values(betArray.tie)[0]);
                this.setState({ totalBetAmount: myAmt });
                this.betObj = betArray;
            } else {
                // this.setState({
                //     infoText: this.props.language.Min_Bet + " " + this.props.minBetAmt + " " + this.props.language.Max_Bet + " " + this.props.maxBetAmt
                // })
                this.setState({ infoText: this.props.language.MinBetAmt + " " + this.props.minBetLbl + " " + this.props.language.MaxBetAmt + " " + this.props.maxBetLbl });

            }
        } else {
            this.setState({
                infoText: this.props.language.LowBalance
            })
        }

        //console.log(this.undoArray)
    }

    /************************ CLEARE BET *****************************/
    cleareBets = () => {
        //console.log("Clear Bets " + Number(totalBetAmt));
        this.disableBtn("dealBtn");
        this.disableBtn("unDoBtn");
        this.disableBtn("clrBtn");
        this.disableBtn("doubleBtn");

        if (this.props.gameState.betRequest && this.props.gameState.betRequest.betAmount > 0) {
            this.setState({
                betBtnStatus: 0,
                betBtnTxt: this.props.language.Prev,
            });
            this.enableBtn('dealBtn');
        } else {
            this.setState({
                betBtnStatus: 0,
                betBtnTxt: this.props.language.Prev,
            });
            this.disableBtn('dealBtn');
        }

        if (document.getElementById("dealBtn")) {
            document.getElementById("dealBtn").classList.remove("dr_bt_active");
        }
        // if (document.getElementById("betBoxCover")) {
        //     document.getElementById("betBoxCover").style.display = "none";
        // }
        // console.log(this.betObj);

        for (let j = 0; j < this.coinId.length; j++) {
            let betId = this.coinId[j];
            if (document.getElementById(betId)) {
                document.getElementById(betId).innerHTML = `<div> <p class="goldCoin_dr" } /> </div>`;
            }
        }

        this.setState({
            infoText: this.props.language.Play_Njoy,
            undoArray: [],
            balanceLbl: this.props.balance,
            totalBetAmt: 0,
            totalBetAmount: 0,
            winCover: false
        })

        this.betObj.dragon["dr"] = 0;
        this.betObj.dragon["dbig"] = 0;
        this.betObj.dragon["dsml"] = 0;
        this.betObj.tie["ti"] = 0;
        this.betObj.tiger["tr"] = 0;
        this.betObj.tiger["tbig"] = 0;
        this.betObj.tiger["tsml"] = 0;

        // if (this.props.gameState.betRequest && this.props.gameState.betRequest.betAmount > 0) {
        //     this.setState({
        //         betBtnStatus: 0,
        //         betBtnTxt: this.props.language.Prev,
        //     });
        //     this.enableBtn('dealBtn');
        // } else {
        //     this.disableBtn('dealBtn');
        // }
        console.log(this.betObj);
    }

    TakeAmount_fn = () => {

        let dragonWinCard = document.getElementById('dragonWinCard');
        let tigerWinCard = document.getElementById('tigerWinCard');
        if (dragonWinCard) {
            dragonWinCard.innerHTML = ``
        }
        if (tigerWinCard) {
            tigerWinCard.innerHTML = ``
        }

        document.getElementById("dealBtn").classList.remove("dr_bt_active");
        let take_body = {
            sessionId: sessionStorage.getItem('sessionId'),
            CN: "TAKE_AMOUNT",
            object: {
                gameId: this.props.gameId,
                playerId: Number(this.props.playerId),
            },
        };
        this.props.network.sendof(take_body);

        let gs_body = {
            sessionId: sessionStorage.getItem('sessionId'),
            CN: "GET_GAME_STATE",
            object: {
                playerId: Number(this.props.playerId),
                gameId: this.props.gameId,
            },
        };
        this.props.network.sendof(gs_body);

        document.getElementById("betBoxCover").style.display = "none";
        document.getElementById("betBoxCover").style.zIndex = 99;

        this.betObj.dragon["dr"] = 0;
        this.betObj.dragon["dbig"] = 0;
        this.betObj.dragon["dsml"] = 0;
        this.betObj.tie["ti"] = 0;
        this.betObj.tiger["tr"] = 0;
        this.betObj.tiger["tbig"] = 0;
        this.betObj.tiger["tsml"] = 0;

        this.setState({
            betBtnStatus: 0,
            betBtnTxt: this.props.language.Prev,
            infoText: this.props.language.Play_Njoy,
            totalBetAmount: 0,
            totalBetAmt: 0,
            winCover: false,
            winAmount: 0,
            undoArray: [],
        });
        this.enableBtn("dealBtn");

        for (let j = 0; j < this.coinId.length; j++) {
            let betId = this.coinId[j];
            document.getElementById(betId).innerHTML = `<div> <p class="goldCoin_dr" } /> </div>`;

            if (document.getElementById((betId + "_img")) && document.getElementById((betId + "_img")).classList.contains("active_win")) {
                document.getElementById(betId + "_img").classList.remove("active_win");
            }
        }
        if (this.props.isPlayerBanned) {
            this.closeGame();
            this.props.player_banned();
        }

        //console.log("gameUnderMaintanance " + props.gameUnderMaintanance);
        if (this.props.gameUnderMaintanance) {
            this.closeGame();
            this.props.game_maintanance("Game under maintenance, reach to admin.");
        }

        //console.log("deviceBlocked " + props.isdeviceBlocked);
        if (this.props.isdeviceBlocked) {
            this.closeGame();
            this.props.device_blocked();
        }
    }

    /************************ REBET FUNCTIONALITY *****************************/
    rebetFun = () => {        
        if (this.props.gameState.betRequest && this.props.gameState.betRequest.betAmount <= this.props.balance) {
            this.updateBetString();

            for (let a = 0; a < this.winBetArray.length; a++) {
                if (document.getElementById((this.winBetArray[a] + "_img"))) {
                    document.getElementById((this.winBetArray[a] + "_img")).classList.remove("active_win");
                }
            }

            console.log("Previous Bet");
            console.log(this.state.totalBetAmt);
            console.log(this.props.balance);
            // console.log(undoArray)
            // console.log(props.gameState.betRequest.betAmount);

            if (this.state.totalBetAmt <= this.props.balance) { //Changed total bet variable
                if (this.state.totalBetAmt > this.props.maxBetAmt) { //Changed total bet variable
                    this.setState({
                        infoText: this.props.language.PreBetLmt
                    })
                } else {
                    console.log("Rebet: betObj 0 : ");

                    this.setState({ infoText: this.props.language.Play_Njoy, winCover: false })

                    // document.getElementById("betBoxCover").style.display = "none";

                    let reBetObj = [];
                    reBetObj = this.betObj;

                    let rebArray = this.betObj;
                    let redoIdArray = [];
                    let redoValuesArray = [];

                    var totalVal = 0;
                    for (let i = 0; i < 7; i++) {
                        if (Object.getOwnPropertyNames(reBetObj.dragon)[i] != undefined && Object.getOwnPropertyNames(reBetObj.dragon)[i] == Object.keys(rebArray.dragon)[i]) {
                            redoIdArray.push(Object.keys(rebArray.dragon)[i]);
                            redoValuesArray.push(Object.values(rebArray.dragon)[i]);
                            reBetObj.dragon[Object.keys(rebArray.dragon)[i]] = Object.values(rebArray.dragon)[i];

                            let valNo = Object.values(rebArray.dragon)[i];
                            if (valNo !== "") {
                                totalVal += Number(valNo);
                                if (valNo > 0) {
                                    this.undoArray.push({ [Object.keys(rebArray.dragon)[i]]: Number(valNo) });
                                }
                            }
                        }
                        if (Object.getOwnPropertyNames(reBetObj.tiger)[i] != undefined && Object.getOwnPropertyNames(reBetObj.tiger)[i] == Object.keys(rebArray.tiger)[i]) {
                            redoIdArray.push(Object.keys(rebArray.tiger)[i]);
                            redoValuesArray.push(Object.values(rebArray.tiger)[i]);
                            reBetObj.tiger[Object.keys(rebArray.tiger)[i]] = Object.values(rebArray.tiger)[i];

                            let valNo = Object.values(rebArray.tiger)[i];
                            if (valNo !== "") {
                                totalVal += Number(valNo);
                                if (valNo > 0) {
                                    this.undoArray.push({ [Object.keys(rebArray.tiger)[i]]: Number(valNo) });
                                }
                            }
                        }
                    }

                    if (Object.values(rebArray.tie)[0] !== "" && Object.values(rebArray.tie)[0] > 0) {
                        this.undoArray.push({ [Object.keys(rebArray.tie)[0]]: Number(Object.values(rebArray.tie)[0]) });
                    }
                    redoIdArray.push(Object.keys(rebArray.tie)[0]);
                    redoValuesArray.push(Object.values(rebArray.tie)[0]);
                    reBetObj.tie[Object.keys(rebArray.tie)[0]] = Object.values(rebArray.tie)[0];

                    let valNo = Object.values(rebArray.tie)[0];
                    if (valNo !== "") {
                        totalVal += Number(valNo);
                    }
                    this.rebetSetToGame(redoIdArray, redoValuesArray);
                    this.setState({
                        betBtnStatus: 1,
                        betBtnTxt: this.props.language.BetOK,
                    });
                    this.enableBtn("dealBtn");
                    this.enableBtn("clrBtn");
                    this.enableBtn("unDoBtn");
                    this.enableBtn("doubleBtn");
                    document.getElementById("dealBtn").classList.add("dr_bt_active");
                }
            } else {
                this.setState({ infoText: this.props.language.BetAmtMaxBal });
            }
        } else {
            this.setState({ infoText: this.props.language.BetAmtMaxBal });
        }
    }

    rebetSetToGame(ids, vals) {
        console.log(ids);
        console.log(ids.length);
        console.log(vals);
        let betAmt = 0;

        for (let j = 0; j < ids.length; j++) {
            console.log(vals[j]);
            if (vals[j] > 0) {
                // let amtLbl = changeAmtlabl(vals[j])
                betAmt += Number(vals[j]);
                let num_id4 = ids[j] + "_id";

                let betVal_id4 = this.convertBetValue(vals[j]);

                document.getElementById(ids[j]).innerHTML = `<div> <p class="goldCoin_dr" } /> <span class="t_Count" id=${num_id4}> ${betVal_id4} </span </div>`;
            }
        }
        this.setState({ totalBetAmt: betAmt, totalBetAmount: betAmt });
    }

    /************************ DOUBLE *****************************/
    double = () => {
        console.log("double bet amount " + this.state.totalBetAmt)
        if (this.state.totalBetAmt * 2 <= this.props.balance) {
            if (this.state.totalBetAmt * 2 <= this.props.maxBetAmt) {
                // console.log(this.state.totalBetAmt + '          ' + this.state.totalBetAmt * 2)
                // console.log(this.props.maxBetAmt + '           ' + this.props.balance);
                // console.log(this.betObj);
                let betArray = {};
                betArray = this.betObj;
                let newBetAmt = 0;
                this.undoArray.length = 0;
               

                for (let i = 0; i < this.state.betidReq.length; i++) {
                    let betId = this.state.betidReq[i].split("_id")[0];
                    if (document.getElementById(this.state.betidReq[i]) != null && Number(document.getElementById(this.state.betidReq[i]).innerHTML) > 0) {
                        console.log(document.getElementById(this.state.betidReq[i]));

                        console.log(betId)
                        switch (betId) {
                            case "dr":
                                if (Number(document.getElementById(this.state.betidReq[i]).innerHTML) * 2 <= this.innerLimits[0][1]) {
                                    document.getElementById(this.state.betidReq[i]).innerHTML = Number(document.getElementById(this.state.betidReq[i]).innerHTML) * 2;
                                    betArray.dragon[betId] = Number(document.getElementById(this.state.betidReq[i]).innerHTML);
                                } else {
                                    this.setState({ infoText: this.props.language.Play_Njoy })
                                }
                                break;
                            case "tr":
                                if (Number(document.getElementById(this.state.betidReq[i]).innerHTML) * 2 <= this.innerLimits[0][1]) {
                                    document.getElementById(this.state.betidReq[i]).innerHTML = Number(document.getElementById(this.state.betidReq[i]).innerHTML) * 2;
                                    betArray.tiger[betId] = Number(document.getElementById(this.state.betidReq[i]).innerHTML);
                                } else {
                                    this.setState({ infoText: this.props.language.Play_Njoy })
                                }
                                break;
                            case "dbig":
                                if (Number(document.getElementById(this.state.betidReq[i]).innerHTML) * 2 <= this.innerLimits[2][1]) {
                                    document.getElementById(this.state.betidReq[i]).innerHTML = Number(document.getElementById(this.state.betidReq[i]).innerHTML) * 2;
                                    betArray.dragon[betId] = Number(document.getElementById(this.state.betidReq[i]).innerHTML);
                                } else {
                                    this.setState({ infoText: this.props.language.Play_Njoy })
                                }
                                break;
                            case "dsml":
                                if (Number(document.getElementById(this.state.betidReq[i]).innerHTML) * 2 <= this.innerLimits[2][1]) {
                                    document.getElementById(this.state.betidReq[i]).innerHTML = Number(document.getElementById(this.state.betidReq[i]).innerHTML) * 2;
                                    betArray.dragon[betId] = Number(document.getElementById(this.state.betidReq[i]).innerHTML);
                                } else {
                                    this.setState({ infoText: this.props.language.Play_Njoy })
                                }
                                break;
                            case "tbig":
                                if (Number(document.getElementById(this.state.betidReq[i]).innerHTML) * 2 <= this.innerLimits[2][1]) {
                                    document.getElementById(this.state.betidReq[i]).innerHTML = Number(document.getElementById(this.state.betidReq[i]).innerHTML) * 2;
                                    betArray.tiger[betId] = Number(document.getElementById(this.state.betidReq[i]).innerHTML);
                                } else {
                                    this.setState({ infoText: this.props.language.Play_Njoy })
                                }
                                break;
                            case "tsml":
                                if (Number(document.getElementById(this.state.betidReq[i]).innerHTML) * 2 <= this.innerLimits[2][1]) {
                                    document.getElementById(this.state.betidReq[i]).innerHTML = Number(document.getElementById(this.state.betidReq[i]).innerHTML) * 2;
                                    betArray.tiger[betId] = Number(document.getElementById(this.state.betidReq[i]).innerHTML);
                                } else {
                                    this.setState({ infoText: this.props.language.Play_Njoy })
                                }
                                break;
                            case "ti":
                                if (Number(document.getElementById(this.state.betidReq[i]).innerHTML) * 2 <= this.innerLimits[1][1]) {
                                    document.getElementById(this.state.betidReq[i]).innerHTML = Number(document.getElementById(this.state.betidReq[i]).innerHTML) * 2;
                                    betArray.tie[betId] = Number(document.getElementById(this.state.betidReq[i]).innerHTML);
                                } else {
                                    this.setState({ infoText: this.props.language.Play_Njoy });
                                }
                                break;
                        }
                        newBetAmt += Number(document.getElementById(this.state.betidReq[i]).innerHTML);
                        this.undoArray.push({ [betId]: Number(document.getElementById(this.state.betidReq[i]).innerHTML) });

                    }
                    console.log("New Bet Amount: " + newBetAmt)
                }
                this.betObj = betArray;
                
                this.setState({
                    totalBetAmt: newBetAmt,
                    totalBetAmount: newBetAmt,
                    balanceLbl: (this.props.balance -newBetAmt)
                })
            } else {
                console.log('double reched max bet limt')
                // this.setState({
                //     infoText: this.props.language.Min_Bet + " " + this.props.minBetAmt + " " + this.props.language.Max_Bet + " " + this.props.maxBetAmt
                // })
                this.setState({ infoText: this.props.language.MinBetAmt + " " + this.props.minBetLbl + " " + this.props.language.MaxBetAmt + " " + this.props.maxBetLbl });
            }
        } else {
            this.setState({
                infoText: this.props.language.LowBalance
            })
        }

    }
    /************************ DOUBLE *****************************/



    /************************ DEAL *****************************/

    deal = () => {
        this.disableBtn("dealBtn");
        document.getElementById("betBoxCover").style.display = "block";
        console.log(this.state.totalBetAmt + '    ' + this.state.totalBetAmount)
        if (Number(this.state.totalBetAmount) > 0) {
            if (Number(this.state.totalBetAmount) < Number(this.props.minBetAmt)) {
                this.enableBtn("dealBtn")
                // this.setState({infoText: this.props.language.Min_Bet + " " + this.props.minBetAmt + " " + this.props.language.Max_Bet + " " + this.props.maxBetAmt});
                this.setState({ infoText: this.props.language.MinBetAmt + " " + this.props.minBetLbl + " " + this.props.language.MaxBetAmt + " " + this.props.maxBetLbl });
            } else {
                this.placebet();
            }
        } else {
            document.getElementById("betBoxCover").style.display = "none";
        }
    }

    disableBtns = () => {
        this.setState({
            enableKeyboard: false
        })
        this.disableBtn("dealBtn");
        this.disableBtn("unDoBtn");
        this.disableBtn("clrBtn");
        this.disableBtn("doubleBtn");
        if (document.getElementById("dealBtn")) {
            document.getElementById("dealBtn").classList.remove("dr_bt_active");
        }
    }

    /************************ PLACE BET *****************************/
    placebet = () => {
        let betReq = [
            [Number(this.betObj.dragon.dr.toFixed(2)), Number(this.betObj.tiger.tr.toFixed(2))],
            [Number(this.betObj.tie.ti.toFixed(2))],
            [Number(this.betObj.dragon.dbig.toFixed(2)), Number(this.betObj.dragon.dsml.toFixed(2)), Number(this.betObj.tiger.tbig.toFixed(2)), Number(this.betObj.tiger.tsml.toFixed(2))]
        ];
        console.log(betReq)
        if (this.props.balance >= this.state.totalBetAmt) {
            this.disableBtn("unDoBtn");
            this.disableBtn("dealBtn");
            this.disableBtn("clrBtn");
            this.disableBtn("doubleBtn");

            this.setState({
                enableKeyboard: false,
                winCover: true
            })

            let dragonWinCard = document.getElementById('dragonWinCard');
            let tigerWinCard = document.getElementById('tigerWinCard');
            if (dragonWinCard) {
                dragonWinCard.innerHTML = ``
            }
            if (tigerWinCard) {
                tigerWinCard.innerHTML = ``
            }

            document.getElementById("dealBtn").classList.remove("dr_bt_active");
            // document.getElementById("betBoxCover").style.display = "block";
            document.getElementById("betBoxCover").style.zIndex = 300;

            let arrStr = betReq.toString();
            let betReq2 = arrStr.replace(/,/g, '|');
            let pipeIndex = [];
            for (let k = 0; k < betReq2.length; k++) {
                if (betReq2[k] == '|') {
                    pipeIndex.push(k);
                }
            }

            betReq2[pipeIndex[1]].replace('|', ',');
            let setCharAt = (str, index, chr) => {
                if (index > str.length - 1) return str;
                return str.substring(0, index) + chr + str.substring(index + 1);
            }

            betReq2 = setCharAt(betReq2, pipeIndex[1], ',');
            betReq2 = setCharAt(betReq2, pipeIndex[2], ',');
            this.setState({ isBetSent: true });

            let dt_bet_body = {
                sessionId: sessionStorage.getItem('sessionId'),
                CN: "DRAGON_TIGER_TIMER_BET",
                object: {
                    betString: betReq2,
                    playerId: Number(this.props.playerId),
                    gameId: this.props.gameId,
                    betAmount: Number(this.state.totalBetAmt.toFixed(2)),
                },
            };
            this.props.network.sendof(dt_bet_body);

            let pinfo_body = {
                CN: "GET_PLAYER_INFO",
                object: {
                    gameId: Number(this.props.gameId),
                    playerId: Number(this.props.playerId),
                },
                sessionId: sessionStorage.getItem('sessionId'),
            };
            this.props.network.sendof(pinfo_body);


        } else {
            this.setState({
                infoText: this.props.language.LowBalance
            })
            document.getElementById("betBoxCover").style.display = "none";
            this.enableBtn("unDoBtn");
            this.enableBtn("dealBtn");
            this.enableBtn("clrBtn");
            this.enableBtn("doubleBtn");

            this.setState({
                enableKeyboard: true
            })
        }
    }

    closeGame = () => {
        clearTimeout(this.table_timeout1);
        clearTimeout(this.table_timeout2);
        clearTimeout(this.table_timeout3);
        clearTimeout(this.table_timeout4);
        clearTimeout(this.table_timeout5);
        clearTimeout(this.table_timeout6);
        clearTimeout(this.table_timeout7);
        clearTimeout(this.table_timeout8);
        clearTimeout(this.table_timeout9);
        clearTimeout(this.table_timeout10);
        clearTimeout(this.table_timeout11);
        clearTimeout(this.table_timeout12);
        clearTimeout(this.table_timeout13);
        clearTimeout(this.table_timeout14);
        clearTimeout(this.table_timeout15);


        this.setState({ isGameActive: false, enableKeyboard: false })

        if (this.props.targetResult) {
            if (this.props.targetResult.winAmount == 0) {
                this.newGame();
            }
        }
        this.GameBox.clearTimer_fn();

        if (document.getElementById("dealBtn") && !document.getElementById("dealBtn").disabled) {
            this.cleareBets();
        }
        document.removeEventListener("keyup", this.dt_handleKeyboardEvent);
        this.props.action();
    }

    /*************************  NEW GAME START  *******************/
    newGame = () => {
        let dragonWinCard = document.getElementById('dragonWinCard');
        let tigerWinCard = document.getElementById('tigerWinCard');
        if (dragonWinCard) {
            dragonWinCard.innerHTML = ``
        }
        if (tigerWinCard) {
            tigerWinCard.innerHTML = ``
        }
        this.setState({ undoArray: [], infoText: this.props.language.Play_Njoy, totalBetAmount: 0, winCover: false });
        for (let i = 0; i < this.betObj.length; i++) {
            if (document.getElementById(this.betObj[i] + "_img")) {
                document.getElementById(this.betObj[i] + "_img").classList.remove("active_win");
            }
            if (document.getElementById(this.betObj[i])) {
                document.getElementById(this.betObj[i]).innerHTML = `<div><p class="goldCoin_ar">  </div>`;
            }
        }
    }

    removeGlow = () => {
        for (let j = 0; j < this.coinId.length; j++) {
            let betId = this.coinId[j];
            if (document.getElementById((betId + "_img")) && document.getElementById((betId + "_img")).classList.contains("active_win")) {
                document.getElementById(betId + "_img").classList.remove("active_win");
            }
        }

        if (document.getElementById('dragonWinCard')) {
            document.getElementById('dragonWinCard').innerHTML = ``;
        }
        if (document.getElementById('tigerWinCard')) {
            document.getElementById('tigerWinCard').innerHTML = ``;
        }
    }

    closeWin() {
        document.getElementById('iospopup').style.display = "none";
        this.winSound = new Audio(winSoundFile);
        this.loseSound = new Audio(duploseSound);
        this.betSound = new Audio(betSoundFile);
        this.takeBtnSnd = new Audio(takeSoundFile);
        this.clearSound = new Audio(clearSoundFile);
        this.exitSound = new Audio(exitSoundFile);
        this.betChipSound = new Audio(betChipSnd);
    }

    topLable_fn(labelValue) 
    {
        // Nine Zeroes for Billions
        return Math.abs(Number(labelValue)) >= 1.0e+9
    
        ? (Math.abs(Number(labelValue)) / 1.0e+9).toFixed(2) + "B"
        // Six Zeroes for Millions 
        : Math.abs(Number(labelValue)) >= 1.0e+6
    
        ? (Math.abs(Number(labelValue)) / 1.0e+6).toFixed(2) + "M"
        // Three Zeroes for Thousands
        : Math.abs(Number(labelValue)) >= 1.0e+3
    
        ? (Math.abs(Number(labelValue)) / 1.0e+3).toFixed(2) + "K"
    
        : Math.abs(Number(labelValue.toFixed(2)));
    }
    
    render() {
       
        let updateWin = this.topLable_fn(Number(this.state.winAmount));
        return (
            <React.Fragment>
                <div className="dragGame gamePage">

                    <div className="iosFetchSounds" id="iospopup" onClick={(e) => this.closeWin()}>
                        <div className="popupTooLong">
                            <div className=""> {this.props.language.Wel_DTT}</div>
                            <br></br>
                            {this.props.language.OK_Btn}

                            <br></br>
                            <br></br>
                            <div className="fd">
                                <button className="btn_iosPopup" > {this.props.language.Ok} </button>
                            </div>
                        </div>
                    </div>

                    <div className="dt_popup_back" id="winPop">

                        <img id="dragon_sprkl"
                            className="dt_sparkAnim"
                            src=""
                        />
                        <div className="dt_winpop">
                            <span className="dt_win_txt">{updateWin}</span>
                        </div>
                    </div>


                    <div className="gameBox">
                        <GameBox
                            childRef={ref => (this.GameBox = ref)}
                            ref={GameBox}
                            // chipValues={props.dt_chipValues}
                            getBetBoxId={this.getBetBoxId.bind(this)}
                            stopBetSound={this.stopBetSound.bind(this)}
                            winAmount={this.state.winAmount}
                            getTimer={this.getTimer}
                            removeGlow={this.removeGlow}
                            user={this.props.user}
                            balanceLbl={this.state.balanceLbl}
                            balancedragon={this.props.balance}
                            // action={this.closeGame}
                            betAmount={this.state.totalBetAmt}
                            deal={this.deal}
                            Chek_Spec={this.Chek_Spec}
                            undoFun={this.undoFun}
                            newGame={this.newGame}
                            rebetFun={this.rebetFun}
                            doubleBetFun={this.double}
                            cleareBets={this.cleareBets}
                            takeAmount_fn={this.TakeAmount_fn}
                            minBetAmt={this.props.minBetAmt}
                            maxBetAmt={this.props.maxBetAmt}
                            language={this.props.language}
                            infoTxt={this.state.infoText}
                            dragonTigerHistoy={this.props.dragonTigerHistoy}
                            handId={this.props.gameState.handId}
                            playerId={this.props.playerId}
                            gameId={this.props.gameId}
                            betSent={this.state.isBetSent}
                            winCover={this.state.winCover}
                            network={this.props.network}
                            isMobile={this.props.isMobile}
                            betBtnStatus={this.state.betBtnStatus}
                            betBtnTxt={this.state.betBtnTxt}
                            isGameActive={this.state.isGameActive}
                            chipValue={this.chipValue.bind(this)}
                            disableBtns={this.disableBtns}
                            dtt_chipValues={this.props.dtt_chipValues}
                            selectedChipVal={this.state.selectedChipVal}
                        ></GameBox>
                        {/* <Close
                            action={this.closeGame}>
                        </Close> */}
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

const mapStatesToProps = (state) => {
    return {
        language: state.languageObjs.languageObj
    }
}

export default connect(mapStatesToProps)(DragonTigerTimer)


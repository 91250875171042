import React, { Component } from "react";
import matkanums from "./matkaNumbers";
import chips from "../../../chips";

import { connect } from "react-redux";

class MatkaLeft extends Component {
  constructor(props) {
    super(props);
    const{childRef} = props 
    childRef(this)
  }
  scale(val){ 
    var secList_left = document.getElementsByClassName('secList_left');
    for (var i = 0; i < secList_left.length; i++) {
      secList_left[i].classList.remove('matkaScale')
    }
    var id = document.getElementById(val).classList.add('matkaScale'); 
  }

  removeScale(){
    let secList_left = document.getElementsByClassName('secList_left');
    for (let m = 0; m < secList_left.length; m++) {
      secList_left[m].classList.remove('matkaScale')
    }
  }
  mouseDownSelectCard(data){ 
    this.props.action(data, "leftClick") 
    this.startInt = setInterval( () => { 
      this.props.action(data, "leftClick")
    }, 150); 
  } 
  mouseUpStopCards(){
    clearInterval(this.startInt);
  }
  
  // balaceLable_fn(betAmount){
  //   if(betAmount >= 1000 && betAmount <=999999){
  //       betAmount = betAmount / 1000+"K";      
  //   }else if(betAmount >= 1000000 && betAmount <=999999999){
  //     betAmount = betAmount / 1000+"M";    
  //   }else if(betAmount >999999999){
  //     betAmount = betAmount / 1000+"B";      
  //   }

  //   return betAmount;
  // }
  balaceLable_fn(Value) {
    let labelValue = Math.abs(Number(Value))
    // Nine Zeroes for Billions
    return labelValue >= 1.0e+9

    ? Number((labelValue / 1.0e+9).toFixed(2)) + "B"
    // Six Zeroes for Millions 
    : labelValue >= 1.0e+6

    ? Number((labelValue / 1.0e+6).toFixed(2)) + "M"
    // Three Zeroes for Thousands
    : labelValue >= 1.0e+3

    ? Number((labelValue / 1.0e+3).toFixed(2)) + "K"

    : Number(labelValue.toFixed(2));
}


  // balaceLable_fn(labelValue) 
  // {
  //     // Nine Zeroes for Billions
  //     return Math.abs(Number(labelValue)) >= 1.0e+9
  
  //     ? Math.abs(Number(labelValue)) / 1.0e+9 + "B"
  //     // Six Zeroes for Millions 
  //     : Math.abs(Number(labelValue)) >= 1.0e+6
  
  //     ? Math.abs(Number(labelValue)) / 1.0e+6 + "M"
  //     // Three Zeroes for Thousands
  //     : Math.abs(Number(labelValue)) >= 1.0e+3
  
  //     ? Math.abs(Number(labelValue)) / 1.0e+3 + "K"
  
  //     : Math.abs(Number(labelValue));
  // }
  
  render() {
    const roundNumber = this.props.balance - this.props.totalBet
    // const betAmount = Math.round(roundNumber*100, 2)/100
    const betAmount = (roundNumber)

   
    let updatebal = this.balaceLable_fn(betAmount)
    let updateBet = this.balaceLable_fn(this.props.totalBet)


    return (
      <div className="fd p_3 leftBox">
        <div className="id_Numbers ">
          {this.props.language.Player_Id}: {this.props.playerId} <br />
          {this.props.language.Hand_Id}: {this.props.gameState.handId}
        </div>
        <div className="topBox numberSet secList_left"
          id="leftOne" onClick={(e)=> this.scale('leftOne')}>
            {matkanums[4].top_bottom.filter(data => data.tpBtm === 'topLeft').map((data) => (
              <div className="in_2" key={data.betId} betNumber={data.betNum} id={data.betId} onMouseDown={(e) => { e.preventDefault(); this.mouseDownSelectCard(`${data.betId}`)}}
                  onMouseUp={(e)=>{this.mouseUpStopCards()}}
                  onMouseOut={(e)=>{this.mouseUpStopCards()}}> {data.betNum} <span></span> </div>
            ))}
        </div>
        <div className="fd">
          <div className="numberSet secList_left"
          id="leftTwo" onClick={(e)=> this.scale('leftTwo')}>
            {matkanums[0].sectionOne.map((data) => (
            <div className="in_2" key={data.betId} betNumber={data.betNum} id={data.betId} onMouseDown={(e) => { e.preventDefault(); this.mouseDownSelectCard(`${data.betId}`)}}
                onMouseUp={(e)=>{this.mouseUpStopCards()}}
                onMouseOut={(e)=>{this.mouseUpStopCards()}}> {data.betNum} <span></span> </div>
            ))}
          </div>
        </div>
        <div className="fd matka_m_t_vh">
          <div className="numberSet secList_left"
            id="leftThree" onClick={(e)=> this.scale('leftThree')}>
              {matkanums[1].sectionTwo.map((data) => (
              <div className="in_2" key={data.betId} betNumber={data.betNum} id={data.betId} onMouseDown={(e) => { e.preventDefault(); this.mouseDownSelectCard(`${data.betId}`)}}
                  onMouseUp={(e)=>{this.mouseUpStopCards()}}
                  onMouseOut={(e)=>{this.mouseUpStopCards()}}> {data.betNum} <span></span> </div>
              ))}
          </div>
        </div>
        <div className="topBox numberSet matka_m_t_vh secList_left"
        id="leftFour" onClick={(e)=> this.scale('leftFour')}>
        {matkanums[4].top_bottom.filter(data => data.tpBtm === 'btmLeft').map((data) => (
          <div className="in_2" key={data.betId} betNumber={data.betNum} id={data.betId} onMouseDown={(e) => { e.preventDefault(); this.mouseDownSelectCard(`${data.betId}`)}}
              onMouseUp={(e)=>{this.mouseUpStopCards()}}
              onMouseOut={(e)=>{this.mouseUpStopCards()}}> {data.betNum} <span></span> </div>
        ))}
        </div>
        <div className="fd matka_m_t_vh d-flex secList_left">
            <div className="fl_1">
                <div className="matkaBal fd">
                    {/* <div className=""> {this.props.balance - this.props.totalBet} </div> */}
                    <div className=""> {updatebal} </div>

                    <div className="">{this.props.language.Balance} </div>
                </div>
            </div>
            <div className="fl_1">
                <div className="matkaBal fd">
                    <div className=""> {updateBet} </div>
                    <div className="">{this.props.language.TotalBet} </div>
                </div>
            </div>
            {/* <div className="fl_1">
                <div className="matkaBal fd">
                    <div className=""> {this.props.winning} </div>
                    <div className=""> WINNING </div>
                </div>
            </div> */}
        </div>
      </div>
    );
  }
}



const mapStatesToProps = (state) =>{
  return{
    language:state.languageObjs.languageObj
  }
}

export default connect(mapStatesToProps)(MatkaLeft);

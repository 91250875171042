import React from "react";
import Wheel from "./UI/wheel";
import RightButtonPanel from "./UI/rightButtonPanel";
import EuropeanTimerInSeconds from "./UI/european_TimerSec";
import "../../../../css/all_roulette_tamplate.css"; 
import artBg from "../../../../../assets/games/erupeanRouletteTimer/ert_bg.jpg"; 
import "./UI/europeanTimerRoulette.css"; 
import Close from "./UI/close";
import Title from "./UI/title";
import Table from "./UI/Table";
import BetChips from './UI/betChips';
import coin from '../../../../../assets/gold_coin.svg';
import user from '../../../../../assets/login/user.svg';

import clearSound from "../../../../../assets/sounds/clear.mp3";
import betSound from "../../../../../assets/sounds/bet.mp3";
import takeSound from "../../../../../assets/sounds/take.mp3";
import winSound from "../../../../../assets/sounds/win.mp3";
import duploseSound from "../../../../../assets/sounds/duplose.mp3";
import exitSound from "../../../../../assets/sounds/exit.mp3";
import tickSound from "../../../../../assets/sounds/tick.mp3";
import wheelSound from "../../../../../assets/sounds/wheelStart.mp3";

import {connect} from 'react-redux'

 class EuropeanRouletteTimer extends React.Component {
    constructor(props) {
        super(props);
        const {childRef} = this.props 
        childRef(this)
        this.artWheelRef = React.createRef();
        this.EuropeanTimerInSeconds = React.createRef();
        this.RightButtonPanel = React.createRef();
        this.eurTm_Ref = React.createRef();
        this.wheelWidth = window.innerHeight * 1;
        this.wheelHeight = window.innerHeight * 0.4;
        this.state = {
            previousBetArray: [],
            finalPreviousBetArray: [],
            balance: this.props.balance,
            gameId: this.props.gameId,
            playerId: this.props.playerId,
            zindex: "",
            betButtonText: this.props.language.Prev,
            betButtonValue:0,
            zoomButtonText:this.props.language.Zoom_ON ,
            zoomButtonValue:0,
            showBetButton: false,
            wheelZoom: 1,
            stop: "NA",
            betString: [],
            Total_Bet_Amount: 0,
            nmArray:[],
            betAmount: 1,
            totalBet: 0,
            betstr: "",
            bets: "",
            winCover:false,
            infoText:this.props.language.Play_Njoy,
            targetResult: props.targetResult,
            selectedChipVal:1,
            wheelZoomClick: true,
            disDoubleBtn: 1,
            disBetBtn: 0,
            disClearBtn: 1,
            disZoomBtn: 0,
            glowTakeBtn: 0,
            btnActive : false,
            winAmount:0,
            eurpRlt:true,      
            placeBetState: false,      
        };
        this.newLimits = []
        this.resultNumber = 0;
        this.isReady = false;
        this.removeGlow = false;
        this.handId = 0;
        this.takeAmount = 0;
        this.isloadImg = false;
        this.clearSound = new Audio(clearSound);
        this.exitSound = new Audio(exitSound);
        this.betSound = new Audio(betSound);
        this.takeSound = new Audio(takeSound);
        this.winSound = new Audio(winSound);
        this.duploseSound = new Audio(duploseSound);
        this.tickSound = new Audio(tickSound);
        this.wheelSound = new Audio(wheelSound);

        this.loadBg();  
      }
      componentDidMount() {
        if(this.props.isIphone){
            document.getElementById('iospopup').style.zIndex = "3000";
            document.getElementById('iospopup').style.display = 'block';  
        }else{
            document.getElementById('iospopup').style.display = 'none';  
        }
          
        document.getElementById('ert_WinPop').style.display ='none'; 
 
        this.resetBetList();
        if(this.props.ert_chipValues){
            let newChips = this.props.ert_chipValues.split(",");
            console.log(newChips[0]);  
            let initChipVal = newChips[0];
            let lblTxt;
            if(initChipVal >= 1000 && initChipVal <= 999999){
                lblTxt = "K"
            }else if(initChipVal >= 1000000 && initChipVal <= 999999999){
                lblTxt = "M"                        
            }else if(initChipVal > 999999999){            
                lblTxt = "B"
            } 
            
            this.state.selectedChipVal = initChipVal;
            // this.state.selectedChipLbl = initChipVal+""+lblTxt;
          }

          if(this.props.ert_innerLimits){
            this.newLimits = this.props.ert_innerLimits.split("|");
            console.log(this.newLimits);
            for(let t=0; t<this.newLimits.length; t++){
              this.newLimits[t] = this.newLimits[t].split("-");
              console.log(" *******   "+this.newLimits[t]);
            }
          }
       
        if(this.props.gameState.handId != undefined){
            if(this.props.gameState.handId == this.props.gameState.oldHandId){
                document.getElementById("eurpRltCover").style.display = 'block'; 
                let newStr = this.props.gameState.betRequest.betString.replace(/,/g, "|");
                console.info("newStr: "+newStr);
                //let newStr0 = newStr.slice(0, -1)
                let newStr1 = newStr.replace(/ /g, "0");
                console.info("newStr1: "+newStr1);
                console.info("newStr1: "+newStr1);
                let newStr2 = newStr1.split('|');
                console.info("newStr2: "+newStr2);
                // var newStr3 = newStr2.slice(0, -1);
                let prevBetString=[];
                for(var i=0; i<newStr2.length; i++){
                    prevBetString.push({
                        id: i,
                        amount: Number(newStr2[i])
                    });
                }
                //console.log(prevBetString);
                this.state.betString = prevBetString.slice();
                console.log("this.state.betString");
                console.log(this.state.betString);

                for(var i=0; i<this.state.betString.length; i++){
                    // var value  = this.state.betString[i].amount;
                    let preBetValue = Number((this.state.betString[i].amount).toFixed(2));
                    if (preBetValue > 0) {
                      if(preBetValue >= 1000 && preBetValue <=999999){
                        preBetValue = Number((preBetValue / 1000).toFixed(2))+"K";
                        console.log(preBetValue);
                      }else if(preBetValue >= 1000000 && preBetValue <=999999999){
                        preBetValue = Number((preBetValue / 1000).toFixed(2))+"M";
                        console.log(preBetValue);
                      }else if(preBetValue >999999999){
                        preBetValue = Number((preBetValue / 1000).toFixed(2))+"B";
                        console.log(preBetValue);
                      }
                      document.getElementById("id_"+i).innerHTML = `<p class="goldCoin"> <img src=${coin} /> <span> ${preBetValue} </span> </p>`
                    }
                    // if(value > 0){
                    //     document.getElementById("id_"+i).innerHTML = `<p class="goldCoin"> <img src=${coin} /> <span> ${value} </span> </p>`
                    // }
                } 
               //this.setState({zoomButtonText: "Wheel Zoom: OFF", wheelZoom: 1.5, zindex: '1000',  X_top: "21.8vh", x_img: 1});
                
               let totBetNum = this.changeAmtlabl(Number(this.props.gameState.betRequest.betAmount));
               this.setState({
                    btnActive : false,
                    betButtonText:this.props.language.BetOK ,
                    betButtonValue:1,
                    disBetBtn: 1,
                    disClearBtn: 1,
                    disDoubleBtn: 1,
                    infoText: this.props.language.BetAccepted + totBetNum,
                    Total_Bet_Amount: this.props.gameState.betRequest.betAmount,
                    winCover:true,
                }); 
            }else{
                console.log(this.props.gameState.betRequest)
                if(this.props.gameState.betRequest == undefined){
                    this.setState({disBetBtn: 1});
                }else{
                    this.setState({disBetBtn: 0});

                }
            }
        }else{ 
            document.getElementById("bet_take").classList.remove("active");            
            if(this.props.gameState.betRequest){
                this.setState({disBetBtn: 1});
            }else{
                this.setState({disBetBtn: 0});
            }
            this.setState({
                betButtonText: this.props.language.Prev,
                betButtonValue:0,
                disDoubleBtn: 0,                
                disClearBtn: 0,
                disZoomBtn: 0,
                winCover:false,
                infoText: this.props.language.Play_Njoy
            });
        }
        if(this.props.gameState.handId){
            this.handId = this.props.gameState.handId;
        }
        if(this.props.gameState.betRequest == undefined){
            this.setState({
                disDoubleBtn: 1,
                disBetBtn: 1,
                disClearBtn: 1,
            });
        }
        // this.RightButtonPanel.current.updateHistory(); 
        this.RightButtonPanel.updateHistory(); 

        document.addEventListener("keyup", this.ert_handleKeyboardEvent); 
    }

    componentWillUnmount() {
        document.removeEventListener("keyup", this.ert_handleKeyboardEvent);
      }

      changeAmtlabl(chngAmt) {
        let newAmt = Number((chngAmt).toFixed(2));
        if (newAmt >= 1000 && newAmt <= 999999) {
            newAmt = Number((newAmt / 1000).toFixed(2)) + "K";
        } else if (newAmt >= 1000000 && newAmt <= 999999999) {
            newAmt = Number((newAmt / 1000).toFixed(2)) + "M";
        } else if (newAmt > 999999999) {
            newAmt = Number((newAmt / 1000).toFixed(2)) + "B";
        }
        return newAmt;
    }

      ert_handleKeyboardEvent = (evt) => {
        console.log(evt.code);
        switch(evt.code){
          case "Enter":
          case "NumpadEnter":
            //if (this.state.betButtonText == "PREV" && this.state.disBetBtn == 0) {
            if (this.state.betButtonValue == 0 && this.state.disBetBtn == 0) {
  
              this.rightButtonPanelHandler("PREV");
           // }else if (this.state.betButtonText == "BET OK" && this.state.disBetBtn == 0) {
            }else if (this.state.betButtonValue == 1 && this.state.disBetBtn == 0) {
  
              this.rightButtonPanelHandler("BET OK");
            }
            break;
          case "Space":
           // if(this.state.betButtonText == "TAKE" && this.state.disBetBtn == 0){
            if(this.state.betButtonValue == 2  && this.state.disBetBtn == 0){
  
              this.rightButtonPanelHandler("TAKE");
            }
            break;
          case "Escape":
            //this.rightButtonPanelHandler("exit");
            break;
          case "KeyC":      
          //  if(this.state.betButtonText == "BET OK" && this.state.disClearBtn == 0){
            if(this.state.betButtonValue == 1  && this.state.disClearBtn == 0){
  
              this.rightButtonPanelHandler("clear");
            }
            break;      
        }  
      }

 updateGameState(){ 
        this.RightButtonPanel.updateHistory(); 
        // this.RightButtonPanel.current.updateHistory(); 
        if(this.props.gameState.betRequest){
            this.setState({disBetBtn: 0});
        }else{
            this.setState({disBetBtn: 1});
        }
        
    } 
    resetBetList(){
        this.state.betString = [];
        for (var l = 0; l < 168; l++) {
            this.state.betString.push({
                id: l,
                amount: 0,
            });
        }
    }

    updateMessage(){
        console.log("--------- "+this.props.infoText);
        this.setState({ infoText: this.props.infoText});
 
        console.log("error_code: "+this.props.error_code);
        if(this.props.error_code== "game.bet.invalid" || this.props.error_code == "game.bet.rejected.timeOver"){
            this.setState({
                betButtonText: this.props.language.Prev,
                betButtonValue:0,
                btnActive : false,
                zoomButtonText: this.props.language.Zoom_ON,
                zoomButtonValue:0,
                wheelZoom: 1,
                zindex: "0",
                X_top: "14.5vh",
                disBetBtn : 0,
                disZoomBtn :0,
                winCover:false
            });
            this.updateWinsArray(0);
        }
    }
    gameCloseHandler(game) {
        console.log("Exit Game");
        this.exitSound.play();
        this.props.action(game); 
        this.artWheelRef.current.stopWheelSound();
        this.clearSound.pause();
        this.betSound.pause();
        this.takeSound.pause();
        this.winSound.pause();
        this.duploseSound.pause();
    }
    wheelHandler(position) {
        //console.log("Stop Position: "+position);
        if(position){
            this.setState({stop: position});
        }
        this.setState({ placeBetState: false });
        
        if(this.props.targetResult.winAmount > 0){
            console.log(this.props.targetResult);
            this.setState({ 
                infoText: this.props.language.WonHand,                    
                disDoubleBtn: 1,                
                disClearBtn: 1,
                disZoomBtn: 1,                   
                winCover:true,
                winAmount: this.props.targetResult.winAmount,
                Total_Bet_Amount: this.props.targetResult.betAmount
            });             
            document.getElementById("eurpRltCover").style.display = 'none'; 
            document.getElementById('ert_WinPop').style.display ='block';
            this.declareWin(this.props.targetResult.winAmount); 
            setTimeout(() =>{
                document.getElementById('ert_WinPop').style.display ='none';
                this.rightButtonPanelHandler('TAKE')
            },2000 ); 
        }else{
            if(this.takeAmount == 0){
                this.setState({winAmount: 0});
                // this.updateWinsArray(0);            
                var valId = document.getElementById("bet_take");
                valId.classList.remove("active");  
                this.setState({betButtonText: this.props.language.Prev,betButtonValue:0, btnActive : false, winCover:false, disZoomBtn: 0 });
            }            
        }        
        console.log("Wheel Handle: winAmount: "+this.props.targetResult.winAmount);
        this.glowImage();
        if(this.props.gameState.handId){
            this.handId = this.props.gameState.handId;
        }
        this.props.gamstateHandle();
    }

    showPrevBtn(){
        this.setState({
            showBetButton: true,
            betButtonText: this.props.language.Prev,
            betButtonValue:0,
            btnActive : false,
        });
        let valId = document.getElementById("bet_take");
        valId.classList.remove("active");
    }
    getPlayerInfo(){
        const body2 = {
          CN: "GET_PLAYER_INFO",
          object: {
            gameId: Number(this.state.gameId),
            playerId: Number(this.props.playerId),
          },
          sessionId: sessionStorage.getItem('sessionId'),
        };
        this.props.network.sendof(body2);
        this.body2 = {};
      }
    declareWin(value) {
        console.log(this.props.targetResult);
        console.log("European Timer: DeclareWIN: value: "+value);
         
        this.setState({
            showBetButton: false,
            infoText: this.props.language.Play_Njoy,
            //balance: this.props.balance,
            totalBet: 0,
            winAmount: this.props.targetResult.winAmount,
            placeBetState: false,      
        });
        this.rightButtonPanelHandler("clear");
        document.getElementById("eurpRltCover").style.display = 'none'; 
        
        if(this.props.gameState.betRequest == undefined){
            this.setState({

            })
        }
        this.isReady = false;
    }
    rightButtonPanelHandler(action, value) {
        console.log( 'action --------------------------')
        console.log( action )
        console.log( 'action--------------------------' )
        switch (action) {
            case "BET OK":
                this.state.totalBet = this.state.betString.reduce((prev, cur) => Number((prev + cur.amount).toFixed(2)), 0);
                if(this.state.totalBet != 0 && this.state.totalBet < this.props.minBetAmt){
                    // this.setState({infoText: this.props.language.Min_Bet+" "+this.props.minBetAmt+" "+this.props.language.Max_Bet+" "+this.props.maxBetAmt});
                    this.setState({infoText: this.props.language.MinBetAmt+" "+this.props.minBetLbl+" "+this.props.language.MaxBetAmt+" "+this.props.maxBetLbl});
          
                  }else{ 
                    // document.getElementById("eurpRltCover").style.display = 'block'; 
                this.setState({
                    // disDoubleBtn: 1,
                    // disBetBtn: 1,
                    // disClearBtn: 1,
                    // disZoomBtn: 1,
                    // placeBetState: true,
                    btnActive:false
                });
                var divContain = document.getElementById('roulette');
                divContain.style.transform = "scale(1)";
                divContain.style.zIndex = "1";
                // if(this.state.zoomButtonText == "Wheel Zoom: ON") {
                if(this.state.zoomButtonValue == 0) {

                    this.setState({zindex: "1000", winCover:true});
                } 
                //document.getElementById("zoomBtn").disabled = true;
                //console.log("Double Btn: "+document.getElementById("zoomBtn").disabled)
                console.log( this.takeAmount )
                if(this.takeAmount == 0){
                    console.log( this.takeAmount )
                    console.log(this.isReady);
                    this.setState({ betstr: [], bets: [] });
                    if(this.state.betString.length >1){
                        this.state.totalBet = this.state.betString.reduce((prev, cur) => Number((prev + cur.amount).toFixed(2)), 0);
                    }else{
                        this.state.totalBet =0;
                    }
                    if (this.isReady) {
                        let betValid = [];
                        this.isReady = false;
                        // this.state.betstr = "";
                        this.state.bets = "";
                        console.log("balance: "+this.props.balance)
                        console.log("totalBet: "+this.state.totalBet)
                        if (this.props.balance >= this.state.totalBet && this.state.totalBet != NaN) {
                            console.log("betString length  "+this.state.betString.length);
                            let innerMin_Limit = 1;
                            for (let i = 0; i < this.state.betString.length; i++) {
                            if(i<=36){                  
                            innerMin_Limit = this.newLimits[0][0];                  
                            
                            }else if(i >= 37 && i <= 96){
                            innerMin_Limit = this.newLimits[1][0];                      
                            }else if(i >= 97 && i <= 110){
                            innerMin_Limit = this.newLimits[2][0];                      
                            }else if(i >= 111 && i <= 133){
                            innerMin_Limit = this.newLimits[3][0];            
                            }else if(i>=135 && i <= 145){
                            innerMin_Limit = this.newLimits[5][0];
                            }else if(i>=151 && i <= 156){
                            innerMin_Limit = this.newLimits[6][0];
                            }else if(i >= 162 && i <= 167){
                            innerMin_Limit = this.newLimits[7][0];

                            }

                            if(this.state.betString[i].amount > 0){
                            console.log(this.state.betString[i].amount)
                            console.log(innerMin_Limit)
                            if(this.state.betString[i].amount >= innerMin_Limit){
                                console.log("OK")
                                betValid[i] = true;
                            }else{
                                betValid[i] = false;
                                console.log("dont place the bet.")
                            }
                            }else{
                            betValid[i] = true;
                            }


                                if (this.state.betString[i].id == 145
                                    || this.state.betString[i].id == 156
                                    || this.state.betString[i].id == 133
                                ) {
                                    this.state.bets += Number(this.state.betString[i].amount.toFixed(2)) + ',';
                                } else if (this.state.betString[i].id == 134

                                    || this.state.betString[i].id == 146
                                    || this.state.betString[i].id == 147
                                    || this.state.betString[i].id == 148
                                    || this.state.betString[i].id == 149
                                    || this.state.betString[i].id == 150

                                    || this.state.betString[i].id == 157
                                    || this.state.betString[i].id == 158
                                    || this.state.betString[i].id == 159
                                    || this.state.betString[i].id == 160
                                    || this.state.betString[i].id == 161
                                    ) {
                                    this.state.bets += ' ' + ',';

                                } else if (
                                    this.state.betString[i].id == 37
                                    || this.state.betString[i].id == 97
                                    || this.state.betString[i].id == 111
                                    ) {
                                    this.state.bets = this.state.bets.slice(0, -1);
                                    this.state.bets += ',' + Number(this.state.betString[i].amount.toFixed(2)) + '|';
                                } else {
                                    this.state.bets += Number(this.state.betString[i].amount.toFixed(2)) + '|';
                                }
                            }

                            let sendBet = true;;
                            for(let u=0; u<betValid.length;u++){
                              if(betValid[u] ==  false){
                                sendBet = false;
                                // console.log("Dont send bet reques");
                                break;
                              }
                            }
                            this.state.betstr = this.state.bets.slice(0, -1);
                            //console.log(this.state.betstr);
                            if(sendBet){
                                this.placebet();
                                this.setState({
                                    disDoubleBtn: 1,
                                    disBetBtn: 1,
                                    disClearBtn: 1,
                                    disZoomBtn: 1,
                                    placeBetState: true,
                                    btnActive:false
                                });
                                console.log(  console.log(this.state.betstr))
                                document.getElementById("eurpRltCover").style.display = 'block'; 
                              }else{
                               
                                console.log("Dont Send Bet Request.")
                                 const minInrLmt = this.changeAmtlabl(Number(innerMin_Limit))
                                  this.setState({
                                     infoText: this.props.language.InerLmt + minInrLmt,
                                     disClearBtn: 0,
                                    });     
                              
                                           
                              }
                           
                            return this.state.betstr;
                        } else {
                            //alert('amount reduces to your balance');
                        }
                    }
                    if(this.state.totalBet > 0){
                        // this.setState({ infoText: this.props.infoText });
                    }else{
                        // this.props.spectatorCount(this.state.Total_Bet_Amount, this.handId );  //Check Spectator
                        this.setState({ infoText: this.props.language.Play_Njoy });
                    }
                }
                

                  }
                break;
            case "TAKE":
                if(document.getElementById('iospopup').style.display == "none"){
                    this.takeSound.play();
                }
                var valId = document.getElementById("bet_take");
                // valId.classList.remove("active");

                for(var i=0; i<this.state.betString.length; i++){
                    var valId = document.getElementById("id_"+i);
                    if(valId){
                        // valId.classList.remove("active");
                    }
                    document.getElementById("id_"+i).innerHTML = ``;
                    this.setState({disClearBtn:1});
                    //console.log("*****: "+this.state.betString[i].amount);
                }
                this.takeAmountResult();

                break;
            case "PREV":
                this.betSound.play();
                this.removeImgGlow();
                this.showPrevBet();
                break;
            case "ZoomON":
                if(document.getElementById('iospopup').style.display == "none"){
                    this.betSound.play();
                }
                //this.setState({zoomButtonText: "Wheel Zoom: OFF", wheelZoom: 1.5, zindex: '1000',  X_top: "21.8vh", x_img: 1});
                this.setState({zoomButtonText:this.props.language.Zoom_OFF ,zoomButtonValue:1});
                break;
            case "ZoomOFF":
                if(document.getElementById('iospopup').style.display == "none"){
                    this.betSound.play();
                }
                this.setState({zoomButtonText: this.props.language.Zoom_ON,zoomButtonValue:0});
                break;
            case "double":
                this.betSound.play();
                this.updateWinsArray(2);
                break;
            case "clear":
                this.setState({betButtonText: this.props.language.Prev,betButtonValue:0, btnActive : false, disZoomBtn: 0,disBetBtn: 0, });
                document.getElementById("bet_take").classList.remove("active");
                if(document.getElementById('iospopup').style.display == "none"){
                    this.clearSound.play();
                }
                this.clearBetList();
                break;
            case "exit":
                this.gameCloseHandler("target");
                const body = {
                    sessionId: sessionStorage.getItem('sessionId'),
                    CN: "EXIT_GAME",
                    object: {
                        gameId: this.props.gameId,
                        playerId: Number(this.props.playerId),
                    },
                };
                this.props.network.sendof(body);
                break;
            case "disableBtns":
                this.eurTm_Ref.current.mouseUpStopCards();
                this.removeImgGlow();
                this.setState({
                    disDoubleBtn: 1,
                    disBetBtn: 1,
                    disClearBtn: 1,
                    disZoomBtn: 1,
                    winCover:true
                });
                if(this.state.totalBet == 0 && this.takeAmount == 0){
                    this.setState({infoText: this.props.language.Play_Njoy});
                } 
            break;
            case "PlayTickSound":
                if(document.getElementById('iospopup').style.display == "none"){
                    this.tickSound.play();
                }
            break;
            case "StopTickSound":
                if(document.getElementById('iospopup').style.display == "none"){
                    this.tickSound.pause();
                }
            break;
            default:
            break;
        }
    }

    removeImgGlow(){
        for(var i=0; i<this.state.betString.length; i++){
            var valId = document.getElementById("id_"+i);
            if(valId){
                valId.classList.remove("active");
            }
        }
        this.removeGlow = false;
    }

    PreviousBetMethod() {        
        this.updateWinsArray(0);
        var spacesremove = this.props.gameState['betRequest']['betString'].replace(/ /g, "0");
        var preBet = spacesremove.replace(/,/g, '|');
        this.state.previousBetArray.push({
            amount: preBet.split("|",)
        });
        this.setState({ previousBetArray: this.state.previousBetArray });
        if (this.state.previousBetArray) {
            this.toCreatePreviousBetArray();
        }
        //console.log(this.state.previousBetArray[0].amount[0]);
        if(this.props.gameState.handId){
            this.handId = this.props.gameState.handId;
        }
    }

    toCreatePreviousBetArray() {
        for (let i = 0; i < this.state.previousBetArray[0].amount.length; i++) {
            this.state.finalPreviousBetArray.push({
                id: i,
                amount: Number(this.state.previousBetArray[0].amount[i])
            });
        }
        this.setState({ finalPreviousBetArray: this.state.finalPreviousBetArray });
        //console.log(this.state.finalPreviousBetArray);
        if (this.state.finalPreviousBetArray) {
            this.toGenerteBetString();
        }
    }

    toGenerteBetString() {
        for (let i = 0; i < this.state.betString.length; i++) {
            if (this.state.betString[i].id == this.state.finalPreviousBetArray[i].id) {
                //console.log('ids' + i);
                let value = this.state.betString[i].amount += this.state.finalPreviousBetArray[i].amount;
                if (this.state.finalPreviousBetArray[i].amount != 0) {
                    document.getElementById("id_" + i).innerHTML = `<p class="goldCoin"> <img src=${coin} /> <span> ${Number(value.toFixed(2))} </span> </p>`
                }
            }
        }
        this.setState({ betString: this.state.betString, betButtonText: this.props.language.BetOK,betButtonValue:1, previousBetArray: [], finalPreviousBetArray: [],btnActive:true });
    }

    takeAmountResult() {
        this.setState({
            disDoubleBtn: 1,
            disBetBtn: 0,
            disClearBtn: 1,
            disZoomBtn: 0,
            glowTakeBtn: 0,
            btnActive : false,
            betButtonText: this.props.language.Prev,
            betButtonValue:0,
            totalBet: 0,
            winAmount:0,
            Total_Bet_Amount: 0,
            btnActive:false
        });
        this.takeAmount = 0;
        var valId = document.getElementById('bet_take');
        valId.classList.remove('active'); 
        this.setState({infoText: this.props.language.Play_Njoy, showBetButton: false, winCover:false});
        
        this.updateWinsArray(0);
        document.getElementById("eurpRltCover").style.display = 'none'; 

        const body = {
            sessionId: sessionStorage.getItem('sessionId'),
            CN: "TAKE_AMOUNT",
            object: {
                gameId: this.props.gameId,
                playerId: Number(this.props.playerId),
            }
        }
        // this.props.network.sendof(body);
        this.body = {};

        if(this.props.isPlayerBanned){
            this.gameCloseHandler("target");
            this.props.player_banned();
        }

        console.log("gameUnderMaintanance "+this.props.gameUnderMaintanance);
        if(this.props.gameUnderMaintanance){
            this.gameCloseHandler("target");
            this.props.game_maintanance("Game under maintenance, reach to admin.");
        }

        if (this.props.isdeviceBlocked){
            this.gameCloseHandler("target");
            this.props.device_blocked();
        }
    }

    clearBetList(){
      
        this.setState({ infoText: this.props.language.Play_Njoy,})
        //console.log("clearBetList");
        for(var i=0; i<this.state.betString.length; i++){
            if(document.getElementById("id_"+i)){
                document.getElementById("id_"+i).innerHTML = ``;
                this.state.betString[i].amount = 0;
            }
        }
        this.setState({Total_Bet_Amount: 0});
        this.setState({disDoubleBtn: 1, disClearBtn:1});
        if(document.getElementById("eurpRltCover")){
            document.getElementById("eurpRltCover").style.display = 'none';
        }

        if(this.props.gameState.betRequest == undefined){
            this.setState({disBetBtn: 1});
        }
    }

    updatePlayerInfo(){
        this.setState({ Total_Bet_Amount: 0})
    }

    updateWinsArray(num) {
        console.info("Update Bet:::::::::::::::::::::: "+ num);

        let totBet = 0;
        if(num == 2){
            if(this.state.Total_Bet_Amount*2> this.props.maxBetAmt){
                this.setState({infoText: this.props.language.MinBetAmt+" "+this.props.minBetLbl+" "+this.props.language.MaxBetAmt+" "+this.props.maxBetLbl});
            }else{
                if(this.state.Total_Bet_Amount*2 <= this.props.balance){
                    let innerMaxLimit = 1;
                    for(let k=0; k<this.state.betString.length; k++){
                            if(k<=36){                  
                                innerMaxLimit = this.newLimits[0][1];                  
                            
                            }else if(k >= 37 && k <= 96){
                                innerMaxLimit = this.newLimits[1][1];                      
                            }else if(k >= 97 && k <= 110){
                                innerMaxLimit = this.newLimits[2][1];                      
                            }else if(k >= 111 && k <= 133){
                                innerMaxLimit = this.newLimits[3][1];            
                            }else if(k>=135 && k <= 145){
                                innerMaxLimit = this.newLimits[5][1];
                            }else if(k>=151 && k <= 156){
                                innerMaxLimit = this.newLimits[6][1];
                            }else if(k >= 162 && k <= 167){
                                innerMaxLimit = this.newLimits[7][1];

                            }


                        if(this.state.betString[k].amount > 0 && this.state.betString[k].amount <= innerMaxLimit && totBet <= this.props.balance){          
                            if(this.state.betString[k].amount * num <= innerMaxLimit){                          
                            
                                if(this.state.Total_Bet_Amount + this.state.betString[k].amount <= this.props.balance){   
                                    this.state.Total_Bet_Amount = this.state.Total_Bet_Amount + this.state.betString[k].amount;             
                                    this.state.betString[k].amount = this.state.betString[k].amount * num;
                                    let doubleBetValue = Number((this.state.betString[k].amount).toFixed(2))
                                    if(doubleBetValue >= 1000 && doubleBetValue <=999999){
                                      doubleBetValue = Number((doubleBetValue / 1000).toFixed(2))+"K";
                                      console.log(doubleBetValue);
                                    }else if(doubleBetValue >= 1000000 && doubleBetValue <=999999999){
                                      doubleBetValue = Number((doubleBetValue / 1000).toFixed(2))+"M";
                                      console.log(doubleBetValue);
                                    }else if(doubleBetValue >999999999){
                                      doubleBetValue = Number((doubleBetValue / 1000).toFixed(2))+"B";
                                      console.log(doubleBetValue);
                                    } 
                                    document.getElementById("id_" + k).innerHTML = `<p class="goldCoin"> <img src=${coin} /> <span> ${doubleBetValue} </span> </p>`;


                                    // document.getElementById("id_" + k).innerHTML = `<p class="goldCoin"> <img src=${coin} /> <span> ${this.state.betString[k].amount} </span> </p>`;
                                }
    
                            }else{
                                console.log(innerMaxLimit);
                                let maxBetMount = this.changeAmtlabl(Number(innerMaxLimit))
                              
                                  this.setState({infoText: this.props.language.MaxInrBetAmt+" "+maxBetMount});
                               }
                            totBet += this.state.betString[k].amount;
                            this.setState({
                                Total_Bet_Amount: totBet,
                            });
                        }
                    }
    
                    if(totBet <= this.props.balance){
                        this.setState({
                            betString: this.state.betString,
                            disDoubleBtn: 0,     
                            disClearBtn: 0,
                            disZoomBtn: 0,
                            // infoText:this.props.language.Play_Njoy,
                        });
                        this.isReady = true;
                    }else {
                        this.isReady = false;
                        this.setState({         
                            disDoubleBtn: 1,     
                            disClearBtn: 0,
                            disZoomBtn: 0,
                            infoText: this.props.language.Amount_Reduce ,
                        });                
                    }
                }else{
                    // this.isReady = false;
                    this.setState({                             
                        disClearBtn: 0,
                        disZoomBtn: 0,
                        infoText: this.props.language.LowBalance ,
                    });
                }   

            }
                  
        }else{
          for(let a=0; a<this.state.betString.length ; a++){
            if(this.state.betString[a].amount > 0 ){
              this.state.betString[a].amount = 0;
              document.getElementById("id_" + a).innerHTML = "";
            }
            totBet += this.state.betString[a].amount;
          }
    
          this.setState({
            betString: this.state.betString,
            disDoubleBtn: 1,     
            disClearBtn: 1,
            disZoomBtn: 0,
            infoText: this.props.language.Play_Njoy,
            Total_Bet_Amount: totBet,
          });
        }        
    }

    showPrevBet(){     
        if(this.props.gameState.betRequest.betAmount <= this.props.balance){   
            console.log(this.props.gameState.betRequest.betAmount);
            console.log(this.props.balance)
            if(this.props.gameState.betRequest.betAmount > this.props.maxBetAmt){
                this.setState({ infoText:this.props.language.PreBet_MaxLmt });
              }else{

                if(this.state.Total_Bet_Amount === 0){
                    let prevBetString=[];
                    var newStr = this.props.gameState.betRequest.betString.replace(/,/g, "|");
                    //var newStr0 = newStr.slice(0, -1)
                    var newStr1 = newStr.replace(/ /g, "0");
                    var newStr2 = newStr1.split('|');
                    var totalBetAmt = 0
                    for(var i=0; i<newStr2.length; i++){
                        if(Number(newStr2[i]) != NaN){
                            totalBetAmt = totalBetAmt+Number(newStr2[i]);
                        }
                        prevBetString.push({
                            id: i,
                            amount: Number(newStr2[i])
                        });
                    }
                    this.state.betString = prevBetString.slice();
                    //this.state.nmArray = prevBetString.slice();
                    //console.log("this.state.nmArray");
                    //console.log(this.state.nmArray);
                }
    
                //const totalbal = this.state.nmArray.reduce((total, m) => m.amount + total, 0)
                // console.log("totalbal "+totalbal);
                
    
                for(var i=0; i<this.state.betString.length; i++){
                    // var value  = this.state.betString[i].amount;
                    let preBetValue = Number((this.state.betString[i].amount).toFixed(2));
                    if (preBetValue > 0) {
                      //console.log("Bet Value: 488: " + value);
                      if(preBetValue >= 1000 && preBetValue <=999999){
                        preBetValue = Number((preBetValue / 1000).toFixed(2))+"K";
                        console.log(preBetValue);
                      }else if(preBetValue >= 1000000 && preBetValue <=999999999){
                        preBetValue = Number((preBetValue / 1000).toFixed(2))+"M";
                        console.log(preBetValue);
                      }else if(preBetValue >999999999){
                        preBetValue = Number((preBetValue / 1000).toFixed(2))+"B";
                        console.log(preBetValue);
                      }
                      document.getElementById("id_"+i).innerHTML = `<p class="goldCoin"> <img src=${coin} /> <span> ${preBetValue} </span> </p>`
                    }
                    // if(value > 0){
                    //     document.getElementById("id_"+i).innerHTML = `<p class="goldCoin"> <img src=${coin} /> <span> ${value} </span> </p>`
                    // }
                }
    
                // if(this.state.betButtonText == "PREV"){
                    if(this.state.betButtonValue == 0){
                    var valId = document.getElementById('bet_take');
                    valId.classList.remove('active');
                    this.setState({
                        showBetButton: true,
                        betButtonText: this.props.language.BetOK,
                        betButtonValue:1,
                        btnActive:true
                    });
                    this.isReady = true;
                }
                this.setState({
                    betString: this.state.betString,
                    disDoubleBtn: 0,
                    disClearBtn: 0,
                    disZoomBtn: 0,
                    Total_Bet_Amount : totalBetAmt
                })

              }
            
        }else{
            this.setState({infoText: this.props.language.Bet_Amt_MaxBal})
        }
    }
    betHandler(amount) {
        //console.log(amount);
        this.setState({ betAmount: amount });
    }
    betPanelHandler(no) {
        this.setState({ betButtonText: this.props.language.BetOK ,betButtonValue:1,btnActive:true});
        //console.log(this.state.betAmount);
        if (this.state.betAmount !== 0) {
            //console.log(no);
            const x = no;
            switch (true) {
                case (x < 168):
                    for (let i = 0; i < this.state.betString.length; i++) {
                        if (this.state.betString[i].id == no) {
                            let value = this.state.betString[i].amount += this.state.betAmount;
                            document.getElementById("id_" + no).innerHTML = `<p class="goldCoin"> <img src=${coin} /> <span> ${Number(value.toFixed(2))} </span> </p>`
                        }
                    }
                    this.setState({ betString: this.state.betString });
                    //console.log(this.state.betString);
                    break;
                default:
                    //alert("none");
                    break;
            }
        }
    }
    placebet() {
        const body = {
            sessionId: sessionStorage.getItem('sessionId'),
            CN: "EUROPEAN_ROULETTE_TIMER_BET",
            object: {
                betString: this.state.betstr,
                playerId: Number(this.props.playerId),
                gameId: this.props.gameId
            }
        }
        this.props.network.sendof(body);
        this.body ={};
    }
    europeanRoulleteTimerGetwheelresp() {
        //console.log(" EuropeanRoullete Result winAmount: "+this.props.targetResult.winAmount);
        //this.setState({winAmount: this.props.targetResult.winAmount});
        document.getElementById("eurpRltCover").style.display = "block"; 
        if (this.props.targetResult) {
            this.resultNumber = this.props.targetResult.resultNum;
            // if(this.state.zoomButtonText == "Wheel Zoom: ON"){
            if(this.state.zoomButtonValue == 0){

                this.setState({wheelZoom: 1.5, zindex: '1000', X_top: "21.8vh", x_img:1 });
            } 
           // this.takeAmount = this.props.targetResult.winAmount;
            this.artWheelRef.current.spinTheWheel(this.resultNumber);
            if(document.getElementById('iospopup').style.display == "none"){
                this.wheelSound.play();
            }
        }
    }
    glowImage(){
        this.setState({wheelZoom: 1, zindex: '0', X_top: "14.5vh", });
        this.removeImgGlow();
        this.getPlayerInfo()
        if(this.state.winAmount > 0){
            if(document.getElementById('iospopup').style.display == "none"){
                this.winSound.play();
            }
            document.getElementById("eurpRltCover").style.display = 'block'; 
        }else{
            if(document.getElementById('iospopup').style.display == "none"){
                this.duploseSound.play();
            }
            if(this.state.winAmount == 0 && this.takeAmount == 0){                
                setTimeout(()=>{
                    this.clearBetList();
                    if(this.takeAmount == 0 && document.getElementById("eurpRltCover") ){
                        document.getElementById("eurpRltCover").style.display = 'none'; 
                    }
                },1000)
                this.setState({infoText: this.props.language.Play_Njoy});       
            }
            if(this.props.gameState.betRequest == undefined){
                this.setState({
                    disBetBtn: 1
                })
            }else{
                this.setState({
                    disBetBtn: 0
                })
            }
        }
        //console.log("Glow Win Image: "+ this.resultNumber);
        document.getElementById('id_'+Number(this.resultNumber)).classList.add('active');
        document.getElementById("eurpRltCover").style.zIndex = "1000";
    }
    EuropeanRouletteTimerGetTime(){
        //console.log(this.props.timesend);
        if (this.props.timesend) {
            //console.log(this.props.timesend);
           this.EuropeanTimerInSeconds.current.sendTimer(this.props.timesend);
        }
    }
    chipVal(val){
        this.takeSound = new Audio(takeSound);
        this.takeSound.play();
        //console.log("Funsorat Timer Val  "+val);
        this.state.selectedChipVal = val;
        console.log( "check ::" + this.state.selectedChipVal );
        this.setState({
            selectedChipVal: val,
        });
        console.log(this.state.selectedChipVal)
    }

    benohandle(id){
        this.betSound1 = new Audio(betSound)
        this.betSound1.play();
        this.removeImgGlow();
        console.info("Selected Chip Value: "+this.state.selectedChipVal);
        console.info("Selected ID Value: "+ id);
        if(this.state.selectedChipVal == 0){
            this.state.betString[id].amount = 0;
            document.getElementById("id_"+id).innerHTML = ``
        }else{
            // this.setState({
            //   disClearBtn: 0,
            //   disBetBtn: 0,
            //   disDoubleBtn: 0,
            // });
        }
        //const totalbal = this.state.nmArray.reduce((total, m) => m.amount + total, parseInt(this.state.selectedChipVal))
        const totalbet = this.state.betString.reduce((total, m) => Number((m.amount + total).toFixed(2)), Number(this.state.selectedChipVal));
        //console.log("totalet "+totalbet);

        if(totalbet > this.props.maxBetAmt){
            // this.setState({infoText: this.props.language.Min_Bet+" "+this.props.minBetAmt+" "+this.props.language.Max_Bet+" "+this.props.maxBetAmt});
            this.setState({infoText: this.props.language.MinBetAmt+" "+this.props.minBetLbl+" "+this.props.language.MaxBetAmt+" "+this.props.maxBetLbl});
          }else if(totalbet <= this.props.balance ){

            if (this.state.betString[id] != null && this.state.betString[id].amount != null) {

                let innerLimit = 1;
                try{
                    if(id<=36){                  
                        innerLimit = this.newLimits[0][1];                  
                        }else if(id >= 37 && id <= 96){
                            innerLimit = this.newLimits[1][1];                      
                        }else if(id >= 97 && id <= 110){
                            innerLimit = this.newLimits[2][1];                      
                        }else if(id >= 111 && id <= 133){
                            innerLimit = this.newLimits[3][1];            
                        }else if(id>=135 && id <= 145){
                            innerLimit = this.newLimits[5][1];
                        }else if(id>=151 && id <= 156){
                            innerLimit = this.newLimits[6][1];
                        }else if(id >= 162 && id <= 167){
                            innerLimit = this.newLimits[7][1];

                        }
                        console.log("FST Inner Limit:  "+innerLimit)
          
                }catch (err)
                {
                  console.log("error while trying to fetch inner limits: ArayOutOfBoundsException "+ err.message);
                }
                console.log("FST Inner Limit:  "+innerLimit)

                
            // if((this.state.betString[id].amount+parseInt(this.state.selectedChipVal)) <= innerLimit){
            if((this.state.betString[id].amount+Number(this.state.selectedChipVal)) <= innerLimit){
                this.isReady = true;
                this.setState({
                    Total_Bet_Amount : totalbet,
                    // infoText: this.props.language.Play_Njoy,
                    infoText: this.props.language.MinBetAmt+" "+this.props.minBetLbl+" "+this.props.language.MaxBetAmt+" "+this.props.maxBetLbl
                })
                for(var i=0; i<this.state.betString.length; i++){
                    if(this.state.betString[i].id == id){
                        if(this.state.selectedChipVal == 0){
                            var value  = this.state.betString[i].amount *= Number(this.state.selectedChipVal);
                            document.getElementById("id_"+i).innerHTML = ``
                        }else{
                            // var value  = this.state.betString[i].amount += parseInt(this.state.selectedChipVal);
                            // let chipBetValue = this.state.betString[i].amount += parseInt(this.state.selectedChipVal);
                            console.log(this.state.betString[i].amount)
                            let chipBetValue = this.state.betString[i].amount =Number((this.state.betString[i].amount+ (Number(this.state.selectedChipVal))).toFixed(2));
                            console.log(this.state.betString[i].amount)
                            console.log("Bet Value: 699: " + chipBetValue);
                            console.log("Bet Value: 699: " + typeof(chipBetValue));
                            if(Number(chipBetValue) >= 1000 && Number(chipBetValue) <=999999){
                            chipBetValue = Number((chipBetValue / 1000).toFixed(2))+"K";
                            console.log(chipBetValue);
                            }else if(chipBetValue >= 1000000 && chipBetValue <=999999999){
                            chipBetValue = Number((chipBetValue / 1000).toFixed(2))+"M";
                            console.log(chipBetValue);
                            }else if(chipBetValue >999999999){
                            chipBetValue = Number((chipBetValue / 1000).toFixed(2))+"B";
                            }
                            console.log(chipBetValue);

                            this.setState({
                                disClearBtn: 0,
                                disBetBtn: 0,
                                disDoubleBtn: 0,
                              });

                            // document.getElementById("id_"+i).innerHTML = `<p class="goldCoin"> <img src=${coin} /> <span> ${value} </span> </p>`
                            document.getElementById("id_"+i).innerHTML = `<p class="goldCoin"> <img src=${coin} /> <span> ${chipBetValue} </span> </p>`

                        }
                    }

                    if( this.removeGlow == true){
                        //document.getElementById('id_'+i).classList.remove('active');
                        var valId = document.getElementById('id_'+i);
                        if(valId){
                            valId.classList.remove('active');
                        }
                    }
                }
                var TotalBetVaue = this.state.betString.reduce((prev, cur) => Number((prev + cur.amount).toFixed(2)), 0);
                console.log("Total Bet Amount: "+TotalBetVaue );
                if(TotalBetVaue == 0){
                    console.log("Disable Buttons");
                    this.setState({disDoubleBtn: 1, disClearBtn: 1, disBetBtn:0, disZoomBtn:0});
                    if(this.props.gameState.betRequest){
                        this.setState({ betButtonText: this.props.language.Prev,betButtonValue:0,btnActive:false})
                    }else{
                        this.setState({ disBetBtn:1})
                    }
                }else{
                    this.setState({disDoubleBtn: 0, disClearBtn: 0, showBetButton: true, betButtonText: this.props.language.BetOK,betButtonValue:1,btnActive:true});
                    this.removeGlow = false;
                    // if(this.state.betButtonText == "PREV"){
                        if(this.state.betButtonValue == 0){
                        var valId = document.getElementById('bet_take');
                        valId.classList.remove('active');
                    }                
                }
            }else{

                let maxBetMount = this.changeAmtlabl(Number(innerLimit))
                this.setState({infoText: this.props.language.MaxInrBetAmt+""+maxBetMount}) 
               
                 console.log(this.state.infoText)
              }
            if(this.state.selectedChipVal == 0 && this.state.Total_Bet_Amount ==0){
                this.setState({infoText:this.props.language.MinBetAmt +" "+ this.props.minBetLbl})
                setTimeout(()=>{
                    this.setState({ infoText:  this.props.language.Play_Njoy });
                  },1500)
            }
            // else{
            //     this.setState({ infoText:  this.props.language.Play_Njoy });
            // }


            }


        }else{
            this.setState({infoText:this.props.language.Amount_Reduce})
            //console.log("amount reduces to your balance")
            //alert('amount reduces to your balance');
        }
        
        this.setState({
            betString: this.state.betString
        })
    }

    loadBg(){
        this.LoadImg = new window.Image()
        this.LoadImg.src = artBg; 
        this.LoadImg.addEventListener('load', this.eurpRltTmLoded)
      }
    
      eurpRltTmLoded = () => {
        if(!this.isloadImg){
            this.isloadImg = true; 
    } else{ 
            this.setState({
                eurpRlt:false
            })
        }
    }

    closeWin() {
        document.getElementById('iospopup').style.display="none";
        this.takeSound = new Audio(takeSound);
        this.winSound = new Audio(winSound);
        this.duploseSound = new Audio(duploseSound);
        this.tickSound = new Audio(tickSound);
        this.wheelSound = new Audio(wheelSound);
    }

    render() {
        let winAmount = this.changeAmtlabl(Number(this.state.winAmount))
        return (
            <React.Fragment>
                <div className="gameBox" id="gameBox">
                    <div className="gamePage euroRltTimer" >
                        <div className="iosFetchSounds" id="iospopup" onClick={(e) => this.closeWin()}>
                            <div className="popupTooLong">
                                <div className="">{this.props.language.Wel_ERT}  </div>
                                <br></br>
                                {this.props.language.OK_Btn}
                               
                                <br></br>
                                <br></br>
                                <div className="fd">                    
                                    <button className="btn_iosPopup" >  {this.props.language.Ok} </button>
                                </div>
                            </div>
                        </div>
                        <div className="FGAeuropeanRoulette">
                            <div className="fd american_ht_1">
                                <div className="fd">
                                    <div className="row">
                                        <div className="fd">
                                            <div className="wheelandhis">
                                                <div className="row">
                                                    <div className="fun_chips">
                                                        <BetChips
                                                            bet={this.betHandler.bind(this)}
                                                            winAmount={this.state.winAmount}
                                                            balance={this.props.balance}
                                                            TotalBetAmouont={this.state.Total_Bet_Amount}
                                                            totalBet={this.state.betString}
                                                            betAmount={this.chipVal.bind(this)}
                                                            ert_chipValues = {this.props.ert_chipValues}
                                                            action={this.rightButtonPanelHandler.bind(this)}/>
                                                    </div>
                                                    <div className="fun_routlet_table">
                                                        <div className="fd">
                                                            <Title className="fd"></Title>
                                                            <div className="fd" style={{height:'40vh'}}>
                                                                <Wheel
                                                                    gameState={this.props.gameState}
                                                                    ref={this.artWheelRef}
                                                                    zindex={this.state.zindex}
                                                                    zoom={this.state.wheelZoom}
                                                                    height={this.wheelHeight}
                                                                    width={this.wheelWidth}
                                                                    eurpRltTmLoded={this.eurpRltTmLoded.bind(this)}
                                                                    action={this.wheelHandler.bind(this)}
                                                                    isMobile={this.props.isMobile}
                                                                    showGlow={this.glowImage.bind(this)}
                                                                    //onFocus={this.state.onFocus}
                                                                    >
                                                                </Wheel>
                                                            </div>
                                                        </div>                                                        
                                                    </div>
                                                    <div className="fun_btns">
                                                        <EuropeanTimerInSeconds
                                                            action={this.rightButtonPanelHandler.bind(this)}
                                                            playerId={this.props.playerId}
                                                            gameId={this.props.gameId}
                                                            network={this.props.network}
                                                            winCover={this.state.winCover}
                                                            timesend={this.props.timesend}
                                                            ref={this.EuropeanTimerInSeconds} 
                                                            placeBetState={this.state.placeBetState}
                                                        />

                                                        <RightButtonPanel
                                                        childRef ={ref =>(this.RightButtonPanel = ref)}
                                                       
                                                            user={this.props.user}
                                                            betBtnText={this.state.betButtonText}
                                                            betButtonValue = {this.state.betButtonValue}
                                                            gameState={this.props.gameState}
                                                            targetResult={this.props.targetResult}
                                                            zoomButtonText={this.state.zoomButtonText}
                                                            zoomButtonValue = {this.state.zoomButtonValue}
                                                            betBtn={this.state.showBetButton}
                                                            action={this.rightButtonPanelHandler.bind(this)}
                                                            stop={this.state.stop}
                                                            
                                                            disBetBtn ={this.state.disBetBtn}
                                                            disZoomBtn = {this.state.disZoomBtn}
                                                            disDoubleBtn ={this.state.disDoubleBtn}
                                                            disClearBtn = {this.state.disClearBtn}
                                                        
                                                            glowTakeBtn = {this.state.glowTakeBtn}
                                                            ref={this.RightButtonPanel}
                                                            network={this.props.network}
                                                            btnActive={this.state.btnActive}
                                                            >
                                                        </RightButtonPanel>
                                                        {/* <Close action={this.gameCloseHandler.bind(this)}></Close> */}
                                                        {/* <Close action={this.gameCloseHandler.bind(this)}></Close> */}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="ert_table">
                                                <div className="fd">
                                                    <Table  
                                                        betAmount={this.chipVal.bind(this)}
                                                        action={this.benohandle.bind(this)}
                                                        // action={this.betPanelHandler.bind(this)}
                                                        infoText = {this.state.infoText}
                                                        ref={this.eurTm_Ref}
                                                        className="fd">
                                                    </Table>
                                                </div>
                                            </div>
                                        </div>
                                        </div>
                                        </div>
                    <div className="american_ins"> 
                        <div className="fd">
                            <div className="row">
                                <div className="col-3 col-sm-2">
                                    <div className="userLbl">
                                    <img className="user" src={user} alt={user}/> {this.props.user} </div> 
                                </div>
                                <div className="col-6 col-sm-8">
                                    <div className="eurpTmrIns"> {this.state.infoText} </div>
                                </div>
                                { this.props.gameEntry == 'in' ?
                                <div className="col-3 col-sm-2">
                                    {/* <div className="fd box_clm"> 
                                        <button className="fd ar_bt_1 z_1500" disabled={this.props.disZoomBtn == 1}
                                        onClick={(e)=>{e.preventDefault(); this.gameCloseHandler("target");}}>
                                       {this.props.language.Exit}  </button>
                                    </div> */}
                                </div> : <div className="userLbl">
                               {this.props.language.Player_Id} {this.props.playerId} <br></br>
                               {this.props.language.Hand_Id}  {this.props.gameState.handId} <br></br>
                                </div> }
                            </div>
                        </div> 
                        <div className="eurpRTimerWinPop" id="ert_WinPop" style={{zIndex: "2000"}}>
                        {this.props.language.Win_Amt}  {winAmount}
                        </div>
                    </div>
                </div>
            </div>      
            </div>
            </div>
            </React.Fragment>
        );
    }
}

const mapStatesToProps = (state)=>{
   
    return {
      language : state.languageObjs.languageObj,
    }
  }

export default connect(mapStatesToProps)(EuropeanRouletteTimer)

import{
    FETCH_ENGLISH_REQUEST, FETCH_ENGLISH_SUCCESS, FETCH_ENGLISH_FAIL,
    FETCH_GUJARATHI_REQUEST, FETCH_GUJARATHI_SUCCESS, FETCH_GUJARATHI_FAIL,
    FETCH_BENGALI_REQUEST, FETCH_BENGALI_SUCCESS, FETCH_BENGALI_FAIL,
    FETCH_MARATHI_REQUEST, FETCH_MARATHI_SUCCESS, FETCH_MARATHI_FAIL,
    FETCH_TELUGU_REQUEST, FETCH_TELUGU_SUCCESS, FETCH_TELUGU_FAIL,
    FETCH_TAMIL_REQUEST, FETCH_TAMIL_SUCCESS, FETCH_TAMIL_FAIL,
    FETCH_KANNADA_REQUEST, FETCH_KANNADA_SUCCESS, FETCH_KANNADA_FAIL,
    FETCH_MALAYALAM_REQUEST, FETCH_MALAYALAM_SUCCESS, FETCH_MALAYALAM_FAIL,
    FETCH_PORTUGUESE_REQUEST, FETCH_PORTUGUESE_SUCCESS, FETCH_PORTUGUESE_FAIL,
    //Gujarathi , Bengali , Marathi , Telugu , Tamil , Portuguese , Kannada  and malayalam
}from "../actions/actiontypes";

const initialState = {
    forntSize: "14px",
    languageLoader: false,
    languageError: null,

    languageObj: {   
    "Wel_MT":" Welcome to MATKA TIMER GAME",
    "Wel_ST":"Welcome to SORAT TIMER GAME",
    "Wel_TT":"Welcome to TARGET TIMER GAME",
    "Wel_ERT":" Welcome to EUROPEAN ROULETTE TIMER GAME",
    "Wel_ART":"Welcome to AMERICAN ROULETTE TIMER GAME",	
    "OK_Btn":"Please click on OK button to continue.",
    "NA":"NA",
    "Player_Id":"Player Id: ",
    "Hand_Id":"Hand Id: ",
    "Balance":"BALANCE",
    "TotalBet":"TOTAL BET",
    "Total_Bet":"TOTAL BET",
    "Prev":"PREV",
    "Clear":"CLEAR",
    "Double":"DOUBLE",
    "Play_Njoy":"Play and Njoy!",
    "Ok":"Ok",
    "Bet_OK":"BET OK",
	"BetOK":"BET OK",
    "Bet_Accepted":"Bet Accepted !! your bet amount is ",
    "BetAccepted":"Bet Accepted !! your bet amount is ",
    "Bet_Amt_MaxBal":"Previous bet amount is more than your current balance.",
    "Amount_Reduce":"Amount reduce to your amount",
    "Low_Balance":"Amount reduces to your balance",
    "Game_Mainenance_Msg":"Game under maintenance, reach to admin.",
    "Stop_At":"Stop At:",
    "Spin":"SPIN",
    "Zoom_ON":"WHEEL ZOOM: ON",
    "Zoom_OFF":"WHEEL ZOOM: OFF",
    "WonHand":"You won the hand!!!",
    "Exit":"EXIT",
    "Take_Amt":"Please Take Your Take Amount",
    "You_won":"Congratulations! You won!",
    "Win_Num":" Winning Number is",
    "Win_Amt":" Winning Amount is",
    "History":"History",
    "AmusementOnly": "For Amusement Only",
    "Click_Take":"Please Click on Take",
    "Bets_Empty":"Bets are Empty!!!",
    "Select_Chip":"Select Chip",
    "BetRejected":"Bet Rejected!!.Please click on the take",
    "Winning":"WINNING",
    "A":"A",
    "B":"B",
    "Winnings":"Winnings",	
	"InternalError":"Internal Error !!",
	"AlreadyBetPlaced":"Bet rejected!!. You have already placed a bet for this Round.",
	"BetRejTimeOver":"Bet Rejected!! Time Over",
	"InvalidBet":"Bets Rejected !!",
	"BetRej":"Bet Rejected",
	"Deal":"Deal",
	"User_Name":"User Name",	
	"Dealer":"Dealer",
    "Player":"Player",
	"Card":"Card",
	"Bet":"Bet",
   
	"Winner":"Winner",
	"PlayerFirst":"Player First",
	"Les_Amt":"Select Lesser Amount",
	"MinBetAmt":"Minimum bet Amount ",
    "MaxBetAmt":"Maximum bet amount",
	"MaxInrBetAmt":"Your maximum bet limit for this bet is: ",
	"InerLmt":"Your minimum bet limit for this bet is: ",	
	"PreBet_MaxLmt":"The previous bet amount is greater than your current maximum bet limit.",
	"InerLmt_Max":"Individual bets exceed the maximum limit.",
	"Bet_Limits":"Bet Limits",
	"Min":"Min: ",
	"Max":"Max: ",
	"Win":"WIN: ",
	"Undo":"UNDO",
	"NewGame":"NEW GAME",
	"Rebet":"Rebet",
	"Global_Bonus":"Global bonus",
	"Score":"score",
	"D_UP":"DOUBLE UP",
	"RANDOM":"RANDOM",
	"TAKE":"TAKE",
	"RED":"RED",
	"BLACK":"BLACK",
	"START":"START",
	"BONUS_AMT":"Your winning bonus Amount is: ",
	"RAN_NUM":"You are selected 10 random numbers.",
	"NUM_MAT":"Numbers Matched",
	"PRIZE_AMT":"Prize Amount",
	
	"LowBalance": "Amount reduced to your balance",
	"BetAmtMaxBal": "Previous bet amount is more than your current balance.",
	"Wel_DTT":"Welcome to DRAGON TIGER TIMER GAME",
	"keno_bet_info": "Please select 10 numbers to start betting.",
	"Wel_MWT":"Welcome to MONEY WHEEL TIMER GAME",
    "Insurence":"Insurance",
	"Ante":"Ante",
	"Bonus":"Bonus",
	"Buy":"Buy",
	"Draw":"Draw",
	"Fold":"Fold",
	"RebetX2":"RebetX2",
	"Place_antebet":"Place Your Bet On ANTE To Start",
	"deal_tostart":"Press Deal to start the Game!",
	"No_Insurence":"No insurance",
	"Yes":"Yes",
	"No":"No",
	"RP_Clear":"Clear",
	"MinBetLmt":"Your current bet amount is less than your minimum bet limit.",
	"Roll":"ROLL",	
	"Min_Bet":"Your minimum Bet ",
    "Max_Bet":" and the maximum Bet "
}
}

const languageReducer = (state = initialState, action) => {
    switch(action.type){
        case FETCH_BENGALI_REQUEST:
        case FETCH_ENGLISH_REQUEST:
        case FETCH_GUJARATHI_REQUEST:
        case FETCH_KANNADA_REQUEST:
        case FETCH_MALAYALAM_REQUEST:
        case FETCH_MARATHI_REQUEST:
        case FETCH_PORTUGUESE_REQUEST:
        case FETCH_TAMIL_REQUEST:
        case FETCH_TELUGU_REQUEST:
            return{
                ...state, languageLoader: true
            }
        break;
        case FETCH_BENGALI_SUCCESS:
        case FETCH_ENGLISH_SUCCESS:
        case FETCH_GUJARATHI_SUCCESS:
        case FETCH_KANNADA_SUCCESS:
        case FETCH_MALAYALAM_SUCCESS:
        case FETCH_MARATHI_SUCCESS:
        case FETCH_PORTUGUESE_SUCCESS:
        case FETCH_TAMIL_SUCCESS:
        case FETCH_TELUGU_SUCCESS:
            // alert('success')
            return{
                ...state, languageObj: action.payload, languageError: null
            }
        break;
        case FETCH_BENGALI_FAIL:
        case FETCH_ENGLISH_FAIL:
        case FETCH_GUJARATHI_FAIL:
        case FETCH_KANNADA_FAIL:
        case FETCH_MALAYALAM_FAIL:
        case FETCH_MARATHI_FAIL:
        case FETCH_PORTUGUESE_FAIL:
        case FETCH_TAMIL_FAIL:
        case FETCH_TELUGU_FAIL:
            return{
                ...state, languageLoader: false, languageError: action.payload,
            }
        break; 
        default : return {...state, languageObj:state.languageObj, languageLoader: false, languageError: null }

    }
}
export default languageReducer;